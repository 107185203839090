const SCORE_BOUNDARY_VALUES = {
  AVERAGE: 28,
  GOOD: 56,
  GREAT: 78
};

const SCORE_BOUNDARY_NAMES = {
  BAD: "bad",
  AVERAGE: "average",
  GOOD: "good",
  GREAT: "great"
};

function OverallScoreColor(score) {
  switch (true) {
    case score >= SCORE_BOUNDARY_VALUES.GREAT:
      return { thumb: "vitrueDarkGreen", text: "vidaPainDarkGreen" };
    case score >= SCORE_BOUNDARY_VALUES.GOOD:
      return { thumb: "vitrueBrightGreen", text: "vidaPainGreen" };
    case score >= SCORE_BOUNDARY_VALUES.AVERAGE:
      return { thumb: "vitrueYellow", text: "vidaPainOrange" };
    default:
      return { thumb: "vitrueRed", text: "vidaPainRed" };
  }
}

function GetScoreRatingName(score) {
  switch (true) {
    case score >= SCORE_BOUNDARY_VALUES.GREAT:
      return SCORE_BOUNDARY_NAMES.GREAT;
    case score >= SCORE_BOUNDARY_VALUES.GOOD:
      return SCORE_BOUNDARY_NAMES.GOOD;
    case score >= SCORE_BOUNDARY_VALUES.AVERAGE:
      return SCORE_BOUNDARY_NAMES.AVERAGE;
    default:
      return SCORE_BOUNDARY_NAMES.BAD;
  }
}

export { SCORE_BOUNDARY_VALUES, OverallScoreColor, GetScoreRatingName };
