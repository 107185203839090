import i18n from "@/plugins/i18n.js";

const defaultLocale = "en-gb";
const localeVariableKey = "Vitrue-Locale-Storage";

export default {
  setLocaleValue() {
    i18n.locale = defaultLocale;
    localStorage.setItem(localeVariableKey, defaultLocale);
  },
  syncLocale() {
    this.setLocaleValue();
  }
};
