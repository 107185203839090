<template>
  <div class="window-header px-4">
    <div class="header-content py-1">
      <div class="header-left">
        <img
          src="/images/icons/ai-icon-coloured.svg"
          class="mr-2"
          width="20px"
          height="20px"
        />
        <span class="header-text text-subtitle-1 mr-1">MSK Copilot</span>
        <v-tooltip top color="transparent">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="beta-tag text-label vflGreyLight--text"
              >Beta</span
            >
          </template>
          <vitrue-tooltip
            maxWidth="350px"
            message="VIDA MSK Copilot is currently in beta, so you might encounter some errors. We appreciate your feedback."
          />
        </v-tooltip>
      </div>
      <div class="header-right">
        <button
          v-if="showNewConversationButton"
          class="vfl-button-white is-icon-only is-square"
          :class="{
            'is-disabled': disableNewConversation || isLoadingConversations
          }"
          @click="startNewConversation"
        >
          <v-icon color="black">mdi-square-edit-outline</v-icon></button
        ><button
          class="vfl-button-white is-icon-only is-square"
          @click="closeWindow"
        >
          <v-icon color="black">mdi-close</v-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "AiAssistantWindowHeader",
  components: {
    VitrueTooltip
  },
  data() {
    return {
      showNewConversationButton: false // Hiding on purpose until later versions of AI Chat
    };
  },
  computed: {
    ...mapGetters("ai", ["isLoadingConversations", "currentConversation"]),
    disableNewConversation() {
      return !this.currentConversation?.messages?.length;
    }
  },
  methods: {
    closeWindow() {
      this.$store.dispatch("ai/closeAiWindow");
    },
    startNewConversation() {
      this.$store.dispatch("ai/abortCurrentRequest");
      this.$store.dispatch("ai/createNewConversation");
    }
  }
};
</script>

<style scoped>
.window-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
}
.header-left {
  user-select: none;
}
.header-right {
  margin-right: -12px;
}

.header-text {
  background-image: linear-gradient(
    to right,
    var(--v-vflPrimary-base),
    var(--v-vflPurple-base)
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.beta-tag {
  align-self: flex-start;
  background-color: hsla(0, 0%, 0%, 0.05);
  border-radius: 4px;
  font-size: 0.675rem;
  padding: 0.125rem 0.5rem;
  margin-top: 0.2rem;
}
</style>
