<template>
  <div
    class="assistant-footer px-4"
    :class="{ 'is-disabled': isLoadingConversations }"
  >
    <ai-assistant-window-input
      v-model="newMessage"
      :disabled="processingMessage"
      auto-grow
      @send="send"
      @enter="handleEnter"
      :autofocus="!isLoadingConversations"
    >
      <template v-slot:icon>
        <ai-assistant-input-action-button
          v-if="processingMessage"
          @click="stop"
        >
          <ai-assistant-input-action-stop-icon />
          <span class="d-sr-only">Cancel message</span>
        </ai-assistant-input-action-button>

        <ai-assistant-input-action-button
          v-else
          class-list="vfl-button-ai is-icon-only is-small-icon"
          :disabled="!canSubmit"
          @click="send"
        >
          <v-icon small color="white" style="margin-bottom: 3px">
            mdi-arrow-right-thin
          </v-icon>
          <span class="d-sr-only">Send message</span>
        </ai-assistant-input-action-button>
      </template>
    </ai-assistant-window-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AiAssistantWindowInput from "@/components/AiAssistant/WindowComponents/AiAssistantWindowInput.vue";
import AiAssistantInputActionButton from "@/components/AiAssistant/WindowComponents/AiAssistantInputActionButton.vue";
import AiAssistantInputActionStopIcon from "@/components/AiAssistant/WindowComponents/AiAssistantInputActionStopIcon.vue";

export default {
  name: "AiAssistantWindowFooter",
  components: {
    AiAssistantInputActionStopIcon,
    AiAssistantInputActionButton,
    AiAssistantWindowInput
  },
  data() {
    return {
      newMessage: ""
    };
  },
  computed: {
    ...mapGetters("ai", [
      "isLoadingConversations",
      "isAssistantTyping",
      "awaitingAssistantResponse"
    ]),
    canSubmit() {
      return this.newMessage;
    },
    processingMessage() {
      return this.awaitingAssistantResponse || this.isAssistantTyping;
    }
  },
  methods: {
    send() {
      if (this.canSubmit) {
        this.$store.dispatch("ai/sendMessage", {
          message: this.newMessage
        });
        this.newMessage = "";
      }
    },
    stop() {
      this.$store.dispatch("ai/abortCurrentRequest");
    },
    handleEnter() {
      this.send();
    }
  }
};
</script>

<style lang="scss" scoped>
.assistant-footer {
  padding: 1rem 0rem;
  position: relative;

  &.is-disabled {
    &:after {
      background: hsla(0, 100%, 100%, 0.6);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
