<template>
  <div
    class="ai-assistant-scroll-down-button-container"
    :class="{ 'is-visible': visible }"
  >
    <button @click="emitScrollDown" class="text-body-2">
      <v-icon>mdi-chevron-down</v-icon>More
    </button>
  </div>
</template>

<script>
export default {
  name: "AiAssistantScrollDownButton",
  props: {
    visible: Boolean
  },
  methods: {
    emitScrollDown() {
      this.$emit("scroll-down");
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-assistant-scroll-down-button-container {
  background-color: hsla(0, 0%, 0%, 0.1);
  flex: 0 0 1px;
  margin: 0 1rem;
  pointer-events: none;
  opacity: 0;
  position: relative;
  transition: opacity 0.25s ease-out;
  z-index: 1;

  &.is-visible {
    opacity: 1;
    pointer-events: auto;
  }

  button {
    align-items: center;
    background: var(--v-vflBlueVeryLight-base);
    border: 1px solid hsla(0, 0%, 0%, 0.1);
    border-radius: 999px;
    color: black;
    display: inline-flex;
    gap: 0.125rem;
    left: 0;
    line-height: 1;
    margin: 0 auto;
    padding: 0 0.5rem 0 0.325rem;
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    width: min-content;

    &:hover {
      filter: brightness(98%);
    }
  }
}
</style>
