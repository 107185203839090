<template>
  <button
    class="vfl-button-ai vfl-button is-icon-only shadow"
    @click="openWindow"
  >
    <img src="/images/icons/ai-icon.svg" width="24px" height="24px" />
    <span class="d-sr-only">Toggle AI Assistant</span>
  </button>
</template>

<script>
export default {
  name: "AiAssistantToggle",
  methods: {
    openWindow() {
      this.$store.dispatch("ai/openAiWindow");
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &:active {
    transform: none; // override scaling on active
  }
}

.shadow {
  box-shadow: 0px 8.4px 6px rgba(136, 65, 165, 0.028),
    0px 19.1px 14.4px rgba(136, 65, 165, 0.04),
    0px 33.8px 27.2px rgba(136, 65, 165, 0.05),
    0px 54.6px 48.5px rgba(136, 65, 165, 0.06),
    0px 76.8px 90.7px rgba(136, 65, 165, 0.072),
    0px 93px 217px rgba(136, 65, 165, 0.1);
}
</style>
