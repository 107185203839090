<template>
  <div class="ai-feature-introduction-goals">
    <h1 class="text-h5 mb-3">{{ heading }}</h1>
    <p class="text-body-2 mb-3">{{ subheading }}</p>

    <check-box-pills-question
      :options="goalOptions"
      :value="selectedGoals"
      @input="updateSelectedGoals"
      class="my-10"
    />

    <v-text-field
      v-if="selectedGoals.includes('other')"
      v-model="otherGoals"
      :label="$t('aiFeatureIntroductionDialog.form.goals.otherLabel')"
      outlined
      dense
      maxlength="255"
      class="mt-4"
      hide-details
      color="var(--v-vflPrimary-base)"
      @input="emitUpdate"
      ref="otherRoleInput"
    ></v-text-field>
  </div>
</template>

<script>
import CheckBoxPillsQuestion from "@/components/form/CheckBoxPillsQuestion.vue";

export default {
  name: "AiFeatureIntroductionGoals",
  components: {
    CheckBoxPillsQuestion
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedGoals: this.modelValue?.goals || [],
      otherGoals: this.modelValue?.otherGoals || "",
      goalOptions: [
        {
          value: "pain_reduction",
          label: this.$t("productGoals.painReduction")
        },
        {
          value: "decrease_claims",
          label: this.$t("productGoals.decreaseClaims")
        },
        {
          value: "reduce_absenteeism",
          label: this.$t("productGoals.reduceAbsenteeism")
        },
        { value: "productivity", label: this.$t("productGoals.productivity") },
        {
          value: "wellbeing_culture",
          label: this.$t("productGoals.wellbeingCulture")
        },
        {
          value: "equipment_management",
          label: this.$t("productGoals.equipmentManagement")
        },
        {
          value: "injury_prevention",
          label: this.$t("productGoals.injuryPrevention")
        },
        {
          value: "full_compliance",
          label: this.$t("productGoals.fullCompliance")
        },
        { value: "other", label: this.$t("productGoals.other") }
      ]
    };
  },
  watch: {
    selectedGoals(newValue, oldValue) {
      if (!oldValue.includes("other") && newValue.includes("other")) {
        this.$nextTick(() => {
          this.$refs.otherRoleInput.focus();
        });
      }
    },
    otherGoals() {
      this.emitUpdate();
    }
  },
  methods: {
    updateSelectedGoals(newGoals) {
      this.selectedGoals = [...newGoals];
      this.emitUpdate();
    },
    emitUpdate() {
      // If 'other' is selected we include it in the array only if the 'other' text box is empty
      const goals = this.otherGoals
        ? this.selectedGoals.filter(goal => goal !== "other")
        : this.selectedGoals;

      this.$emit("update:modelValue", {
        selectedGoals: goals,
        otherGoals: this.selectedGoals.includes("other") ? this.otherGoals : ""
      });
    }
  }
};
</script>
