<template>
  <v-autocomplete
    style="min-width: 125px"
    :style="`max-width:${maxWidth}px`"
    v-model="tags"
    :items="tagItems"
    :label="$tc(label, 1)"
    multiple
    hide-details
    clearable
    item-text="text"
    item-value="value"
    return-object
    @input="$emit('input', tags)"
    :search-input.sync="search"
    @change="search = ''"
    color="var(--v-vflPrimary-base)"
  >
    <template v-slot:selection="{ item, index }">
      <div class="font-weight-regular" v-if="tags.length === 1">
        <v-chip
          v-if="chips"
          small
          :color="item.color"
          :outlined="outlineChips"
          :dark="!outlineChips"
        >
          <div class="longTextHidden">
            {{ text(item) }}
          </div></v-chip
        >
        <div class="longTextHidden" v-else>
          {{ text(item) }}
        </div>
      </div>
      <span v-else-if="index === 0"
        >{{ tags.length }} {{ $tc(label, tags.length) }}</span
      >
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }" dense>
        <v-list-item-action v-if="checkBox">
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-chip
          v-if="chips"
          small
          :color="item.color"
          :outlined="outlineChips"
          :dark="!outlineChips"
          >{{ text(item) }}</v-chip
        >
        <v-list-item-title v-else>
          {{ text(item) }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:no-data>
      <span class="ml-3 caption">{{
        $t("dashboardHistoryComponents.assessmentHistoryTable.text.noTags")
      }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "TableFilter",
  props: {
    value: Array,
    label: String,
    items: Array,
    maxWidth: {
      type: Number,
      default: 300
    },
    checkBox: {
      type: Boolean,
      default: true
    },
    chips: {
      type: Boolean,
      default: false
    },
    outlineChips: Boolean,
    translateText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { tags: this.value, search: "" };
  },
  computed: {
    tagItems() {
      this.items.sort(this.sortTags);
      return this.items;
    }
  },
  methods: {
    sortTags(a, b) {
      if (a.value === "No Tag") {
        return 1;
      }
      var textA = a.text.toUpperCase();
      var textB = b.text.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    },
    text(item) {
      if (item === "No Tag") {
        return this.$t(
          "dashboardHistoryComponents.assessmentHistoryTable.text.noTag"
        );
      }

      return this.translateText ? this.$t(item.text) : item.text;
    }
  }
};
</script>

<style scoped>
.longTextHidden {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
