import { formatShortDate } from "@/services/date-formatting-service.js";
import { getAssessmentEventText } from "@/components/user-profile/history/events/assessment-event.js";
import { getPainEventText } from "@/components/user-profile/history/events/pain-event.js";
import { getRecommendationEventText } from "@/components/user-profile/history/events/recommendation-event.js";
import { getAccountEventText } from "@/components/user-profile/history/events/account-event.js";

const HISTORY_TYPES = {
  ASSESSMENT: "ASSESSMENT",
  PAIN: "PAIN",
  RECOMMENDATION: "RECOMMENDATION",
  ACCOUNT: "ACCOUNT"
};

const processHistoryItem = {
  [HISTORY_TYPES.ASSESSMENT]: item => getAssessmentEventText(item),
  [HISTORY_TYPES.PAIN]: item => getPainEventText(item),
  [HISTORY_TYPES.RECOMMENDATION]: item => getRecommendationEventText(item),
  [HISTORY_TYPES.ACCOUNT]: item => getAccountEventText(item)
};

export function processUserHistory(history) {
  return history
    .map(item => ({
      ...item,
      displayText: processHistoryItem[item.historyType](item),
      formattedDate: formatShortDate(item.timestamp)
    }))
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
}
