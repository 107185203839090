import { Locations, States } from "./assessmentHistoryConstants.js";
import { getRelevantRisks } from "./userHistoryFilterService.js";
import i18n from "@/plugins/i18n";
import * as Constants from "@/constants/constants.js";

export function getDeskAssessmentsInCSVFormat(user) {
  return user.assessments.map(a => {
    return {
      assessmentType: Constants.assessmentTypes.deskAssessment,
      email: user.email,
      location: getLocation(a.location),
      state: i18n.t(States[a.state].text),
      score: a.score,
      lastUpdated: getLastUpdatedDate(a),
      tags: `"${user.tags.map(t => t.replace("#", "")).join(";")}"`,
      risks: getRisks(a.risks).join(";")
    };
  });
}

function getLastUpdatedDate(assessment) {
  return new Date(
    assessment.lastUpdatedAt ?? assessment.createdAt
  ).toLocaleDateString();
}

function getRisks(risks) {
  var relevantRisks = getRelevantRisks(risks);
  return relevantRisks.map(risk =>
    i18n.t(`suggestionsPage.table.issues.${risk}`)
  );
}

function getLocation(location) {
  if (!location) return "";
  return i18n.t(Locations[location.toLowerCase()].text);
}
