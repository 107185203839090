<template>
  <form v-if="!submitted">
    <v-text-field
      v-model="message"
      outlined
      dense
      :placeholder="$t('recommendationsCardComponent.tellUsWhy')"
      hide-details
    />
    <button
      class="vfl-button-primary is-icon-only is-square"
      @click="submit"
      :disabled="isSubmitting || submitted || !message"
    >
      <v-icon v-if="!isSubmitting" color="white">mdi-chevron-right</v-icon>
      <v-progress-circular
        v-else
        size="16"
        width="2"
        indeterminate
        color="white"
      ></v-progress-circular
      ><span class="d-sr-only">{{
        $t("recommendationsCardComponent.submit")
      }}</span>
    </button>
  </form>
  <p v-else>{{ $t("recommendationsCardComponent.thanksForYourFeedback") }}</p>
</template>

<script>
export default {
  name: "RecommendationCardFeedback",
  props: {
    id: String
  },
  data() {
    return {
      isSubmitting: false,
      message: "",
      submitted: false
    };
  },
  methods: {
    submit() {
      this.isSubmitting = true;

      setTimeout(() => {
        this.submitted = true;
        this.isSubmitting = false;

        this.$mixpanel.track("Recommendation card feedback", {
          type: "Not interested",
          feedback: this.message,
          recommendation: this.id
        });
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
form {
  background: white;
  border-radius: 12px;
  display: flex;
  padding: 0.5rem;

  ::v-deep {
    .v-input__control {
      fieldset {
        border: 0;
      }
    }
  }

  button {
    &[disabled] {
      opacity: 0.7;
      pointer-events: none;
    }
  }
}

p {
  background-color: #050f19;
  border-radius: 12px;
  color: white;
  line-height: 56px;
  margin: 0;
  text-align: center;
}
</style>
