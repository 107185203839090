export const layoutStore = {
  namespaced: true,
  state: {
    isMinimised: false
  },
  mutations: {
    SET_SIDEBAR_MINIMISED(state, value) {
      state.isMinimised = value;
    }
  },
  actions: {
    setSidebarMinimised({ commit }, value) {
      commit("SET_SIDEBAR_MINIMISED", value);
    }
  },
  getters: {
    isSidebarMinimised: state => state.isMinimised
  }
};
