<template>
  <v-row justify="center" class="mt-4">
    <v-col md="6" sm="12" cols="12">
      <overall-score
        :score="score"
        :rating="rating"
        :scoreColour="scoreColour"
        :descriptionLocale="`${$options.localePath}.explanation`"
        :sections="$options.scoreSliderSections"
      />
    </v-col>
    <v-col md="6" sm="12" cols="12">
      <what-this-means :results="results" />
    </v-col>
  </v-row>
</template>

<script>
import WhatThisMeans from "@/components/driver-assessment/report/summary/WhatThisMeans.vue";
import OverallScore from "@/components/common/report/OverallScore.vue";
import ScoreService from "@/services/driver-assessment/driver-score-service.js";
import * as AssessmentScores from "@/services/driver-assessment/driver-assessment-scores.js";
const localePath = "driverAssessment.report.summary.overallScore";

const SCORE_SLIDER_SECTIONS = [
  { color: "vitrueRed", percent: 0 },
  {
    color: "vitrueYellow",
    percent: AssessmentScores.SCORE_BOUNDARY_VALUES.AVERAGE
  },
  {
    color: "vitrueBrightGreen",
    percent: AssessmentScores.SCORE_BOUNDARY_VALUES.GOOD
  },
  {
    color: "vitrueDarkGreen",
    percent: AssessmentScores.SCORE_BOUNDARY_VALUES.GREAT
  }
];

export default {
  name: "DriverSummaryContent",
  components: {
    OverallScore,
    WhatThisMeans
  },
  props: {
    results: {
      type: Object
    }
  },
  localePath: localePath,
  scoreSliderSections: SCORE_SLIDER_SECTIONS,
  computed: {
    score() {
      return ScoreService.getOverallScore(this.results);
    },
    rating() {
      const rating = AssessmentScores.GetScoreRatingName(this.score);
      return this.$t(`${localePath}.ratings.${rating}`);
    },
    scoreColour() {
      return AssessmentScores.OverallScoreColor(this.score);
    }
  }
};
</script>
