<template>
  <v-navigation-drawer
    :value="isOpen"
    @input="handleInput"
    @transitionend="onTransitionEnd"
    fixed
    right
    :width="componentWidth"
    :style="{ top: $vuetify.breakpoint.smAndDown ? '57px' : '65px' }"
    :hide-overlay="!hasOverlay"
    stateless
  >
    <button
      @click="close"
      class="vfl-button-primary-ghost is-icon-only close-button"
    >
      <v-icon>mdi-close</v-icon>
    </button>

    <div class="pa-4" style="height: 100%">
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "VflSidepanel",
  props: {
    isOpen: Boolean,
    width: {
      type: String,
      default: "420px"
    },
    hasOverlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      }

      return this.width;
    }
  },
  methods: {
    handleInput(value) {
      if (this.isOpen !== value) {
        this.$emit("update:is-open", value);
      }
    },
    close() {
      this.$emit("update:is-open", false);
    },
    onTransitionEnd() {
      if (!this.isOpen) {
        this.$emit("onCloseComplete");
      }
    }
  }
};
</script>
<style scoped lang="scss">
.close-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
</style>
