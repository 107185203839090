export const DESK_BACKGROUND_GRADIENT = `linear-gradient(
  80deg,
  hsl(0deg 0% 100%) 0%,
  hsl(210deg 100% 99%) 3%,
  hsl(208deg 100% 97%) 9%,
  hsl(208deg 100% 96%) 22%,
  hsl(209deg 100% 95%) 60%,
  hsl(208deg 100% 94%) 78%,
  hsl(208deg 100% 93%) 87%,
  hsl(218deg 100% 93%) 92%,
  hsl(226deg 100% 93%) 95%,
  hsl(235deg 100% 94%) 97%,
  hsl(244deg 100% 94%) 99%,
  hsl(252deg 100% 94%) 100%,
  hsl(261deg 100% 94%) 100%
)`;

export const Theme = {
  DESK: "#EEF5FC",
  DRIVER: "#F2FFF5",
  PHYSICAL: "#FFFBF1",
  RESILIENCE: "#F3F1FF",
  // DEMO ONLY
  AIRPLANE: "#DEEFFF",
  NEW_PARENTS: "#E4FFFA"
};

export const Image = {
  DESK: "images/illustrations/woman-waving-at-desk.svg",
  DRIVER: "images/illustrations/front-car-with-checkmark.svg",
  PHYSICAL: "images/illustrations/construction-workers-coffee-break.svg",
  RESILIENCE: "images/illustrations/office-worker-holding-shield.svg",
  // DEMO ONLY
  AIRPLANE: "images/illustrations/airplane-clouds.svg",
  NEW_PARENTS: "images/illustrations/new-parents.svg"
};
