export class SineWaveGenerator {
  static PI2 = Math.PI * 2;
  static HALFPI = Math.PI / 2;

  constructor(options) {
    Object.assign(
      this,
      {
        speed: 1,
        amplitude: 10,
        wavelength: 50,
        segmentLength: 10,
        lineWidth: 2,
        strokeStyle: "rgba(255, 255, 255, 0.5)",
        waves: [],
        time: 0,
        dpr: window.devicePixelRatio || 1,
        width: null,
        height: null,
        waveWidth: null,
        waveLeft: null
      },
      options
    );

    if (!this.el) {
      throw "No Canvas Selected";
    }
    this.ctx = this.el.getContext("2d");

    if (!this.waves.length) {
      throw "No waves specified";
    }

    this._resizeWidth();
    window.addEventListener("resize", this._resizeWidth.bind(this));
    this.resizeEvent();
    window.addEventListener("resize", this.resizeEvent.bind(this));

    if (typeof this.initialize === "function") {
      this.initialize.call(this);
    }

    this.loop();
  }

  resizeEvent() {}

  _resizeWidth() {
    this.width = this.canvasWidth
      ? this.canvasWidth * this.dpr
      : window.innerWidth * this.dpr;
    this.height = this.canvasHeight
      ? this.canvasHeight * this.dpr
      : window.innerHeight * this.dpr;

    this.el.width = this.width;
    this.el.height = this.height;
    this.el.style.width = `${this.canvasWidth || window.innerWidth}px`;
    this.el.style.height = `${this.canvasHeight || window.innerHeight}px`;

    this.waveWidth = this.width * 0.95;
    this.waveLeft = this.width * 0.025;
  }

  clear() {
    this.ctx.fillStyle = "white";
    this.ctx.fillRect(0, 0, this.width, this.height);
  }

  update(time) {
    this.time -= 0.007;
    time = time || this.time;

    this.waves.forEach(wave => {
      const timeModifier = wave.timeModifier || 1;
      this.drawSine(time * timeModifier, wave);
    });
  }

  drawSine(time, options = {}) {
    let { amplitude, wavelength, lineWidth, strokeStyle, segmentLength } =
      options;
    amplitude = amplitude || this.amplitude;
    wavelength = wavelength || this.wavelength;
    lineWidth = lineWidth || this.lineWidth;
    strokeStyle = strokeStyle || this.strokeStyle;
    segmentLength = segmentLength || this.segmentLength;

    let x = time;
    let y = 0;
    let amp = this.amplitude;
    const yAxis = this.height / 2;

    this.ctx.lineWidth = lineWidth * this.dpr;
    this.ctx.strokeStyle = strokeStyle;
    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    this.ctx.beginPath();
    this.ctx.moveTo(0, yAxis);
    this.ctx.lineTo(this.waveLeft, yAxis);

    for (let i = 0; i < this.waveWidth; i += segmentLength) {
      x = time * this.speed + (-yAxis + i) / wavelength;
      y = Math.sin(x);
      amp = this.ease(i / this.waveWidth, amplitude);
      this.ctx.lineTo(i + this.waveLeft, amp * y + yAxis);
    }

    this.ctx.lineTo(this.width, yAxis);
    this.ctx.stroke();
  }

  ease(percent, amplitude) {
    return (
      amplitude *
      (Math.sin(percent * SineWaveGenerator.PI2 - SineWaveGenerator.HALFPI) +
        1) *
      0.5
    );
  }

  loop() {
    this.clear();
    this.update();
    window.requestAnimationFrame(this.loop.bind(this));
  }
}
