<template>
  <user-profile-block>
    <template #title
      ><span>Assessments</span
      ><button
        @click="openInviteDialog"
        class="vfl-button-link ml-auto"
        style="text-decoration: none"
      >
        Send invite
      </button></template
    >
    <template #content>
      <user-profile-assessment-invite-dialog
        v-if="showInviteDialog"
        :show.sync="showInviteDialog"
      />

      <v-radio-group
        v-model="selectedAssessmentType"
        class="assessment-filter-header"
        background-color="transparent"
        row
        @change="handleAssessmentTypeChange"
      >
        <v-radio
          v-for="option in assessmentOptions"
          :key="option.name"
          :id="option.name"
          :value="option.name"
          :label="option.name"
          :class="isSelected(option.name) ? 'selected-tab' : ''"
        >
        </v-radio>
      </v-radio-group>

      <div
        v-if="displayedAssessments?.length && !isLoading"
        class="user-profile-assessments-container"
        :class="`is-${layout}`"
      >
        <user-profile-assessment-card
          v-for="assessment in displayedAssessments"
          :key="assessment.id"
          :assessment="assessment"
        />
      </div>

      <div v-else-if="isLoading" class="loader">
        <v-skeleton-loader type="image" width="178" height="122" />
        <v-skeleton-loader type="image" width="178" height="122" />
      </div>

      <div v-else>
        <p>No assessments found</p>
      </div></template
    >
  </user-profile-block>
</template>

<script>
import UserProfileAssessmentCard from "@/components/user-profile/UserProfileAssessmentCard.vue";
import { mapGetters } from "vuex";
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";
import UserProfileAssessmentInviteDialog from "@/components/user-profile/UserProfileAssessmentInviteDialog.vue";

const AssessmentType = {
  ALL: "all",
  LATEST: "latest"
};

export default {
  name: "UserProfileAssessments",
  components: {
    UserProfileAssessmentInviteDialog,
    UserProfileBlock,
    UserProfileAssessmentCard
  },
  props: {
    layout: {
      type: String,
      default: "page",
      validator: value => ["sidepanel", "page"].includes(value)
    }
  },
  data() {
    return {
      selectedAssessmentType: AssessmentType.LATEST,
      showInviteDialog: false
    };
  },
  computed: {
    ...mapGetters("userProfile", [
      "latestAssessments",
      "allAssessments",
      "loadingStates",
      "id"
    ]),
    isLoading() {
      return this.loadingStates.assessments;
    },
    displayedAssessments() {
      return this.selectedAssessmentType === AssessmentType.ALL
        ? this.allAssessments
        : this.latestAssessments;
    },
    assessmentOptions() {
      return [{ name: AssessmentType.LATEST }, { name: AssessmentType.ALL }];
    }
  },
  methods: {
    isSelected(name) {
      return this.selectedAssessmentType === name;
    },
    openInviteDialog: function () {
      this.$mixpanel.track("Send invite dialog opened from user profile", {
        viewing_user_id: this.id
      });
      this.showInviteDialog = true;
    },
    handleAssessmentTypeChange(newValue) {
      this.$mixpanel.track("Assessment display toggled in user profile", {
        viewing_user_id: this.id,
        value: newValue
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-assessments-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  &.is-page {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.loader {
  display: flex;
  gap: 0.5rem;
}

.v-skeleton-loader {
  border-radius: 16px;
}

.v-skeleton-loader__image {
  height: 100%;
  width: 100%;
}

.assessment-filter-header .v-radio {
  border: 1px solid var(--silver);
  border-radius: 8px;
  margin: 0 !important;
}

.assessment-filter-header .v-radio.selected-tab {
  border-color: var(--v-vflPrimary-base);
  background-color: var(--v-selectedPrimary-base);
  color: var(--v-vflPrimary-base);
}
</style>

<style>
.assessment-filter-header .v-input--radio-group__input {
  gap: 0.325rem;
}

.assessment-filter-header .v-input--selection-controls__input {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.assessment-filter-header .v-label {
  color: var(--v-vflPrimary-base);
  font-size: 0.875rem;
  line-height: 1.8;
  padding: 0 0.675rem;
  text-transform: capitalize;
}
</style>
