export function viewingOwnResults(route) {
  const restrictedPaths = [
    "/dashboard",
    "/certification",
    "/suggestions",
    "/analytics",
    "/people"
  ];

  return !restrictedPaths.includes(route.path);
}
