export function getConversationHeader(
  conversations,
  conversationIndex,
  conversation,
  locale
) {
  if (conversations.length === conversationIndex + 1) {
    return "NEW CONVERSATION";
  }

  const date = new Date(conversation.messages[0]?.createdAt || Date.now());
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatTime = date => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hour = hours % 12 || 12;
    return `${hour}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  if (date.toDateString() === today.toDateString()) {
    return `TODAY, ${formatTime(date)}`;
  }

  if (date.toDateString() === yesterday.toDateString()) {
    return `YESTERDAY, ${formatTime(date)}`;
  }

  // For dates within the current year
  if (date.getFullYear() === today.getFullYear()) {
    const formatter = new Intl.DateTimeFormat(locale, {
      day: "numeric",
      month: "short"
    });
    return `${formatter.format(date)}, ${formatTime(date)}`;
  }

  // For dates older than a year
  const formatter = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
  return formatter.format(date);
}
