<template>
  <div class="ai-search-feedback">
    <feedback-thumb-buttons @feedbackSubmitted="onFeedbackSubmitted" />
  </div>
</template>

<script>
import FeedbackThumbButtons from "@/components/common/Feedback/FeedbackThumbButtons.vue";
import { sendAssistantFeedback } from "@/customApi";

export default {
  name: "AiSearchFeedback",
  components: { FeedbackThumbButtons },
  props: {
    interactionId: String
  },
  methods: {
    async onFeedbackSubmitted(value) {
      if (this.interactionId) {
        await sendAssistantFeedback(this.interactionId, value);
      }
    }
  }
};
</script>
