<template>
  <div
    class="ai-assistant-error-message-container text-body-2 mb-6 py-3 px-4"
    role="alert"
    aria-live="assertive"
  >
    <div class="icon-column" aria-hidden="true">
      <v-icon size="24" color="black">mdi-alert-outline</v-icon>
    </div>
    <div class="text-column vflText--text text-body-2">
      <h4>Try again</h4>
      <p class="mb-0">I couldn’t manage the request this time.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "AiAssistantErrorMessage",
  props: {
    content: String,
    required: true
  }
};
</script>

<style lang="scss" scoped>
.ai-assistant-error-message-container {
  background-color: #fff5f5;
  border-radius: 16px;
  border: #ffe1e1 1px solid;
  display: flex;
  align-items: start;
}

.icon-column {
  margin-right: 10px;
  align-items: center;
}

.text-column {
  display: flex;
  flex-direction: column;
}
</style>
