<template>
  <div>
    <div :class="`response response-${response?.type}`">
      <div v-if="loading" class="loader" aria-live="polite">
        <p class="mb-0" style="min-width: 200px">{{ loadingMessage }}</p>

        <SineWaves :animate="true" :height="50" :width="300" />
      </div>

      <div v-else-if="showResponse">
        <p class="mb-0" style="max-width: 90ch">
          <strong
            v-if="response.type === 'success' && response.count"
            class="d-flex mb-1"
            >{{ response.count }}
          </strong>
          <span v-html="formattedText"></span>
        </p>
        <ai-search-feedback
          v-if="response.type === 'success'"
          class="mt-4"
          :interactionId="response.interactionId"
        />
      </div>
    </div>

    <small class="d-block my-4" v-if="response.type === 'success'"
      >AI search results may not be 100% accurate</small
    >
  </div>
</template>
<script>
import SineWaves from "@/components/common/animations/SineWaves.vue";
import AiSearchFeedback from "@/components/common/assessment-history/AiSearchFeedback.vue";

export default {
  name: "AiSearchResponse",
  components: { SineWaves, AiSearchFeedback },
  props: {
    loading: Boolean,
    response: Object
  },
  data() {
    return {
      loadingMessage: "...",
      loadingMessages: [
        { text: "Thinking..." },
        { text: "Thinking..." },
        { text: "Doing a brain stretch..." },
        { text: "Crunching numbers..." },
        { text: "Interpreting tea leaves..." },
        { text: "Chasing down bytes..." },
        { text: "Exploring possibilities..." },
        { text: "Verifying details..." },
        { text: "Delving deeper..." },
        { text: "Rethinking strategy..." },
        { text: "Reevaluating options..." },
        { text: "Exploring new angles..." },
        { text: "Switching tactics..." },
        { text: "Revising approach..." },
        { text: "Seeking alternative paths..." }
      ],
      messageIndex: 0,
      interval: null
    };
  },
  watch: {
    loading(newVal) {
      if (newVal) {
        this.startLoadingSequence();
      } else {
        this.clearLoadingInterval();
      }
    }
  },
  mounted() {
    if (this.loading) this.startLoadingSequence();
  },
  computed: {
    showResponse() {
      return this.response.text;
    },
    formattedText() {
      return this.response.text?.replace(/\n/g, "<br>");
    }
  },
  methods: {
    startLoadingSequence() {
      this.clearLoadingInterval();
      this.setRandomDurations();

      this.messageIndex = 0;
      this.loadingMessage = this.loadingMessages[0].text;

      this.setNextMessage();
    },
    setNextMessage() {
      this.messageIndex++;
      if (this.messageIndex >= this.loadingMessages.length) {
        this.messageIndex = 0;
      }
      this.loadingMessage = this.loadingMessages[this.messageIndex].text;

      this.interval = setTimeout(() => {
        this.setNextMessage();
      }, this.loadingMessages[this.messageIndex].duration);
    },
    setRandomDurations() {
      this.loadingMessages.forEach((message, i) => {
        // Random duration between .5s-4s
        message.duration = Math.floor(Math.random() * 3500) + 500;
      });
    },
    clearLoadingInterval() {
      clearTimeout(this.interval);
      this.interval = null;
    }
  },
  beforeDestroy() {
    this.clearLoadingInterval();
  }
};
</script>
<style lang="scss" scoped>
.response {
  padding: 0 1rem;
  position: relative;

  &:before {
    background-image: linear-gradient(
      to right,
      var(--v-vflPrimary-base),
      var(--v-vflPurple-base)
    );
    border-radius: 10px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &.response-success {
  }

  &.response-error {
  }
}

.loader {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}
</style>
