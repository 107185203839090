<template>
  <v-row align="center" justify="start" style="height: 100%" dense
    ><v-col
      cols="4"
      sm="2"
      v-if="teamName || teamLogoURL"
      class="d-flex justify-center"
    >
      <v-btn
        icon
        v-if="teamName && !teamLogoURL"
        text
        @click="clickIcon()"
        :disabled="!isSuperAdmin"
        :width="logoSize"
        :height="logoSize"
      >
        <initials-icon
          :text="teamName"
          color="D9D9D9"
          TextColor="6E6E6E"
          :size="logoSize"
          defaultIcon="mdi-office-building-outline"
      /></v-btn>
      <div
        v-else-if="teamLogoURL"
        class="image-crop-container"
        :style="`position: relative;text-align: center;width: ${logoSize}px;height: ${logoSize}px;`"
      >
        <img :src="teamLogoURL" style="margin: auto; display: block" />
      </div>
    </v-col>
    <v-col cols="8" sm="10" v-if="teamName" class="text-body-1">
      <div
        class="hideOverflow"
        :class="$vuetify.breakpoint.xs ? 'limitWidth' : ''"
      >
        {{ teamName }}
      </div></v-col
    ></v-row
  >
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getTeamDetails } from "@/customApi.js";
import InitialsIcon from "./InitialsIcon.vue";

export default {
  name: "top-bar",
  components: {
    InitialsIcon
  },
  props: {
    logoSize: String
  },
  created() {
    this.getTeamDetails();
  },
  computed: {
    ...mapGetters(["signedIn", "isSuperAdmin", "teamName", "teamLogoURL"])
  },
  methods: {
    ...mapMutations(["setTeamName", "setTeamLogoURL"]),
    clickIcon() {
      if (this.$route.name === "Settings") return;
      this.$router.push({
        name: "Settings",
        params: { tabToShow: "workspacesettings" }
      });
    },
    async getTeamDetails() {
      try {
        var teamDetails = await getTeamDetails();
        this.setTeamName(teamDetails.name);
        this.setTeamLogoURL(teamDetails.logoURL);
      } catch (err) {
        this.$logger.captureException(err);
      }
    }
  }
};
</script>

<style scoped>
.bar-style {
  width: 1px;
  background-color: var(--v-vitrueGrey-base);
}

.hideOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limitWidth {
  max-width: 200px;
}
</style>
