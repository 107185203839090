<template>
  <initials-icon :text="name" :fallback-text="email" size="50" />
</template>
<script>
import InitialsIcon from "@/components/layout/InitialsIcon.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileAvatar",
  components: { InitialsIcon },
  computed: {
    ...mapGetters("userProfile", ["name", "email"])
  }
};
</script>
