<template>
  <div>
    <v-menu
      v-model="panel"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on">
          <v-icon class="mt-n1">mdi-cog</v-icon>
        </button>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="settings.useDefaults"
                color="vflPrimary"
                @change="emitSettings"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Use default settings</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="!settings.useDefaults">
            <v-list-item-action>
              <v-switch
                v-model="settings.maintainSession"
                color="vflPrimary"
                @change="emitSettings"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Maintain Session</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!settings.useDefaults">
            <v-list-item-content>
              <v-list-item-title class="mb-n1 mt-2"
                ><span class="text-body-bold"
                  >Select an assistant</span
                ></v-list-item-title
              >
              <v-select
                v-model="settings.selectedAssistant"
                :items="assistantOptions"
                @change="emitSettings"
                color="vflPrimary"
              ></v-select>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            class="vfl-button-primary-ghost is-small"
            @click="panel = false"
          >
            Cancel
          </button>
          <button class="vfl-button-primary is-small" @click="panel = false">
            Save
          </button>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: false,
      settings: {
        useDefaults: true,
        maintainSession: false,
        selectedAssistant: "triage"
      },
      assistantOptions: [
        { text: "Triage", value: "triage" },
        { text: "Completion", value: "completion" },
        { text: "Health Risk", value: "healthRisk" },
        { text: "Original User History", value: "userHistory" }
      ]
    };
  },
  created() {
    const localSettings = JSON.parse(localStorage.getItem("aiSettings"));
    if (localSettings) {
      this.settings.useDefaults = false;
      this.settings.selectedAssistant = localSettings.selectedAssistant;
    }
  },
  methods: {
    emitSettings() {
      this.$emit("ai-search-settings-changed", { ...this.settings });
    }
  }
};
</script>
