<template>
  <v-row class="mt-4">
    <v-col cols="12" md="6" lg="6">
      <overall-score
        :score="score"
        :sections="$options.scoreBoundaries"
        :scoreColour="scoreColor"
        :descriptionLocale="scoreLocale"
        :painArea="allPainAreas"
        :rating="risk"
      />
    </v-col>
    <v-col cols="12" md="6" lg="6">
      <why-it-matters :results="results" />
    </v-col>
  </v-row>
</template>

<script>
import OverallScore from "@/components/common/report/OverallScore.vue";
import WhyItMatters from "./WhyItMatters.vue";
import { getOverallScore } from "@/services/assessment/physical-labour/overall-scoring-service.js";
import {
  getScoreColor,
  getScoreRatingName,
  SCORE_SLIDER_SECTIONS,
  ScoreBoundaryNames
} from "@/services/assessment/physical-labour/score-interpretation-service.js";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
const localePath = "physicalLabourAssessment.report.summary.overallScore";

export default {
  name: "PhysicalLabourSummaryContent",
  components: {
    OverallScore,
    WhyItMatters
  },
  props: {
    results: {
      type: Object
    }
  },
  scoreBoundaries: SCORE_SLIDER_SECTIONS,
  data() {
    return {
      score: null,
      scoreColor: null
    };
  },
  created() {
    this.score = getOverallScore(this.results);
    this.scoreColor = getScoreColor(this.score);
  },
  computed: {
    risk() {
      return this.$t(`${localePath}.risks.${getScoreRatingName(this.score)}`);
    },
    scoreLocale() {
      const hasPain = !!this.allPainAreas;
      const painType = hasPain ? "Pain" : "NoPain";
      const rating = getScoreRatingName(this.score);

      switch (rating) {
        case ScoreBoundaryNames.NEGLIGIBLE:
        case ScoreBoundaryNames.LOW:
          return `${localePath}.explanations.lowReba${painType}`;
        case ScoreBoundaryNames.HIGH:
          return `${localePath}.explanations.highReba${painType}`;
        case ScoreBoundaryNames.VERY_HIGH:
          return `${localePath}.explanations.veryHighReba${painType}`;
        default:
          return `${localePath}.explanations.mediumReba${painType}`;
      }
    },
    allPainAreas() {
      const hasPain = Object.keys(this.results.pain.areas).length > 0;

      if (!hasPain) {
        return null;
      }

      const allPainAreas = PainService.getAllPainAreasText(this.results);
      return this.$t(`${localePath}.pain`, {
        painArea: allPainAreas.toLowerCase()
      });
    }
  }
};
</script>
