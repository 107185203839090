<template>
  <div v-if="isLoading" class="loader">
    <v-skeleton-loader
      type="chip"
      height="24"
      width="100"
      class="loader-skeleton"
    />
    <v-skeleton-loader
      type="chip"
      height="24"
      width="100"
      class="loader-skeleton"
    />
  </div>

  <div class="user-profile-msk-overview" v-else-if="hasMskDetails">
    <user-profile-health-conditions v-if="enablePreExistingConditions" />
    <user-profile-pain />
  </div>

  <div v-else>
    <vfl-chip
      label="No pain"
      background-color="#F2FFF5"
      border-color="#D7EBDB"
    />
  </div>
</template>

<script>
import UserProfileHealthConditions from "@/components/user-profile/UserProfileHealthConditions.vue";
import UserProfilePain from "@/components/user-profile/UserProfilePain.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "UserProfileMskOverview",
  components: { UserProfilePain, UserProfileHealthConditions },
  computed: {
    ...mapGetters(["enablePreExistingConditions"]),
    ...mapState("userProfile", ["loadingStates", "pain", "healthConditions"]),
    isLoading() {
      return this.loadingStates.mskDetails;
    },
    hasMskDetails() {
      return this.pain?.length || this.healthConditions?.length;
    }
  }
};
</script>
<style lang="scss" scoped>
.user-profile-msk-overview {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.loader {
  display: flex;
  gap: 0.5rem;

  .loader-skeleton ::v-deep .v-skeleton-loader__chip {
    height: 100%;
    width: 100%;
  }
}
</style>
