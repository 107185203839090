<template>
  <v-app id="app">
    <top-bar
      v-model="toggleSideBar"
      :showSideBar="showSideBar"
      class="no-print"
    />
    <side-bar v-model="toggleSideBar" v-if="showSideBar" class="no-print" />
    <v-main>
      <router-view :class="$vuetify.breakpoint.xs ? 'pa-1' : 'pa-3'" />
    </v-main>
    <AdminChecklist
      v-if="showOnboardingChecklist && showSideBar"
      class="checkList"
    />

    <v-overlay :value="activeAsyncRequestsCount" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <cookie-law
      theme="personalisedCookieTheme"
      :buttonText="$t(`cookieBar.okButton`)"
      buttonLink="https://www.vitrueremote.com/privacy"
      :buttonLinkText="$t(`cookieBar.policy`)"
      buttonLinkNewTab
      ><div slot="message">
        {{ $t("cookieBar.message") }}
      </div></cookie-law
    >
    <v-snackbar
      v-model="showRefreshBar"
      top
      color="primary"
      timeout="-1"
      transition="fade-transition"
      content-class="text-center"
    >
      <v-btn
        class="mr-2"
        @click="setShowRefreshBar(false)"
        icon
        small
        color="white"
      >
        <v-icon>clear</v-icon>
      </v-btn>
      {{ $t("newVersion.text") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" outlined text v-bind="attrs" @click="refreshPage">
          {{ $t("newVersion.refresh") }}
        </v-btn>
      </template>
    </v-snackbar>
    <short-feedback
      v-if="showAdminSiteWideFeedback"
      is-dismissible
      @onClose="onFeedbackClosed"
    />
    <user-profile-snackbar />
  </v-app>
</template>

<script>
import SideBar from "./components/layout/SideBar.vue";
import TopBar from "./components/layout/TopBar.vue";
import OldTopBar from "./components/layout/OldTopBar.vue";
import CookieLaw from "vue-cookie-law";
import { mapGetters, mapActions, mapMutations } from "vuex";
import AdminChecklist from "@/components/account/onboarding/AdminChecklist.vue";
import TeamService from "@/services/teamService.js";
import FeatureService from "@/services/new-feature-service.js";
import LanguageService from "@/services/language-service.js";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";
import {
  markFeedbackAsSeen,
  showAdminFeedback,
  FEEDBACK_VISIBILITY_DELAY
} from "@/services/feedback-service.js";
import UserProfileSnackbar from "@/components/user-profile/UserProfileSnackbar.vue";

export default {
  components: {
    UserProfileSnackbar,
    ShortFeedback,
    SideBar,
    TopBar,
    CookieLaw,
    AdminChecklist,
    OldTopBar
  },
  data() {
    return {
      toggleSideBar: null,
      showAdminSiteWideFeedback: false,
      feedbackDelayTimer: null
    };
  },
  created() {
    this.setAppLoading(true);
    LanguageService.syncLocale();
  },
  mounted() {
    if (
      !localStorage.AppVersion ||
      (localStorage.AppVersion &&
        localStorage.AppVersion != import.meta.env.VITE_VERSION)
    ) {
      localStorage.AppVersion = import.meta.env.VITE_VERSION;
    }
  },
  watch: {
    async signedIn(newVal) {
      if (newVal) {
        this.$mixpanel.track(`User signed in (login or refresh)`);
      }
      if (newVal && !this.appLoading) {
        await this.getUserInfo();
        this.setAppLoading(false);
      }
    },
    $route(newRoute) {
      this.handleFeedbackVisibility(newRoute);
    }
  },
  beforeDestroy() {
    this.setAppLoading(false);

    if (this.feedbackDelayTimer) {
      clearTimeout(this.feedbackDelayTimer);
    }
  },
  methods: {
    ...mapActions([
      "setCurrentUserData",
      "getUserTeamAndSubscriptionBaseInformation"
    ]),
    ...mapMutations([
      "setTeamMember",
      "setShowRefreshBar",
      "setAppLoading",
      "setTeamMemberId"
    ]),
    refreshPage() {
      localStorage.AppVersion = import.meta.env.VITE_VERSION;
      this.setShowRefreshBar(false);
      window.location.reload();
    },
    async getUserInfo() {
      if (
        !this.userId ||
        !this.currentUserTeamAndSubscriptionBasicInformation
      ) {
        let teamMberInfo = await TeamService.getTeamMemberInfo();
        await this.setCurrentUserData(teamMberInfo);
        if (!this.isSimpleUser) {
          if (!this.teamMemberId) {
            this.setTeamMemberId(teamMberInfo.teamMemberId);
          }
          if (this.teamMemberId) {
            await this.getUserTeamAndSubscriptionBaseInformation({
              memberId: this.teamMemberId,
              forceToUpdateExistedInformation: false
            });
          }
        }
      }

      let featureIntrosNeedsLoading =
        this.featureIntros &&
        (this.featureIntros.length == 0 ||
          this.featureIntros[0].userId !== this.userId);

      if (this.userId && (!this.featureIntros || featureIntrosNeedsLoading)) {
        await FeatureService.getActiveFeatureIntros();
      }
    },
    handleFeedbackVisibility(route) {
      if (this.feedbackDelayTimer) {
        clearTimeout(this.feedbackDelayTimer);
      }

      this.feedbackDelayTimer = setTimeout(() => {
        this.showAdminSiteWideFeedback = this.isAdminUser
          ? showAdminFeedback(route)
          : false;
      }, FEEDBACK_VISIBILITY_DELAY);
    },
    onFeedbackClosed() {
      markFeedbackAsSeen();

      if (this.feedbackDelayTimer) {
        clearTimeout(this.feedbackDelayTimer);
      }
      this.showAdminSiteWideFeedback = false;
    }
  },
  computed: {
    ...mapGetters([
      "activeAsyncRequestsCount",
      "isSimpleUser",
      "teamMemberId",
      "signedIn",
      "showRefreshBar",
      "showOnboardingChecklist",
      "featureIntros",
      "appLoading",
      "showDummySidebar",
      "userId",
      "currentUserTeamAndSubscriptionBasicInformation",
      "isAdminUser"
    ]),
    showSideBar() {
      return (
        this.showDummySidebar ||
        (this.signedIn && !this.$route.meta?.hideSideBar)
      );
    }
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}

ul.sidebar-panel-nav > li > a > i {
  padding-right: 0.5em;
}

#bottom-sidebar {
  position: fixed;
  bottom: 10px;
}

.checkList {
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
}
</style>
