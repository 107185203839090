const scoreBoundaries = {
  poor: 0,
  average: 35,
  good: 56,
  veryGood: 78,
  max: 100
};

function deskAssessmentScoreColor(score) {
  switch (true) {
    case score >= scoreBoundaries.veryGood:
      return { thumb: "vitrueDarkGreen", text: "vidaPainDarkGreen" };
    case score >= scoreBoundaries.good:
      return { thumb: "vitrueBrightGreen", text: "vidaPainGreen" };
    case score >= scoreBoundaries.average:
      return { thumb: "vitrueYellow", text: "vidaPainOrange" };
    case score >= scoreBoundaries.poor:
      return { thumb: "vitrueRed", text: "vitrueRed" };
  }
}

export { scoreBoundaries, deskAssessmentScoreColor };
