export const HISTORY_TYPES = {
  ASSESSMENT: "ASSESSMENT",
  PAIN: "PAIN",
  RECOMMENDATION: "RECOMMENDATION",
  ACCOUNT: "ACCOUNT"
};

export const ASSESSMENT_HISTORY_STATES = {
  CREATED: "CREATED",
  OPENED: "OPENED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED"
};
