<template>
  <v-navigation-drawer
    id="navDrawer"
    :key="`mini:${isMinimised}`"
    v-model="drawer"
    v-if="$route.name !== '404'"
    color="white"
    clipped
    app
    touchless
    height="100%"
    :width="isSimpleUser ? '175px' : '200px'"
    :mini-variant="isMinimised"
    style="overflow: visible"
    :permanent="$vuetify.breakpoint.mdAndUp"
  >
    <template v-slot:prepend>
      <vitrue-new-feature
        v-if="showNavV3Tooltip"
        v-model="navV3Tooltip"
        :text="$t('tooltips.newFeatures.navigationV3')"
        style="top: 400px"
        :width="$vuetify.breakpoint.smAndDown ? 250 : 500"
        @close="navV3Tooltip = false"
        id="NavigationV3"
      />
      <v-icon
        v-if="$vuetify.breakpoint.mdAndUp"
        :class="isMinimised ? 'ml-4' : 'collapseButton'"
        class="ma-2"
        @click.stop="isMinimised = !isMinimised"
        >{{
          isMinimised ? "mdi-chevron-double-right" : "mdi-chevron-double-left"
        }}</v-icon
      >

      <div
        :class="showHeadings ? 'px-4 pt-8' : ''"
        class="font-weight-medium black--text"
        v-for="group in buttonGroups"
        :key="group.key"
      >
        <div v-if="group.show">
          <p
            v-show="showHeadings"
            class="text-body-1 mb-0 text-overline"
            style="cursor: default; color: #626e7c; line-height: 1.5"
          >
            {{ group.heading }}
          </p>
          <v-list
            :class="
              isMinimised && group.key === 'personal' && !isSimpleUser
                ? 'mt-14'
                : ''
            "
          >
            <div v-for="button in group.buttons" :key="button.key" class="">
              <v-list-item
                v-if="button.show"
                link
                @click="navigate(button)"
                class="text-body-1 sidebar-list-item"
                :ripple="isMinimised ? false : true"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="
                      activeTab == button.key ? 'var(--v-vflPrimary-base)' : ''
                    "
                    size="20"
                    >{{ button.icon }}</v-icon
                  >
                </v-list-item-icon>

                <v-list-item-content
                  v-if="!isMinimised"
                  :class="[{ 'd-sr-only': isMinimised }]"
                >
                  <v-list-item-title
                    class="text-small"
                    :class="{ 'is-active': activeTab == button.key }"
                    >{{ button.title }}</v-list-item-title
                  >
                </v-list-item-content>
                <vitrue-onboarding-tooltip
                  v-if="
                    showOnboardingToolTip(
                      button.key,
                      'PersonalDashboard',
                      'viewPainCoach'
                    )
                  "
                  position="right"
                  :text="$t('onboarding.adminCheckList.tooltips.viewPainCoach')"
                  :width="250"
                />
                <vitrue-onboarding-tooltip
                  v-if="
                    showOnboardingToolTip(
                      button.key,
                      'Insights',
                      'viewTeamData'
                    )
                  "
                  position="right"
                  :text="$t('onboarding.adminCheckList.tooltips.viewTeamData')"
                  :width="250"
                />
              </v-list-item>
            </div>
          </v-list>
        </div>
      </div>
    </template>

    <template v-slot:append>
      <div
        style="position: relative"
        :class="isMinimised ? 'px-2' : 'px-4'"
        class="mb-6"
      >
        <site-feedback :has-text="!isMinimised" class="mb-8" />
        <v-menu top offset-x absolute nudge-right="20px">
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              dot
              :value="newFeatureInUserDropDownList && !isMinimised"
              color="vitrueRed"
              offset-y="20"
              offset-x="-4"
              :style="{ marginLeft: isMinimised ? '.2rem' : '0' }"
            >
              <v-row v-bind="attrs" v-on="on" no-gutters align="center">
                <v-col cols="auto">
                  <v-badge
                    dot
                    :value="newFeatureInUserDropDownList && isMinimised"
                    color="vitrueRed"
                    offset-x="11"
                    offset-y="5"
                    overlap
                  >
                    <initials-icon
                      class="mr-2"
                      :text="userName"
                      :fallback-text="userEmail"
                      :defaultColor="isAccount ? 'primary' : ''"
                      size="32px"
                    />
                  </v-badge>
                </v-col>
                <span class="font-weight-medium text-small">{{
                  isMinimised ? "" : $t("layout.sidebar.account")
                }}</span>
              </v-row>
            </v-badge>
          </template>

          <v-list dense class="py-0">
            <div v-for="item in userDropDownList" :key="item.key">
              <v-list-item
                v-if="!item.hide"
                @click="dropDownItemClicked(item)"
                :class="activeTab == item.key ? 'primary--text' : ''"
              >
                <v-list-item-title v-if="!item.hide"
                  ><v-icon
                    class="pb-1 mr-2"
                    :color="activeTab == item.key ? 'primary' : ''"
                    >{{ item.icon }}</v-icon
                  ><v-badge
                    :value="item.newFeature"
                    dot
                    right
                    offset-x="-4"
                    color="vitrueRed"
                    ><span>{{ item.title }}</span></v-badge
                  ></v-list-item-title
                >
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters, mapActions } from "vuex";
import VitrueOnboardingTooltip from "@/components/common/VitrueOnboardingTooltip.vue";
import RestApiClient from "@/infrastructure/restApiClient";
import InitialsIcon from "./InitialsIcon.vue";
import NewFeatureService from "@/services/new-feature-service.js";
import VitrueNewFeature from "@/components/common/VitrueNewFeature.vue";
import { getSSOCustomisation } from "@/services/authorization/sso-customisation-service.js";
import SiteFeedback from "@/components/layout/SiteFeedback.vue";

export default {
  name: "side-bar",
  components: {
    SiteFeedback,
    VitrueOnboardingTooltip,
    InitialsIcon,
    VitrueNewFeature
  },
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      drawer: null,
      activeTab: "adminDashboard",
      navV3Tooltip: true
    };
  },
  created() {
    this.setActiveTab();
  },
  methods: {
    ...mapActions(["resetLoginState"]),
    ...mapActions("layout", ["setSidebarMinimised"]),
    signOut() {
      let enterpriseSSO = getSSOCustomisation(this.teamIdpName) != null;
      if (this.ssoSignIn && enterpriseSSO) {
        sessionStorage.setItem("ssoSignOut", "true");
        sessionStorage.setItem("teamId", this.teamId);
        sessionStorage.setItem("idpName", this.teamIdpName);
      }
      localStorage.removeItem(RestApiClient.sessionHeaderKey);
      Auth.signOut().then(() => {
        this.resetLoginState();
        this.$router.push("/", () => {});
      });
    },
    navigate(item) {
      if (!item.path) {
        return;
      }
      if (
        item.path === "/wellness" &&
        this.$route.path.includes("deskasssessment")
      ) {
        this.$mixpanel.track(`Go to dashboard after Desk assessment`, {
          fromSidebar: true
        });
      }
      this.$mixpanel.track(`New side bar navigation`, {
        route: item.path
      });
      this.$router.push(item.path, () => {});
      this.$vuetify.goTo(0);
      this.activeTab = item.key;
    },
    openHelpPage() {
      this.$gtag.event("Open help page", {
        event_category: "Sidebar"
      });
      window.open("https://www.vitrueremote.com/faqs", "_blank");
    },
    dropDownItemClicked(item) {
      if (item.key === "Logout") {
        this.signOut();
        return;
      }
      if (item.key === "Help") {
        this.openHelpPage();
        return;
      }
      this.navigate(item);
    },
    showOnboardingToolTip(page, tooltipPage, tooltip) {
      return (
        page === tooltipPage && this.onboardingChecklist.hoveredItem === tooltip
      );
    },
    setActiveTab(to) {
      var path = to ? to.fullPath : this.$route.path;
      var topTabs = this.buttonGroups.reduce(
        (acc, item) => acc.concat(item.buttons),
        []
      );
      var allTabs = [...topTabs, ...this.userDropDownList];
      var tabs = allTabs.map(x => x.subSections ?? x).flat();
      var activeTab = tabs.find(x => path.includes(x.path));
      this.activeTab = activeTab?.key;
    }
  },
  computed: {
    ...mapGetters([
      "isSimpleUser",
      "userName",
      "userEmail",
      "teamId",
      "teamIdpName",
      "ssoSignIn",
      "onboardingChecklist",
      "showDummySidebar",
      "isSuperAdmin",
      "showReportsArchive",
      "enableUserPassport",
      "subscriptionExpired",
      "enableEmployeesPage"
    ]),
    ...mapGetters("layout", ["isSidebarMinimised"]),
    isAccount() {
      return this.userDropDownList.map(x => x.key).includes(this.activeTab);
    },
    endUserOrNotLoggedIn() {
      return this.isSimpleUser || this.showDummySidebar;
    },
    showHeadings() {
      return !this.isMinimised && !this.endUserOrNotLoggedIn;
    },
    buttonGroups() {
      return [
        {
          key: "team",
          show: !this.endUserOrNotLoggedIn,
          heading: "Team",
          buttons: [
            {
              key: "AdminDashboard",
              icon: "grid_view",
              path: "/dashboard",
              title: this.$t("layout.sidebar.dashboard"),
              show: true
            },
            {
              key: "EmployeesD",
              icon: "mdi-account-multiple-outline",
              path: "/employees-d",
              title: "Employees",
              show: this.enableUserPassport
            },
            {
              key: "Employees",
              icon: "mdi-account-multiple-outline",
              path: "/people",
              title: "People",
              show: this.enableEmployeesPage
            },
            {
              key: "Insights",
              icon: "mdi-star-four-points-outline",
              path: "/analytics",
              title: this.$t("layout.sidebar.insights"),
              show: !this.subscriptionExpired
            },
            {
              key: "Suggestions",
              icon: "mdi-list-status",
              path: "/suggestions",
              title: this.$t("layout.sidebar.suggestions"),
              show: !this.subscriptionExpired
            },
            {
              key: "Certification",
              icon: "mdi-license",
              path: "/certification",
              title: this.$t("layout.sidebar.regulatory"),
              show: !this.subscriptionExpired
            },
            {
              key: "Users",
              icon: "mdi-account-multiple-outline",
              path: "/users",
              title: this.$t("layout.sidebar.users"),
              show: !this.subscriptionExpired
            }
          ]
        },
        {
          key: "personal",
          show: true,
          heading: "Personal",
          buttons: [
            {
              key: "PersonalDashboard",
              path: "/wellness",
              icon: "mdi-gymnastics",
              title: this.$t("layout.sidebar.wellbeing"),
              show: true
            },
            {
              key: "Reports",
              icon: "mdi-clipboard-check-outline",
              path: "/reports-archive",
              title: this.$t("layout.sidebar.reports"),
              show: !this.subscriptionExpired
            }
          ]
        }
      ];
    },
    userDropDownList() {
      return [
        {
          key: "Settings",
          icon: "mdi-cog-outline",
          title: this.$t("layout.sidebar.settings"),
          path: "/settings",
          newFeature: this.showCustomRecommendationsFeature
        },
        {
          hide: !this.isSuperAdmin,
          key: "Subscription",
          icon: "mdi-account-circle",
          title: this.$t("layout.sidebar.subscription"),
          path: "/subscription",
          newFeature: false
        },
        {
          key: "Help",
          icon: "help_outline",
          title: this.$t("layout.sidebar.help"),
          newFeature: false
        },
        {
          key: "Logout",
          icon: "exit_to_app",
          title: this.$t("layout.sidebar.logout"),
          newFeature: false
        }
      ];
    },
    showCustomRecommendationsFeature() {
      let shouldShowCustomRecs = NewFeatureService.showFeature(
        "CustomRecommendations"
      );
      return shouldShowCustomRecs;
    },
    showNavV3Tooltip() {
      let showTooltip = NewFeatureService.showFeature("NavigationV3");
      return showTooltip && this.navV3Tooltip;
    },
    newFeatureInUserDropDownList() {
      return this.userDropDownList.filter(x => x.newFeature).length > 0;
    },
    isMinimised: {
      get() {
        return this.isSidebarMinimised;
      },
      set(value) {
        this.setSidebarMinimised(value);
      }
    }
  },
  watch: {
    $route(to) {
      this.setActiveTab(to);
    },
    value() {
      this.drawer = this.value;
    },
    drawer() {
      this.$emit("input", this.drawer);
    }
  }
};
</script>

<style scoped lang="scss">
.collapseButton {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  z-index: 10;
}

#navDrawer:hover .collapseButton {
  display: block;
}

::v-deep .v-list-group--sub-group .v-list-group__header {
  padding-left: 16px !important;
}

* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}

.sidebar-list-item {
  color: #343d48;
  min-height: initial;
  padding: 0.5rem 1rem;

  .v-list-item__icon {
    margin: 0 4px 0 0;

    i {
      color: #343d48;
    }
  }

  .v-list-item__content {
    line-height: 1.35;
    padding: 0;
  }
}

.is-active {
  color: var(--v-vflPrimary-base);
}
</style>
