<template>
  <div>
    <button
      class="feedback-button text-small"
      @click="feedbackDialog = true"
      :class="{ 'is-icon-only': !hasText, active: feedbackDialog }"
    >
      <v-icon color="black" size="20">mdi-message-text-outline</v-icon>
      <span
        class="pl-2"
        :class="{ 'd-sr-only': !hasText }"
        style="font-weight: 600; line-height: 1.3"
        >{{ $t("feedback.feedbackButton") }}</span
      >
    </button>
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
    >
      <feedback-dialog v-model="feedbackDialog" />
    </v-dialog>
  </div>
</template>

<script>
import FeedbackDialog from "@/components/common/Feedback/FeedbackDialog.vue";
const EVENT_NAME = "Open regular feedback dialog from ";

export default {
  name: "SiteFeedback",
  components: {
    FeedbackDialog
  },
  props: {
    hasText: Boolean
  },
  data() {
    return {
      feedbackDialog: false
    };
  },
  watch: {
    feedbackDialog: function (newVal) {
      if (newVal === true) {
        const data = EVENT_NAME + this.$route.name;

        this.$gtag.event(data, {
          event_category: "Feedback"
        });

        this.$mixpanel.track(data);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.feedback-button {
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0.5rem 0.75rem;
  transition: border-color 0.05s ease-out;

  &:hover {
    border-color: hsla(0, 0%, 0%, 0.3);
  }

  &.is-icon-only {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.active {
    border-color: hsla(0, 0%, 0%, 0.3);
  }
}
</style>
