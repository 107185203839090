<template>
  <div>
    <vfl-textarea
      v-model="note"
      :show-counter="false"
      :height="'120'"
      label="Create new note"
    />

    <div class="note-sharing-info mt-3">
      <p class="mb-0 text-body-dark text-body-2">
        <v-icon size="20">mdi-eye-outline</v-icon> Visible to other admins in
        your team
      </p>
    </div>

    <div class="d-flex align-center justify-end">
      <button
        class="vfl-button-primary is-small mt-3"
        :class="{ 'is-disabled': !note.trim() || isSaving }"
        @click="handleAddNote"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import VflTextarea from "@/components/vitrue-foundation-library/form/VflTextarea.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileAddNote",
  components: { VflTextarea },
  data() {
    return {
      isSaving: false,
      note: ""
    };
  },
  computed: {
    ...mapGetters("userProfile", ["id"])
  },
  methods: {
    ...mapActions("userProfile", ["addNote"]),
    async handleAddNote() {
      this.$mixpanel.track("Create new note in user profile", {
        viewing_user_id: this.id
      });

      if (!this.note.trim()) return;

      try {
        await this.addNote({ userId: this.id, note: this.note });
        this.note = "";
        this.$emit("note-added");
      } catch (error) {
        this.$logger.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.note-sharing-info {
  background-color: var(--v-vflBlueVeryLight-base);
  border-radius: 12px;
  padding: 0.5rem 1rem;

  i {
    color: inherit;
    margin-right: 0.25rem;
  }

  p {
    line-height: 1;
  }
}

::v-deep {
  fieldset {
    border-radius: 12px;
  }
}
</style>
