<template>
  <div class="d-flex mb-6">
    <div
      class="user-profile-history-icon mr-4 align-self-start d-flex"
      :class="`is-${color}`"
    >
      <v-icon>{{ icon }}</v-icon>
    </div>
    <div class="d-flex flex-column user-profile-history-content">
      <p
        class="mb-0 user-profile-history-event text-body-2 text-body-dark"
        v-html="event.displayText"
      ></p>
      <span class="user-profile-history-date text-caption">{{
        event.formattedDate
      }}</span>
    </div>
  </div>
</template>

<script>
import { HistoryIcons } from "@/components/user-profile/constants.js";

export default {
  name: "UserProfileHistoryEvent",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      const { historyType } = this.event;

      return HistoryIcons[historyType]?.icon || "mdi-account-check";
    },
    color() {
      const { historyType } = this.event;

      return HistoryIcons[historyType]?.color || "blue";
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-history-icon {
  aspect-ratio: 1/1;
  border-radius: 999px;
  flex: 0 0 40px;
  padding: 0.5rem;

  &.is-green {
    background: #d2f2d8;

    i {
      color: #74ab7e;
    }
  }

  &.is-blue {
    background: #d6dfff;

    i {
      color: #7e91d2;
    }
  }
}

.user-profile-history-date {
  color: var(--v-vflGreyLight-base);
}

p {
  line-height: 1.2;
}
</style>
