<template>
  <div style="min-width: 100%">
    <div
      class="mb-8 mt-8"
      :style="{ '--color': color, '--value': painLevel }"
      ref="container"
    >
      <div class="d-flex mb-2" style="gap: 1.25rem">
        <animated-emoji :value="painLevel" />

        <div class="flex-grow-1" style="min-height: 4.5rem">
          <p v-if="showPainLevelDescription" class="mb-0">
            <span class="font-weight-medium">{{ currentPainTitle }}</span>
            <br />
            {{ currentPainDescription }}
          </p>
        </div>
      </div>

      <div v-if="$vuetify.breakpoint.lgAndUp" class="pain-buttons">
        <button
          :class="{ selected: painLevel === index }"
          :style="{ '--color': colorScale[index] }"
          v-for="(n, index) in 11"
          @click="setPainLevel(index)"
          @mouseenter="setHoverLevel(index)"
          @mouseleave="clearHoverLevel"
        >
          {{ index }}
        </button>
      </div>

      <v-slider
        v-else
        v-model="painLevel"
        :color="color"
        :step="1"
        min="0"
        max="10"
        @input="emitToParent($event)"
        tick-size="0"
        :track-color="color"
        class="mb-1"
        hide-details="true"
        :ticks="'always'"
        :tickLabels="tickLabels"
      />
    </div>
    <v-input :rules="rules" />
  </div>
</template>

<script>
import PainLevelTitles from "@/assets/json/DeskAssessment/PainLevelTitles.json";
import AnimatedEmoji from "@/components/form/AnimatedEmoji.vue";
import PainLevelColours from "@/assets/json/common/PainLevelColours.json";

export default {
  name: "PainRatingQuestion",
  components: {
    AnimatedEmoji
  },
  props: {
    name: String,
    question: String,
    value: {
      type: Number
    },
    index: Number,
    valueCanBeZero: Boolean,
    descriptions: Array,
    titles: Array,
    showDescriptions: {
      type: Boolean,
      default: true
    },
    startAtOne: Boolean,
    standalone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      painLevel: this.value,
      hoverLevel: null,
      painTitles: this.titles ?? PainLevelTitles,
      painDescriptions: this.descriptions ?? [
        this.$t("form.painLevel.level0"),
        this.$t("form.painLevel.level1"),
        this.$t("form.painLevel.level2"),
        this.$t("form.painLevel.level3"),
        this.$t("form.painLevel.level4"),
        this.$t("form.painLevel.level5"),
        this.$t("form.painLevel.level6"),
        this.$t("form.painLevel.level7"),
        this.$t("form.painLevel.level8"),
        this.$t("form.painLevel.level9"),
        this.$t("form.painLevel.level10")
      ],
      colorScale: PainLevelColours,
      tickLabels: Array.from({ length: 11 }, (_, i) => i.toString())
    };
  },
  computed: {
    valueCanBeZeroInputRule() {
      return [
        Number.isInteger(this.value) || this.$t("form.painLevel.noneSelected")
      ];
    },
    valueCantBeZeroInputRule() {
      return [this.value > 0 || this.$t("form.painLevel.selectArea")];
    },
    rules() {
      return this.valueCanBeZero
        ? this.valueCanBeZeroInputRule
        : this.valueCantBeZeroInputRule;
    },
    color() {
      return this.colorScale[this.value];
    },
    showPainLevelDescription() {
      return this.hoverLevel !== null || this.painLevel || this.startAtOne;
    },
    currentPainLevel() {
      return this.hoverLevel !== null ? this.hoverLevel : this.painLevel;
    },
    currentPainTitle() {
      return this.$t(this.painTitles[this.currentPainLevel]);
    },
    currentPainDescription() {
      return this.showDescriptions
        ? this.painDescriptions[this.currentPainLevel]
        : "";
    }
  },
  watch: {
    value() {
      this.handleActiveTick();
    }
  },
  mounted() {
    let valueToEmit = this.value;

    let setDefaultValue = this.valueCanBeZero
      ? !Number.isInteger(this.value)
      : !this.value;

    if (setDefaultValue) {
      valueToEmit = this.valueCanBeZero ? undefined : 0;
    }

    this.emitToParent(valueToEmit);
  },
  methods: {
    getPainEmoji(index) {
      return "/images/emojis/painLevel" + index + ".png";
    },
    getEmojiSize(index) {
      return index === this.painLevel ? "40px" : "20px";
    },
    emitToParent(value) {
      this.$emit("input", value);
    },
    setPainLevel(buttonIndex) {
      this.painLevel = buttonIndex;
      this.emitToParent(this.painLevel);
    },
    setHoverLevel(index) {
      this.hoverLevel = index;
    },
    clearHoverLevel() {
      this.hoverLevel = null;
    },
    handleActiveTick() {
      if (this.$refs?.container) {
        const ticks = [
          ...this.$refs.container.querySelectorAll(".v-slider__tick")
        ];
        if (ticks) {
          ticks.forEach(tick => tick.classList.remove("is-active"));

          this.$nextTick(() => {
            const activeTick = ticks[this.value];

            if (activeTick) {
              activeTick.classList.add("is-active");
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.selectorContainer {
  max-width: 350px;
}

.pain-buttons {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  margin-top: 1.25rem;

  button {
    border: 1px solid var(--color);
    border-radius: 4px;
    padding: 1rem 0;
    position: relative;
    transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1),
      transform 0.1s cubic-bezier(0.2, 0, 0, 1),
      color 0.1s cubic-bezier(0.2, 0, 0, 1),
      background-color 0.1s cubic-bezier(0.2, 0, 0, 1);

    &:hover {
      background-color: hsl(0, 0%, 95%);
      background-color: color-mix(in srgb, var(--color), #fff 95%);
    }

    &:active {
      transform: scale(0.975);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px #0f72ea, 0 0 0 4px #0f72ea;
    }

    &.selected {
      background-color: hsl(0, 0%, 95%);
      background-color: color-mix(in srgb, var(--color), #fff 85%);
      font-weight: 600;
      outline: 1px solid var(--color);
      outline-offset: -2px;
    }
  }
}

.v-input__slider {
  cursor: pointer;
}
::v-deep {
  .v-slider {
    cursor: pointer;
  }

  .v-slider__tick {
    background-color: white;
    opacity: 0.8;
    transition: none;

    &.is-active {
      opacity: 1;

      .v-slider__tick-label {
        font-size: 1.15rem;
        font-weight: 600;
        margin-top: 4px;
      }
    }
  }

  .v-slider__ticks-container {
    box-shadow: 0 0 0 1px var(--color);
    border-radius: 10px;
  }

  .v-slider__tick-label {
    margin-top: 6px;
  }
}
</style>
