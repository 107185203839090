<template>
  <div>
    <user-profile-name-and-email />
    <user-profile-msk-overview class="mt-4" v-if="canViewMskInformation" />
  </div>
</template>

<script>
import UserProfileNameAndEmail from "@/components/user-profile/UserProfileNameAndEmail.vue";
import UserProfileMskOverview from "@/components/user-profile/UserProfileMskOverview.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileHeader",
  components: { UserProfileMskOverview, UserProfileNameAndEmail },
  computed: {
    ...mapGetters(["anonymousResults"]),
    canViewMskInformation() {
      return !this.anonymousResults;
    }
  }
};
</script>
