<template>
  <user-profile-block>
    <template #title>Risks</template>
    <template #content>
      <v-skeleton-loader v-if="isLoading" type="text@3" />
      <div v-else-if="hasRisks" class="risks-container">
        <user-profile-office-risks v-if="officeRisks?.length" />
        <user-profile-home-risks v-if="homeRisks?.length" />
      </div>
      <p v-else class="mb-0">
        Risks will be identified after the first assessment
      </p>
    </template>
  </user-profile-block>
</template>

<script>
import UserProfileHomeRisks from "@/components/user-profile/UserProfileHomeRisks.vue";
import UserProfileOfficeRisks from "@/components/user-profile/UserProfileOfficeRisks.vue";
import { mapGetters } from "vuex";
import UserProfileBlock from "@/components/user-profile/UserProfileBlock.vue";

export default {
  name: "UserProfileRisks",
  components: {
    UserProfileBlock,
    UserProfileOfficeRisks,
    UserProfileHomeRisks
  },
  computed: {
    ...mapGetters("userProfile", [
      "hasRisks",
      "officeRisks",
      "homeRisks",
      "loadingStates"
    ]),
    isLoading() {
      return this.loadingStates.officeRisks || this.loadingStates.homeRisks;
    }
  }
};
</script>

<style scoped lang="scss">
.risks-container {
  > div + div {
    margin-top: 1rem;
  }
}
</style>
