<template>
  <div style="width: 100%" class="form-generator">
    <div
      v-for="(question, index) in visibleQuestions"
      :key="question.name"
      :class="getContainerClassList(question, index)"
      class="form-question"
    >
      <h3
        v-if="isGroupStart(index, question)"
        class="text-h6 mt-6 mb-8 pt-6 group-heading"
      >
        {{ getGroupTitle(question.formGroups) }}
        <span class="d-none d-md-block"></span>
      </h3>

      <h4 v-if="question.container?.title" class="align-center">
        <span v-if="question.container?.icon" class="d-flex"
          ><v-icon>{{ question.container.icon }}</v-icon></span
        ><span v-if="question.container?.image"
          ><img
            :src="question.container?.image"
            width="25px"
            height="25px"
            alt=""
        /></span>
        <div class="d-flex flex-column">
          <span
            v-if="question.optional"
            class="mr-2 absolutely-optional-header"
            >{{ $t("assessment.form.overall.absolutelyOptional") }}</span
          >
          {{ $t(question.container.title) }}
        </div>
      </h4>

      <p
        v-if="question.optional"
        class="text-caption font-weight-light grey--text mb-0"
      >
        {{ $t("form.generator.optionalTooltip") }}
      </p>
      <p v-if="showQuestion(question)" class="text-body-1 mb-0">
        {{ questionText(index) }}
      </p>

      <div
        v-if="question.infoText"
        class="text-body-2 mb-0 grey--text font-weight-light info-text"
      >
        {{ $t(question.infoText) }}
      </div>

      <div v-if="question.shareLevel" class="text-body-2 shareLevel-container">
        <div class="shareLevel-icon mr-2">
          <v-icon>mdi-shield-check-outline</v-icon>
        </div>
        <div class="shareLevel-text">{{ $t(question.shareLevel) }}</div>
      </div>

      <component
        class="form-control"
        :key="question.name"
        :is="question.fieldType"
        @input="save(question.fieldType)"
        v-bind="question"
        v-model="results[question.name]"
        :index="index"
        :optional="question.optional"
        :grey="isGrey(index)"
        :valueCanBeZero="question.valueCanBeZero"
        :fullQuestion="question"
      />
    </div>
  </div>
</template>

<script>
import RadioButtonQuestion from "./RadioButtonQuestion.vue";
import CardSelectQuestion from "./CardSelectQuestion.vue";
import RadioCardQuestion from "@/components/form/RadioCardQuestion.vue";
import CheckBoxCardsQuestion from "@/components/form/CheckBoxCardsQuestion.vue";
import VerticalRadioButtonQuestion from "./VerticalRadioButtonQuestion.vue";
import TextQuestion from "./TextQuestion.vue";
import SliderQuestion from "./SliderQuestion.vue";
import CheckBoxListQuestion from "./CheckBoxListQuestion.vue";
import NumberInputQuestion from "./NumberInputQuestion.vue";
import painAreaDescriptions from "@/components/painareas/PainAreasMale.json";
import criteriaChecker from "@/components/common/criteria-checker.js";
import SelectBodyAreaQuestion from "./SelectBodyAreaQuestion.vue";
import ButtonsQuestion from "@/components/form/ButtonsQuestion.vue";
import PainRatingQuestion from "@/components/form/PainRatingQuestion.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormGenerator",
  components: {
    RadioButtonQuestion,
    TextQuestion,
    SliderQuestion,
    CheckBoxListQuestion,
    VerticalRadioButtonQuestion,
    CardSelectQuestion,
    RadioCardQuestion,
    NumberInputQuestion,
    SelectBodyAreaQuestion,
    CheckBoxCardsQuestion,
    ButtonsQuestion,
    PainRatingQuestion
  },
  props: {
    questions: Array,
    value: Object,
    fullResults: Object,
    popupMessage: String,
    finalQuestionEmphasis: {
      type: Boolean,
      default: false
    },
    answersToKeep: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    },
    enforceNumbering: {
      type: Boolean,
      default: false
    },
    group: String
  },
  data() {
    return {
      results: this.value ?? {},
      formQuestions: this.questions
    };
  },

  watch: {
    value: {
      handler() {
        this.results = this.value;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["teamName"]),
    visibleQuestions() {
      return this.formQuestions.filter(x => x.show || x.show === undefined);
    },
    customContainerBoundaryIndices() {
      const boundaries = {};

      this.visibleQuestions.forEach((q, index) => {
        if (q.container && q.container.key) {
          if (!boundaries[q.container.key]) {
            boundaries[q.container.key] = { first: index, last: index };
          } else {
            boundaries[q.container.key].last = index;
          }
        }
      });

      return boundaries;
    }
  },
  created() {
    this.updateVisilibities();
  },
  methods: {
    save(questionType) {
      this.updateVisilibities();
      this.$emit("questionType", questionType);
      this.$emit("input", this.results);
    },
    updateVisilibities() {
      let localQuestions = [...this.formQuestions];
      this.updateQuestionVisibility(localQuestions);
      this.updateOptionsVisibility(localQuestions);

      this.formQuestions = [...localQuestions];
    },
    updateQuestionVisibility(questions) {
      questions
        .filter(question => question.showOn !== undefined)
        .forEach(question => {
          let results = this.getResultsForCondition(question.showOn);
          if (question.addResults) {
            results = {
              ...this.results,
              ...this.fullResults[question.addResults]
            };
          }
          question.show = criteriaChecker.areCriteriaMet(
            results,
            question.showOn
          );

          if (!question.show && !this.answersToKeep.includes(question.name)) {
            this.$delete(this.results, question.name);
          }
        });
    },
    updateOptionsVisibility(questions) {
      questions.forEach(question => {
        if (!question.options) {
          return;
        }

        question.options.forEach(answer => {
          let results = this.getResultsForCondition(answer.showOn);
          var showAnswer = answer.showOn
            ? criteriaChecker.areCriteriaMet(results, answer.showOn)
            : true;
          answer.showAnswer = showAnswer;
        });
      });
    },
    getResultsForCondition(condition) {
      if (!condition) return this.results;
      // Check if results are for a different theme
      const theme = Object.values(condition).find(v => v.theme)?.theme;
      return theme ? this.fullResults[theme] : this.results;
    },
    getFormQuestion(index) {
      const question = this.visibleQuestions[index];
      if (question.injectToQuestion) {
        const injectionText = question.injectToQuestion;
        switch (injectionText) {
          case "painArea":
            const groupName = painAreaDescriptions[this.group]?.name;
            const painAreaName = this.$t(groupName)?.toLowerCase();
            return this.$t(question.question, { painArea: painAreaName });
          case "painAreas":
            const painAreasText = this.getPainAreas();
            return this.$t(question.question, { painAreas: painAreasText });
          default:
            break;
        }
      }
      return this.$t(question.question);
    },
    getPainAreas() {
      let painAreas = Object.keys(this.fullResults.pain.areas);
      let painAreasAsList = "";
      painAreas.forEach((area, index) => {
        painAreasAsList += this.$t(
          painAreaDescriptions[area].name
        ).toLowerCase();
        if (index != painAreas.length - 1) {
          painAreasAsList +=
            index === painAreas.length - 2
              ? " " + this.$t("form.generator.connector") + " "
              : ", ";
        }
      });
      return painAreasAsList;
    },
    isGrey(index) {
      return (
        this.finalQuestionEmphasis && index !== this.visibleQuestions.length - 1
      );
    },
    isGroupStart(index, question) {
      if (
        question.formGroups &&
        question.formGroups !== this.visibleQuestions[index - 1]?.formGroups
      ) {
        return true;
      }
      return false;
    },
    getGroupTitle(groupName) {
      const group = this.groups.find(group => group.name === groupName);
      return group ? this.$t(group.title) : "";
    },
    questionText(index) {
      const formQuestion = this.getFormQuestion(index);
      if (this.visibleQuestions.length === 1 && !this.enforceNumbering) {
        return this.$t(formQuestion);
      }
      return index + 1 + ". " + this.$t(formQuestion);
    },
    showQuestion(question) {
      return (
        question.showQuestion === undefined || question.showQuestion === true
      );
    },
    getContainerClassList(question, index) {
      let classList = "";

      if (this.isGrey(index)) {
        classList += "grey--text text--lighten-1 ";
      }

      if (question.container) {
        classList += `is-container `;

        const containerBoundaryIndices =
          this.customContainerBoundaryIndices[question.container.key];

        if (containerBoundaryIndices) {
          if (index === containerBoundaryIndices.first) {
            classList += "is-container-first ";
          }
          if (index === containerBoundaryIndices.last) {
            classList += "is-container-last ";
          }
        }
      }

      return classList;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-question:first-child {
  .group-heading {
    @media screen and (min-width: 960px) {
      margin-top: 0 !important;
    }
  }
}

.form-question {
  &.is-container {
    --border-color: var(--v-vflGreyVeryLight-base);
    --border-radius: 20px;

    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);

    + .is-container:not(.is-container-first) {
      padding-top: 1rem;
      margin-top: 0 !important;
    }

    &-first {
      border-top: 1px solid var(--border-color);
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    &-last {
      border-bottom: 1px solid var(--border-color);
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    > * {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    h4 {
      background: linear-gradient(
          117deg,
          rgba(255, 255, 255, 0.32) -18.08%,
          rgba(100, 166, 255, 0.32) 49.77%,
          rgba(134, 111, 255, 0.32) 95.41%
        ),
        #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      line-height: 1.2;
      margin-bottom: 1.5rem;
      padding-bottom: 1.25rem;
      padding-top: 1.25rem;

      > span {
        margin-right: 0.75rem;

        i {
          color: black;
        }
      }
    }
  }

  .form-control {
    margin-top: 1rem;
  }

  .info-text {
    margin-top: 0.5rem;
  }

  + .form-question {
    margin-top: 1.5rem;
  }
}

.shareLevel-container {
  display: inline-flex;
  align-items: center;
  background: var(--v-vflBlueVeryLight-base);
  color: var(--v-vflGreyLight-base);
  border-radius: 0.5rem;
  margin: 0.5rem 1rem;
  padding: 0.75rem 0;
}

.shareLevel-icon {
  display: flex;
  align-items: center;
}

.shareLevel-text {
  flex: 1;
}

.legacy-pain-layout {
  .is-container-first {
    margin-top: 1rem;
  }

  .is-container-last {
    margin-bottom: 2rem;
  }
}

.group-heading {
  align-items: center;
  border-top: 1px solid hsla(0, 0%, 0%, 0.2);
  display: flex;
  gap: 1rem;

  @media screen and (min-width: 960px) {
    border-top: none;
  }

  span {
    background-color: hsla(0, 0%, 0%, 0.2);
    flex: 1;
    height: 1px;
    position: relative;
    top: 1px;
  }
}

.absolutely-optional-header {
  color: var(--v-vflViolet-base);
}
</style>
