import sentry from "@/sentry.js";
import { AssessmentHistoryItem } from "@/components/user-profile/history/item-models/assessmentHistoryItem.js";
import { ASSESSMENT_HISTORY_STATES } from "@/components/user-profile/history/constants.js";
import { AssessmentStateCodes } from "@/components/common/assessment-history/assessmentHistoryConstants.js";

// we want to show an entry for when the assessment was created and another entry for it's latest state (in prog, completed etc)
function processAssessmentHistory(history) {
  try {
    const assessmentHistory = [];
    history.forEach(assessment => {
      assessmentHistory.push(
        new AssessmentHistoryItem(
          assessment.id,
          assessment.createdAt,
          ASSESSMENT_HISTORY_STATES.CREATED,
          null,
          assessment.location,
          assessment.assessmentType
        )
      );

      if (assessment.lastUpdatedAt) {
        assessmentHistory.push(
          new AssessmentHistoryItem(
            assessment.id,
            assessment.lastUpdatedAt,
            mapAssessmentStateToHistoryState(assessment.assessmentState),
            assessment.score,
            assessment.location,
            assessment.assessmentType
          )
        );
      }
    });
    return assessmentHistory;
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

function mapAssessmentStateToHistoryState(stateCode) {
  switch (stateCode) {
    case AssessmentStateCodes.Pending:
      return ASSESSMENT_HISTORY_STATES.CREATED;
    case AssessmentStateCodes.Opened:
      return ASSESSMENT_HISTORY_STATES.OPENED;
    case AssessmentStateCodes.InProgress:
      return ASSESSMENT_HISTORY_STATES.IN_PROGRESS;
    case AssessmentStateCodes.Completed:
      return ASSESSMENT_HISTORY_STATES.COMPLETED;
    case AssessmentStateCodes.FailedToSendInvitation:
    case AssessmentStateCodes.Failed:
      return ASSESSMENT_HISTORY_STATES.FAILED;
    default:
      sentry.captureMessage(`Unknown assessment state code: ${stateCode}`);
      return ASSESSMENT_HISTORY_STATES.FAILED;
  }
}

export { processAssessmentHistory };
