import {
  getAssessmentAnswers,
  getCurrentUserMostRecentAssessmentAnswers
} from "@/customApi";
import SchemaService from "@/services/question-schema/schema-service.js";
import { assessmentTypes } from "@/constants/constants.js";
import { getAssessmentResult } from "@/services/modules/api-queries";
import sentry from "@/sentry";
import { getSchema } from "@/services/assessment/get-schema-service";
const driverAssessmentSchema = getSchema(assessmentTypes.driverAssessment);
const physicalLabourAssessmentSchema = getSchema(
  assessmentTypes.physicalLabourAssessment
);

export async function GetAssessmentResultsForType(
  assessmentId,
  assessmentType
) {
  if (
    assessmentType === assessmentTypes.driverAssessment ||
    assessmentType === assessmentTypes.physicalLabourAssessment
  ) {
    return await GetAssessmentResults(
      assessmentId,
      assessmentType,
      assessmentType === assessmentTypes.driverAssessment
        ? driverAssessmentSchema
        : physicalLabourAssessmentSchema
    );
  }

  return await getAssessmentResult(assessmentId, assessmentType);
}

export async function GetAssessmentResults(
  assessmentId,
  assessmentType,
  schema
) {
  const results = createBaseResultsObjectFromSchema(schema);
  const answers = await getAssessmentAnswers(assessmentId, assessmentType);
  const allQuestions = schema.flatMap(p => SchemaService.getPageQuestions(p));
  answers.forEach(a => addToResults(a, allQuestions, results));

  return results;
}

export async function getLatestAssessmentResults(assessmentType) {
  if (
    assessmentType !== assessmentTypes.driverAssessment &&
    assessmentType !== assessmentTypes.physicalLabourAssessment
  ) {
    return null;
  }
  const schema =
    assessmentType === assessmentTypes.driverAssessment
      ? driverAssessmentSchema
      : physicalLabourAssessmentSchema;

  const assessment = await getCurrentUserMostRecentAssessmentAnswers(
    assessmentType
  );
  if (!assessment) {
    return null;
  }
  const allQuestions = schema.flatMap(p => SchemaService.getPageQuestions(p));
  const results = createBaseResultsObjectFromSchema(schema);
  assessment.answers.forEach(a => addToResults(a, allQuestions, results));

  return {
    id: assessment.id,
    type: assessment.type,
    answers: results
  };
}

function addToResults(answer, questions, results) {
  const { question, group } = answer;
  const schemaQuestion = questions.find(
    q => q.name === question && q.group === group
  );
  if (!schemaQuestion) {
    sentry.captureMessage(`Cannot find question ${answer.question}`);
    return;
  }

  if (schemaQuestion.theme === "pain") {
    addPainAnswer(schemaQuestion, answer, results);
    return;
  }
  addAnswer(schemaQuestion, results[schemaQuestion.theme], answer.answer);
}

function addPainAnswer(question, answer, results) {
  const { group } = answer;
  const root = results.pain.areas;
  root[group] = root[group] || {};
  addAnswer(question, root[group], answer.answer);
}

function addAnswer(question, root, answer) {
  const { name, fieldType } = question;
  switch (fieldType) {
    case "CheckBoxListQuestion":
    case "SelectBodyAreaQuestion":
    case "CheckBoxCardsQuestion":
      root[name] = root[name] || [];
      root[name].push(answer);
      break;
    case "BMIQuestion":
      // Question removed, do not add to results
      break;
    default:
      root[name] = answer;
      break;
  }
}

function createBaseResultsObjectFromSchema(schema) {
  const results = {};
  schema.forEach(x => {
    results[x.theme] = x.theme === "pain" ? { areas: {} } : {};
  });
  return results;
}
