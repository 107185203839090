export const ScoreBoundaries = {
  HIGH: 34,
  MEDIUM: 50,
  LOW: 75,
  NEGLIGIBLE: 90
};

export const ScoreBoundaryNames = {
  VERY_HIGH: "veryHigh",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  NEGLIGIBLE: "negligible"
};

export const getScoreColor = score => {
  if (score >= ScoreBoundaries.NEGLIGIBLE) {
    return { thumb: "vitrueDarkGreen", text: "vitrueDarkGreen" };
  } else if (score >= ScoreBoundaries.LOW) {
    return { thumb: "vflBrightGreen", text: "vitrueDarkGreen" };
  } else if (score >= ScoreBoundaries.MEDIUM) {
    return { thumb: "vitrueOrange", text: "vidaPainOrange" };
  } else if (score >= ScoreBoundaries.HIGH) {
    return { thumb: "sliderRed", text: "sliderRed" };
  } else {
    return { thumb: "vflDarkRed", text: "vflDarkRed" };
  }
};

export const getScoreRatingName = score => {
  if (score >= ScoreBoundaries.NEGLIGIBLE) {
    return ScoreBoundaryNames.NEGLIGIBLE;
  } else if (score >= ScoreBoundaries.LOW) {
    return ScoreBoundaryNames.LOW;
  } else if (score >= ScoreBoundaries.MEDIUM) {
    return ScoreBoundaryNames.MEDIUM;
  } else if (score >= ScoreBoundaries.HIGH) {
    return ScoreBoundaryNames.HIGH;
  } else {
    return ScoreBoundaryNames.VERY_HIGH;
  }
};

export const SCORE_SLIDER_SECTIONS = [
  { color: "vflDarkRed", percent: 0 },
  {
    color: "sliderRed",
    percent: ScoreBoundaries.HIGH
  },
  {
    color: "vitrueOrange",
    percent: ScoreBoundaries.MEDIUM
  },
  {
    color: "vflBrightGreen",
    percent: ScoreBoundaries.LOW
  },
  {
    color: "vitrueDarkGreen",
    percent: ScoreBoundaries.NEGLIGIBLE
  }
];
