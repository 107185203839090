import TeamService from "@/services/teamService.js";
import {
  checkHasSubscriptionAndSetCustomerId,
  recordEndUserLogin,
  recordTeamMemberLogin,
  getActiveTeamForEndUserEmail
} from "@/customApi";
import { getAssessmentResult } from "@/services/modules/api-queries";
import { handleUrlRedirect } from "@/services/authorization/url-redirect/url-redirect-service.js";
import { routes, assessmentRoutes } from "@/router/route-constants.js";

async function getRedirectDestination(teamMemberInfo, store) {
  let assessmentRedirect = store.getters.redirectReportAssessment;
  if (assessmentRedirect) {
    return await GetAssessmentRedirect(assessmentRedirect, store);
  }
  if (store.getters.isSimpleUser) {
    return await GetEndUserRedirect(teamMemberInfo, store);
  }
  return await GetTeamMemberRedirect(teamMemberInfo, store);
}

async function GetAssessmentRedirect(redirect, store) {
  let type = redirect.assessmentType;
  let id = redirect.id;
  let results = await getAssessmentResult(id, type);
  let name = assessmentRoutes[type];
  let route = {
    name: name,
    params: {
      assessmentId: id,
      loadedResults: results
    }
  };
  store.commit("setRedirectAssessment", null);
  return route;
}

function GetWellnessRedirect(store) {
  if (store.getters.redirectToWellness) {
    store.commit("setWellnessRedirect", false);
  }

  return routes.WELLNESS;
}

async function GetEndUserRedirect(teamMemberInfo, store) {
  if (teamMemberInfo.endUserSignedUpToTeam) {
    return { name: GetWellnessRedirect(store) };
  }
  let availableTeams = await getActiveTeamForEndUserEmail();
  if (availableTeams?.teams.length > 0) {
    return {
      name: routes.JOIN_TEAM,
      params: { endUserTeams: availableTeams.teams }
    };
  } else {
    return { name: routes.CREATE_TEAM };
  }
}

async function GetTeamMemberRedirect(teamMemberInfo, store) {
  if (teamMemberInfo.teamsToJoinRequested.length > 0) {
    return {
      name: routes.CHOOSE_TEAM,
      params: { teams: teamMemberInfo.teamsToJoinRequested }
    };
  }

  let wellnessRedirect = store.getters.redirectToWellness;
  let assessmentRedirect = store.getters.redirectReportAssessment;
  var name =
    assessmentRedirect || wellnessRedirect
      ? GetWellnessRedirect(store)
      : routes.DASHBOARD;
  return { name };
}

function recordLogins(destination, store) {
  let notJoinOrCreateTeam =
    destination?.name !== routes.JOIN_TEAM &&
    destination?.name !== routes.CREATE_TEAM;
  if (notJoinOrCreateTeam) {
    if (store.getters.isSimpleUser) {
      recordEndUserLogin();
    } else {
      recordTeamMemberLogin();
    }
  }
}

async function setTeamMemberData(teamMemberInfo, store) {
  if (store.getters.isSimpleUser) {
    return;
  }
  store.commit("setTeamMemberId", teamMemberInfo.teamMemberId);
  if (teamMemberInfo.teamsToJoinRequested.length === 0) {
    await checkHasSubscriptionAndSetCustomerId();
  }
}

export async function completeUserSignInWithoutRedirect(store) {
  recordLogins(null, store);
}

export async function completeUserSignIn(store, router) {
  let teamMemberInfo = await TeamService.getTeamMemberInfo();
  if (teamMemberInfo?.endUserSignedUpToTeam) {
    await TeamService.updateEndUserInfo(); // only call if exists as part of team
  }
  await setTeamMemberData(teamMemberInfo, store);

  let destination = null;
  // if redirect page exist then get destination from there, if it fails or doesn't exist
  // go through normal getRedirectDestination method
  if (store.getters.redirectPage) {
    destination = await handleUrlRedirect();
  }
  if (!destination) {
    destination = await getRedirectDestination(teamMemberInfo, store);
  }
  recordLogins(destination, store);
  if (!destination) {
    throw Error("No router destination found");
  }
  router.push(destination).catch(() => {});
}
