﻿<template>
  <v-dialog v-model="showDialog" max-width="500px" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="justify-center">
        {{
          $tc(
            "dashboardHistoryComponents.userHistoryTable.incompleteAssessmentReminderDialog.title",
            users.length
          )
        }}
      </v-card-title>
      <v-card-text class="justify-center">
        <p>
          {{
            $t(
              "dashboardHistoryComponents.userHistoryTable.incompleteAssessmentReminderDialog.description"
            )
          }}
        </p>
      </v-card-text>
      <v-card-text
        style="max-height: 500px; overflow-y: auto"
        class="justify-center"
      >
        <v-list dense>
          <v-list-item v-for="(user, index) in users" :key="index">
            <v-list-item-content>
              {{ user.email }}{{ user.name ? ` - ${user.name}` : "" }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="!success"
          color="primary"
          @click="sendEmails"
          :loading="loading"
          class="py-4 my-4"
        >
          {{
            $t(
              "dashboardHistoryComponents.userHistoryTable.incompleteAssessmentReminderDialog.remind"
            )
          }}
        </v-btn>
        <vfl-alert
          v-else
          :message="
            $tc(
              'dashboardHistoryComponents.userHistoryTable.incompleteAssessmentReminderDialog.success',
              users.length
            )
          "
          type="success"
          isLight
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { sendAssessmentReminders } from "@/customApi";

export default {
  name: "IncompleteAssessmentReminderDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      localShowDialog: this.showDialog,
      loading: false,
      success: false
    };
  },
  watch: {
    showDialog(newVal) {
      this.localShowDialog = newVal;
    },
    localShowDialog(newVal) {
      if (newVal !== this.showDialog) {
        this.$emit("update:showDialog", newVal);
      }
    }
  },
  methods: {
    async sendEmails() {
      this.loading = true;
      try {
        await sendAssessmentReminders(this.users.map(user => user.id));
        this.success = true;
        this.loading = false;
        this.$mixpanel.track("Assessment reminders sent via remind button", {
          userCount: this.users.length
        });
        setTimeout(() => {
          this.localShowDialog = false;
          this.success = false;
        }, 3000);
      } catch (e) {
        this.success = false;
        this.loading = false;
      }
    },
    closeDialog() {
      this.localShowDialog = false;
    }
  }
};
</script>
