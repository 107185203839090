<template>
  <div style="position: relative">
    <v-menu
      bottom
      left
      offset-y
      v-model="menuOpen"
      min-width="350"
      max-width="450"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          id="notification"
          :value="nonSelectedNotificationsLength != 0"
          color="warning"
          overlap
          offset-x="15"
          offset-y="20"
          style="cursor: pointer"
          ><v-btn
            color="vflPrimary"
            icon
            v-bind="attrs"
            v-on="on"
            @click="toggleNotificationMenu"
            ><v-icon id="bellIcon">mdi-bell-outline</v-icon></v-btn
          >
          <span slot="badge" @click="toggleNotificationMenu">{{
            nonSelectedNotificationsLength
          }}</span>
        </v-badge>
      </template>

      <div
        v-if="loading"
        class="d-flex justify-center align-center loading-container"
      >
        <v-progress-circular
          indeterminate
          :size="64"
          :width="6"
          color="primary"
        />
      </div>
      <div v-else>
        <v-list
          v-if="hasNotifications"
          class="pa-0"
          style="
            max-height: 200px;
            overflow-y: auto;
            border-bottom: 1px solid #a9a8a8;
          "
        >
          <v-list-item
            v-for="(notificationGroup, index) in notifications"
            :key="index"
            link
            dense
            class="mx-0 px-2"
            @click="
              notificationBehaviour[notificationGroup.type].onClick(
                notificationGroup
              )
            "
            @click.stop
          >
            <v-badge
              :value="!notificationGroup.selected"
              color="vitrueRed"
              left
              overlap
              dot
              offset-y="32px"
            >
              <v-list-item-avatar class="mr-2">
                <v-icon
                  :color="notificationGroup.selected ? 'disabled' : 'primary'"
                  >{{ notificationBehaviour[notificationGroup.type].icon }}
                </v-icon>
              </v-list-item-avatar></v-badge
            >
            <v-list-item-title
              class="text-subtitle-2 mx-2 item-title-limit"
              :class="notificationGroup.selected ? 'disabled--text' : ''"
            >
              {{
                notificationBehaviour[notificationGroup.type].text(
                  notificationGroup.name,
                  notificationGroup.notifications.length
                )
              }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item>{{
            $t("notificationsBadge.noNotifications")
          }}</v-list-item>
        </v-list>

        <v-row v-if="hasNotifications" no-gutters>
          <v-col cols="6"
            ><v-btn
              v-if="hasNotifications"
              @click="selectAllNotifications"
              block
              small
              tile
              color="white"
              elevation="0"
              >{{ $t("notificationsBadge.completeAll") }}</v-btn
            ></v-col
          >
          <v-col cols="6"
            ><v-btn
              v-if="hasNotifications"
              @click="archiveAllNotifications"
              block
              small
              tile
              color="white"
              elevation="0"
              >{{ $t("notificationsBadge.archiveAll") }}</v-btn
            ></v-col
          ></v-row
        >
      </div>
    </v-menu>
  </div>
</template>

<script>
import {
  getUserNotifications,
  markNotificationsAsSeen,
  markNotificationsAsSelected,
  markNotificationsAsArchived
} from "@/customApi.js";
import {
  NotificationBehaviour,
  GroupNotifications,
  ExtractNotificationEntities
} from "@/services/notifications/notification-service";

export default {
  name: "notifications-badge",
  data() {
    return {
      loading: false,
      menuOpen: false,
      notifications: [],
      notificationBehaviour: NotificationBehaviour
    };
  },
  async mounted() {
    var vm = this;
    getUserNotifications().then(result => {
      vm.notifications = GroupNotifications(result);
      if (vm.notifications && vm.nonSelectedNotificationsLength.length > 0) {
        vm.animateBell();
      }
    });
  },
  computed: {
    hasNotifications() {
      return this.notifications && this.notifications.length > 0;
    },
    nonSelectedNotificationsLength() {
      var nonSelectedNotifications = this.notifications.filter(obj => {
        return !obj.selected;
      });

      return nonSelectedNotifications.length;
    }
  },
  watch: {
    nonSelectedNotificationsLength(newVal, oldVal) {
      if (newVal != oldVal) {
        this.animateBell();
      }
    }
  },
  methods: {
    async toggleNotificationMenu() {
      this.loading = true;
      try {
        let newNotifications = await getUserNotifications();
        this.notifications = GroupNotifications(newNotifications);
        if (this.notifications.length > 0 && this.menuOpen) {
          await this.markAllNotificationsAsSeen();
        }
      } finally {
        this.loading = false;
      }
    },
    animateBell() {
      let bellElement = document.getElementById("bellIcon");
      bellElement.classList.add("alarm-tilt-shake");
      setTimeout(function () {
        bellElement.classList.remove("alarm-tilt-shake");
      }, 1000);
    },
    async archiveAllNotifications() {
      await markNotificationsAsArchived(
        ExtractNotificationEntities(this.notifications)
      );
      this.$mixpanel.track(`All notifications marked as archived`);
    },
    async selectAllNotifications() {
      await markNotificationsAsSelected(
        ExtractNotificationEntities(this.notifications)
      );
      this.notifications.forEach(x => (x.selected = true));
      this.$mixpanel.track(`All notifications marked as selected`);
    },
    async markAllNotificationsAsSeen() {
      await markNotificationsAsSeen(
        ExtractNotificationEntities(this.notifications)
      );
      this.$mixpanel.track(`All notifications marked as seen`);
    }
  }
};
</script>

<style>
.alarm-tilt-shake {
  animation: tilt-shaking 0.5s ease-in-out;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.loading-container {
  height: 200px;
  background: white;
}

.item-title-limit {
  max-width: 400px;
  white-space: normal;
  word-wrap: break-word;
}
</style>
