import store from "@/store.js";

export const aiFeaturesDisabledMessage = (isHtml = false) => {
  const Messages = {
    Trial: {
      TEXT: "Reach out to support@vitruehealth.com to enable this feature",
      HTML: "Reach out to <a href='mailto:support@vitruehealth.com'>support@vitruehealth.com</a> to enable this feature"
    }
  };

  if (store.getters?.isTrialUser || store.getters?.subscriptionExpired) {
    return isHtml ? Messages.Trial.HTML : Messages.Trial.TEXT;
  }

  // Fallback
  return Messages.Trial.TEXT;
};
