import Schema from "@/assets/json/pre-existing-conditions.json";
import PainService from "@/services/assessment/pain/pain-assessment-service.js";
import answers from "./answers";

const HEALTH_CONDITIONS = "healthConditions";
const NON_PAIN_CONDITION = "nonPainCondition";
const HAS_MOBILITY_IMPAIRMENT =
  answers.setup.nonPainCondition.mobilityImpairment;
const HAS_VISION_IMPAIRMENT = answers.setup.nonPainCondition.visionImpairment;
const HAS_HEARING_LOSS = answers.setup.nonPainCondition.hearingLoss;
const HAS_DYSLEXIA = answers.setup.nonPainCondition.dyslexia;
const HAS_CHRONIC_FATIGUE_SYNDROME =
  answers.setup.nonPainCondition.chronicFatigueSyndrome;
const HAS_FIBROMYALGIA = answers.setup.nonPainCondition.fibromyalgia;
const HAS_POSTURAL_TACHYCARDIA_SYNDROME =
  answers.setup.nonPainCondition.posturalTachycardiaSyndrome;
const UNDISCLOSED_CONDITION = "undisclosed";

export function getConditionsByCategory(results) {
  const hasPainCondition = hasAnyPainCondition(results);
  const mskConditions = getMskConditions(results, hasPainCondition);
  const nonPainConditions = getNonPainConditions(results);

  const hasCondition = (conditions, conditionValue) => {
    return conditions.some(condition => condition.value === conditionValue);
  };

  const conditionsObject = {};

  if (mskConditions.length > 0) {
    conditionsObject.msk = mskConditions;
  }

  const conditionMappings = {
    mobility: HAS_MOBILITY_IMPAIRMENT,
    vision: HAS_VISION_IMPAIRMENT,
    hearing: HAS_HEARING_LOSS,
    dyslexia: HAS_DYSLEXIA,
    chronicFatigueSyndrome: HAS_CHRONIC_FATIGUE_SYNDROME,
    fibromyalgia: HAS_FIBROMYALGIA,
    posturalTachycardiaSyndrome: HAS_POSTURAL_TACHYCARDIA_SYNDROME
  };

  Object.keys(conditionMappings).forEach(key => {
    if (hasCondition(nonPainConditions, conditionMappings[key])) {
      conditionsObject[key] = true;
    }
  });

  return Object.keys(conditionsObject).length > 0 ? conditionsObject : null;
}

export function getPainConditions(results) {
  if (!results?.pain?.areas) return [];

  let allConditions = [];
  const areas = Object.keys(results.pain.areas);

  areas.forEach(a => {
    const conditions = getPainAreaConditions(results, a);
    allConditions = [...allConditions, conditions];
  });

  return allConditions.flat();
}

export function getNonPainConditions(results) {
  const answers = results?.setup?.nonPainCondition;
  if (!answers) return [];
  const question = Schema.find(q => q.name == NON_PAIN_CONDITION);
  return getConditions(question, answers);
}

export function hasAnyHealthCondition(results) {
  const hasPainCondition = hasAnyPainCondition(results);
  const nonPainConditions = getNonPainConditions(results);

  return hasPainCondition || nonPainConditions.length > 0;
}

export function hasNonPainCondition(results, condition) {
  const nonPainConditions = getNonPainConditions(results);
  return nonPainConditions.some(c => c.value === condition);
}

function hasAnyPainCondition(results) {
  if (!results?.pain?.areas) return false;

  return Object.entries(results.pain.areas).some(
    ([key, area]) =>
      answers.pain.areas[key] &&
      area?.healthCondition === answers.pain.areas[key].healthCondition.yes
  );
}

function getPainAreaConditions(results, painArea) {
  const answers = results.pain.areas[painArea].healthConditions;
  if (!answers) return [];

  const question = Schema.find(
    q => q.name == HEALTH_CONDITIONS && q.merge.painAreas.includes(painArea)
  );
  return getConditions(question, answers, painArea);
}

export function getMskConditions(results, hasPainCondition) {
  if (hasPainCondition) {
    const conditions = getPainConditions(results);

    if (conditions.length > 0) {
      return conditions;
    } else {
      return [{ label: UNDISCLOSED_CONDITION }];
    }
  }

  return [];
}

function getConditions(question, answers, painArea) {
  return question.options
    .filter(o => answers.includes(o.value))
    .map(o =>
      painArea ? { ...o, painGroup: PainService.getPainAreaGroup(painArea) } : o
    );
}
