export { standardTheme };

const standardTheme = {
  primary: "#63a8ff",
  accent: "#DA68E4",
  secondary: "#3fb288",
  tertiary: "#6C63FF",
  tertiaryLight: "#E1DFFF",
  success: "#4CAF50",
  successDark: "#0D865A",
  info: "#2196F3",
  warning: "#ff0c3e",
  disabled: "#A9A8A8",
  error: "#A10000",
  brightError: "#FF0000",
  selectedPrimary: "#DFEDFF",
  poorOutcome: "#F3883B",
  fairOutcome: "#FBC63E",
  wayfindingPurple: "#693DEC",
  darkBackground: "#13171a",
  labelColors: [
    "#2117BE",
    "#FD80F8",
    "#3FB288",
    "#B66C00",
    "#AD00FF",
    "#FFAE36",
    "#CD0707",
    "#C5BD01",
    "#00452C",
    "#24CAFF"
  ],
  vitruePurple: "#AD00FF",
  vitruePalePurple: "#ECEAFF",
  vitruePalePink: "#F8EAFB",
  vitrueLightGreen: "#DCFBF0",
  vitruePaleGreen: "#95d3a5",
  vitrueYellow: "#fecb7d",
  vitrueOrange: "#Fbac85",
  vitrueRed: "#f8696b",
  vitrueDarkGreen: "#3FB288",
  vitrueBrightGreen: "#90C819",
  vitruePaleBlue: "#1fcbe7",
  vitruePaleGrey: "#F2F2F2",
  vitrueGrey: "#6E6E6E",
  vitrueDarkGrey: "#505050",
  vitrueMidGrey: "#E8E8E8",
  vitrueLightOrange: "#F2EADE",
  sliderRed: "#ed474a",
  sliderOrange: "#eeaf78",
  sliderLightGreen: "#83c403",
  sliderGreen: "#3fb288",
  vidaPainGreen: "#756E0A",
  vidaPainDarkGreen: "#09593C",
  vidaPainOrange: "#BB5A00",
  vidaPainRed: "#CA1010",
  vflPrimary: "#0f72ea",
  vflSuccess: "#0D865A",
  vflViolet: "#585FA1",
  vflGreyLight: "#626E7C",
  vflDarkRed: "#621708",
  vflBrightGreen: "#C1D37F",
  vflGreyVeryLight: "#DDE0E4",
  vflGreyDark: "#343D48",
  vflBlueVeryLight: "#eaf1f9",
  vflText: "#343D48",
  vflTextDark: "#050F19",
  vflPurple: "#A320DA",
  vflPurpleDark: "#0B26B4",
  vflYellowLight: "#FFF8BB",
  vflRedLight: "#FFEDED"
};
