import { format, parseISO, isThisYear } from "date-fns";
import sentry from "@/sentry";
import i18n from "@/plugins/i18n.js";

const mapLocale = locale => {
  const localeMap = {
    "en-gb": "en-GB",
    "en-us": "en-US",
    "es-mx": "es",
    latam: "es"
  };

  return localeMap[locale] || locale;
};

export async function formatWithLocale(date, formatStr) {
  const currentLocale = mapLocale(i18n.locale);

  try {
    const dateFnsLocaleModule = await getDateFnsLocale(currentLocale);
    return format(date, formatStr, { locale: dateFnsLocaleModule.default });
  } catch (error) {
    sentry.captureException(error);
  }
}

export function formatDateWithOrdinal(dateString) {
  const date = parseISO(dateString);

  if (isThisYear(date)) {
    return format(date, "do MMM");
  } else {
    return format(date, "do MMM yy");
  }
}

export function formatShortDate(dateString) {
  const date = new Date(dateString);

  if (isThisYear(date)) {
    return format(date, "do MMM");
  } else {
    return format(date, "do MMM yy");
  }
}

async function getDateFnsLocale(locale) {
  switch (locale) {
    case "en-GB":
      return import("date-fns/locale/en-GB/index.js");
    case "en-US":
      return import("date-fns/locale/en-US/index.js");
    case "es":
      return import("date-fns/locale/es/index.js");
    default:
      return import("date-fns/locale/en-GB/index.js");
  }
}
