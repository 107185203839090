<template>
  <v-app-bar
    app
    color="white"
    clipped-left
    :hide-on-scroll="$vuetify.breakpoint.smAndDown"
    :value="true"
  >
    <v-app-bar-nav-icon
      v-show="showSidebarBurgerMenu"
      @click.stop="$emit('input', !value)"
    />
    <v-img
      class="mr-4"
      src="/images/VitrueVidaLogoWithText.png"
      max-height="70"
      max-width="200"
      contain
    ></v-img>
    <v-spacer></v-spacer>
    <FeatureFlagSelector />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import FeatureFlagSelector from "@/components/internal/FeatureFlagSelector.vue";

export default {
  name: "top-bar",
  components: {
    FeatureFlagSelector
  },
  props: {
    value: Boolean,
    showSideBar: Boolean
  },
  computed: {
    ...mapGetters(["signedIn", "appLoading"]),
    showSidebarBurgerMenu() {
      return this.showSideBar && this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    $route() {
      this.$emit("input", this.value);
    }
  }
};
</script>
