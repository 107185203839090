<template>
  <div class="text-body-2 rounded-lg">
    <span class="mb-2 vflGreyDark--text">{{
      $t("assessment.form.healthConditions.admin.reportedIssues")
    }}</span>
    <span v-for="condition in userConditions" :key="condition.id">
      {{ $t(condition.text) }}
    </span>
    <span v-if="userAids.length > 0 && userConditions.length > 0">+</span>
    <span v-for="aid in userAids" :key="aid.id">
      <v-icon class="mr-1" color="black">{{ aid.icon }}</v-icon>
      {{ $t(aid.text) }}
    </span>
    <a @click="$emit('openDialog', allConditionValues)">{{
      $t("assessment.form.healthConditions.admin.supportDialog.button")
    }}</a>
  </div>
</template>

<script>
import { Conditions, Aids } from "./conditionsAndAids.js";
export default {
  name: "user-conditions",
  props: {
    conditions: Array
  },
  computed: {
    userConditions() {
      return Conditions.filter(c => this.conditions.includes(c.id));
    },
    userAids() {
      return Aids.filter(c => this.conditions.includes(c.id));
    },
    allConditionValues() {
      return [...this.userConditions, ...this.userAids].map(c => c.value);
    }
  }
};
</script>

<style scoped>
div {
  background-color: white;
  color: black;
  border: var(--v-vflGreyVeryLight-base) 1px solid;
  padding: 16px;
  max-width: 300px;
  box-shadow: 0px 4px 12px rgba(26, 43, 55, 0.08);
}
span {
  display: block;
  padding-top: 2px;
  padding-bottom: 2px;
}
a {
  display: block;
  text-decoration: underline;
  margin-top: 5px;
}
</style>
