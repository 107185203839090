<template>
  <canvas
    ref="canvas"
    v-show="animate"
    :style="{ width: width + 'px', height: height + 'px' }"
  ></canvas>
</template>

<script>
import { SineWaveGenerator } from "@/components/common/animations/sine-waves-animation-service.js";

const Colors = {
  PRIMARY_TRANSPARENT: "rgba(15, 114, 234, 0)",
  PURPLE_TRANSPARENT: "rgba(163, 32, 218, 0)"
};

export default {
  props: {
    animate: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 800
    },
    height: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      sineWave: null,
      animationFrameId: null
    };
  },
  watch: {
    animate(newVal) {
      if (newVal) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    }
  },
  mounted() {
    this.initSineWave();

    if (this.animate) {
      this.startAnimation();
    }
  },
  beforeDestroy() {
    this.stopAnimation();

    this.sineWave = null;
  },
  methods: {
    initSineWave() {
      const self = this;

      this.sineWave = new SineWaveGenerator({
        el: this.$refs.canvas,
        speed: this.generateRandomNumber(0.5, 1.5),
        canvasWidth: this.width,
        canvasHeight: this.height,
        waves: self.generateWavesArray(this.generateRandomNumber(3, 6)),
        initialize: function () {
          const gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);

          gradient.addColorStop(0, Colors.PRIMARY_TRANSPARENT);
          gradient.addColorStop(
            0.25,
            self.$vuetify.theme.currentTheme.vflPrimary
          );
          gradient.addColorStop(
            0.75,
            self.$vuetify.theme.currentTheme.vflPurple
          );
          gradient.addColorStop(1, Colors.PURPLE_TRANSPARENT);

          this.waves.forEach(wave => (wave.strokeStyle = gradient));
        }
      });
    },
    startAnimation() {
      const animate = () => {
        this.sineWave.clear();
        this.sineWave.update();
        this.animationFrameId = window.requestAnimationFrame(animate);
      };
      animate();
    },
    stopAnimation() {
      if (this.animationFrameId) {
        window.cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
        this.sineWave.clear();
      }
    },
    generateRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    generateWavesArray(count) {
      const waves = [];

      for (let i = 0; i < count; i++) {
        const lineWidth = this.generateRandomNumber(0.5, 2);
        const amplitude = this.generateRandomNumber(
          this.height * 0.1,
          this.height * 0.5
        );
        const wavelength = this.generateRandomNumber(
          this.width * 0.1,
          this.width * 2
        );
        const segmentLength = this.generateRandomNumber(1, 10);
        const timeModifier = this.generateRandomNumber(0.75, 3);

        waves.push({
          timeModifier: timeModifier,
          lineWidth: lineWidth,
          amplitude: amplitude,
          wavelength: wavelength,
          segmentLength: segmentLength
        });
      }

      return waves;
    }
  }
};
</script>
