<template>
  <v-snackbar v-model="snackbar.show" :color="color" :timeout="3000" right>
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserProfileSnackbar",
  computed: {
    ...mapGetters("userProfile", ["snackbar"]),
    color() {
      return this.snackbar.type === "success"
        ? "var(--v-vflSuccess-base)"
        : "vflDarkRed";
    }
  }
};
</script>
