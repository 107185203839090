import i18n from "@/plugins/i18n.js";

function getPainChangeText(previous, current) {
  if (previous === null) {
    return { arrow: "", changeDescription: "" };
  }

  const difference = Math.abs(current - previous);
  const changeText = difference === 1 ? "point" : "points";

  if (current === previous) {
    return { arrow: "→", changeDescription: "(stayed the same)" };
  } else if (current < previous) {
    return {
      arrow: "↓",
      changeDescription: `(improved by ${difference} ${changeText})`
    };
  } else {
    return {
      arrow: "↑",
      changeDescription: `(worsened by ${difference} ${changeText})`
    };
  }
}

export const getPainEventText = item => {
  const { arrow, changeDescription } = getPainChangeText(
    item.previousLevel,
    item.newLevel
  );
  const areaText = i18n.t(`reportText.pain.painAreaNames.${item.area}.title`);
  return `${arrow} ${areaText} pain check-in: ${item.newLevel}/10 ${changeDescription}`;
};
