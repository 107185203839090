import Vue from "vue";
import Vuetify from "vuetify/lib";
import { standardTheme } from "@/styles/themes.js";
import Ripple from "vuetify/lib/directives/ripple";
import Scroll from "vuetify/lib/directives/scroll";

Vue.use(Vuetify, {
  directives: {
    Ripple,
    Scroll
  }
});

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: standardTheme
    }
  }
});

export default vuetify;
