<template>
  <div
    v-if="user"
    class="user-profile-container d-flex flex-column"
    :class="`is-${layout}`"
  >
    <div class="sticky-header" :class="`is-${layout}`">
      <user-profile-header />
      <vfl-divider class="mt-4" />
    </div>
    <div
      class="scrolling-content d-flex flex-column pt-4 mb-12"
      ref="scrollingContent"
      :class="`is-${layout}`"
    >
      <user-profile-assessments :layout="layout" />

      <vfl-divider v-if="canViewNotes" />
      <user-profile-notes v-if="canViewNotes" />

      <vfl-divider v-if="canViewRisks" />
      <user-profile-risks v-if="canViewRisks" />

      <vfl-divider />
      <user-profile-history />
    </div>
  </div>
</template>

<script>
import UserProfileNotes from "@/components/user-profile/UserProfileNotes.vue";
import UserProfileHeader from "@/components/user-profile/UserProfileHeader.vue";
import UserProfileHistory from "@/components/user-profile/UserProfileHistory.vue";
import UserProfileAssessments from "@/components/user-profile/UserProfileAssessments.vue";
import VflDivider from "@/components/vitrue-foundation-library/VflDivider.vue";
import { mapActions, mapGetters } from "vuex";
import UserProfileOfficeRisks from "@/components/user-profile/UserProfileOfficeRisks.vue";
import UserProfileHomeRisks from "@/components/user-profile/UserProfileHomeRisks.vue";
import UserProfileRisks from "@/components/user-profile/UserProfileRisks.vue";

export default {
  name: "UserProfile",
  components: {
    UserProfileRisks,
    UserProfileOfficeRisks,
    UserProfileHomeRisks,
    VflDivider,
    UserProfileAssessments,
    UserProfileHistory,
    UserProfileHeader,
    UserProfileNotes
  },
  props: {
    user: {
      type: Object
    },
    layout: {
      type: String,
      default: "page",
      validator: value => ["sidepanel", "page"].includes(value)
    }
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    canViewRisks() {
      return !this.anonymousResults;
    },
    canViewNotes() {
      return !this.anonymousResults;
    }
  },
  watch: {
    user: {
      handler: function (newUser, oldUser) {
        if (newUser && (!oldUser || newUser.id !== oldUser.id)) {
          this.handleUser();
          this.$nextTick(() => {
            this.scrollToTop();
          });
        } else if (!newUser) {
          this.reset();
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions("userProfile", ["setUserDetails", "getUserData", "reset"]),
    handleUser() {
      if (!this.user) return;

      this.setUserDetails(this.user);
      this.getUserData(this.user.id);
    },
    scrollToTop() {
      if (this.$refs.scrollingContent) {
        this.$refs.scrollingContent.scrollTop = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-container {
  height: 100%;
  position: relative;

  &.is-page {
    // top nav height = 64px
    height: calc(100vh - 64px - var(--main-padding-bottom));
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem 2rem !important;
  }
}

.sticky-header {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;

  &.is-page {
    padding-top: 2rem;
    top: 64px; // top nav height
  }
}

.scrolling-content {
  gap: 1rem;
  flex: 1 0 auto;

  // Align the scrollbar with the right edge and add space between content and scrollbar
  margin-right: -1rem;
  padding-right: 1rem;

  overflow-y: auto;
  scrollbar-gutter: stable;

  &.is-sidepanel {
    height: 100%;
    flex: auto;
  }
}
</style>
