<template>
  <v-card
    class="actionsMargin vfl-card page-break-inside-avoid d-flex flex-column"
    :width="width"
    :height="height"
  >
    <v-dialog
      v-model="bookAppointment"
      content-class="elevation-0 noScroll"
      max-width="1000px"
    >
      <CalendlyWidget
        v-if="bookAppointment"
        :deskAssessment="deskAssessment"
        :assessmentId="assessmentId"
        @close="bookAppointment = false"
        @update="checkIfActionButtonIsEnabled"
      />
    </v-dialog>
    <v-dialog
      v-if="showVideo"
      v-model="showVideo"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-bottom-transition"
    >
      <ExerciseVideo
        :title="heading"
        :link="link"
        :disclaimerSmallScreen="$t('wellness.v2.disclaimerSmallScreen')"
        :disclaimer="videoDisclaimer"
        @close="showVideo = false"
      />
    </v-dialog>
    <v-dialog v-if="showEmailDialog" v-model="showEmailDialog" max-width="550px"
      ><email-recommendation-dialog
        id="emailRecommendationDialog"
        v-model="showEmailDialog"
        :action="action"
    /></v-dialog>
    <v-dialog v-model="tipsDialog">
      <v-card style="height: 75vh" class="py-2">
        <iframe
          id="tipsFrame"
          :src="link"
          style="min-height: 100%; width: 100%; border: none"
        ></iframe>
      </v-card>
    </v-dialog>

    <recommendation-card-title
      :heading="heading"
      :subheading="subheading"
      :imageSource="
        json.imageName
          ? `/images/recommendations/${json.imageName}`
          : json.imageLink
      "
      :hideImageOnMobile="slideGroup"
      :coveredBy="json.coveredBy"
      :showPlayButton="linkType === 'videoLink' && link !== ''"
      @showVideo="showVideo = true"
      @onImageLoaded="onCardTitleLoaded"
    />
    <relevant-to-condition-label
      class="mt-3 mx-3"
      v-if="deskAssessment"
      :results="deskAssessment"
      :recommendationId="id"
    />
    <v-row
      v-if="impact > 0"
      id="impactContainer"
      class="circle text-center"
      align="center"
      justify="center"
      no-gutters
    >
      <v-col>
        <p class="text-h5 mb-1 white--text reducedLineHeight">x{{ impact }}</p>
        <p class="text-subtitle-2 ma-0 white--text reducedLineHeight">
          {{ $t("burnoutReportRecommendations.impact") }}
        </p>
      </v-col>
    </v-row>

    <div class="ma-4 black--text text-body-2" style="min-height: 80px">
      <p
        id="explanation"
        v-html="explanation"
        class="text-body-dark"
        data-recommendation-card-explanation
      ></p>

      <v-tooltip bottom :disabled="linkEnabled">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <button
              id="actionButton"
              v-if="showActionButton"
              :class="{
                'text-button-is-disabled': disableActionButton
              }"
              @click.stop="actionButtonClick"
              style="font-weight: bold !important"
            >
              <v-icon class="mr-2" color="black" size="20">
                {{ infoButtonStyle.icon }} </v-icon
              ><span
                id="actionButtonText"
                class="action-underline text-body-dark"
                >{{ linkMessage }}
              </span>
            </button>
          </div>
        </template>
        <span>{{
          $t(
            `deskAssessmentRecommendationCards.custom.ttqWel.inPersonVisit.disabledTooltip.${
              linkEnabledMessage === "AppointmentCompleted"
                ? "completedAppointment"
                : "hasActiveAppointment"
            }`
          )
        }}</span>
      </v-tooltip>
    </div>

    <div
      id="buttonsContainer"
      v-if="showCompletionButton"
      class="cardActions no-print mx-4 pt-4"
    >
      <v-tooltip
        bottom
        :disabled="canInteract && !isPreviewCard"
        color="transparent"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <button
              id="completeRecommendation"
              @click.stop="
                cardInteraction(
                  showTryItButton
                    ? $options.InteractionStates.WANT_TO_TRY
                    : $options.InteractionStates.COMPLETED
                )
              "
              style="width: 100%"
              class="vfl-button-primary is-medium d-flex align-center justify-center"
              :class="[{ 'is-disabled': !canInteract }]"
            >
              <img
                v-if="showTryItButton"
                class="mr-2"
                src="/images/icons/confetti.svg"
                width="18px"
                height="18px"
                alt=""
              />
              {{
                showTryItButton
                  ? $t("recommendationsCardComponent.completed.illTryIt")
                  : $t("recommendationsCardComponent.completed.markAsComplete")
              }}
            </button>
            <div
              class="d-flex justify-space-around mt-4 card-footer-subactions"
            >
              <button
                id="alreadyDoingButton"
                v-if="onAssessmentPage"
                class="d-flex align-center text-button-reset text-small text-body-dark"
                :class="[{ 'disable-text-button': !canInteract }]"
                @click.stop="
                  cardInteraction($options.InteractionStates.ALREADY_DOING)
                "
              >
                <v-icon class="mr-2" color="black" size="20"
                  >mdi-check-circle-outline</v-icon
                >{{ previouslyCompleteText }}
              </button>
              <button
                id="notForMeButton"
                class="d-flex align-center text-button-reset text-small text-body-dark"
                :class="[{ 'disable-text-button': !canInteract }]"
                @click.stop="
                  cardInteraction($options.InteractionStates.NOT_INTERESTED)
                "
              >
                <v-icon class="mr-2" color="black" size="20"
                  >mdi-close-circle-outline</v-icon
                >{{ $t("recommendationsCardComponent.notForMe") }}
              </button>
            </div>
          </div>
        </template>
        <vitrue-tooltip
          :message="$t('recommendationsCardComponent.previewCompleteTooltip')"
        />
      </v-tooltip>
    </div>

    <recommendation-overlay
      :loading="updating"
      :currentState="currentState"
      :linkType="linkType"
      :interactable="canInteract"
      :assessmentId="assessmentId"
      :recommendationId="id"
      @close="setState($options.InteractionStates.NONE)"
      :userInteraction="userInteraction"
    />

    <v-snackbar v-model="showSuccessMessage" timeout="5000" color="success">
      <p class="mb-0 text-center">{{ successMessage }}</p>
    </v-snackbar>
    <v-snackbar v-model="showErrorMessage" timeout="5000" color="error">
      <p class="mb-0 text-center">{{ errorMessage }}</p>
    </v-snackbar>
  </v-card>
</template>

<script>
import { makeEquipmentRequest, userCanBookAppointment } from "@/customApi";
import ExerciseVideo from "@/components/common/exercise-videos/ExerciseVideo.vue";
import EmailRecommendationDialog from "@/components/deskassessment/report/content/custom/EmailRecommendationDialog.vue";
import {
  LinkTypes,
  InfoButtonStyles
} from "@/services/recommendations/link-types.js";
import CalendlyWidget from "../CalendlyWidget.vue";
import { mapGetters } from "vuex";
import { viewingOwnResults } from "@/services/privacy-service.js";

import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { EventBus } from "@/services/events/event-bus";
import {
  InteractionStates,
  updateRecommendationStatus
} from "./recommendation-interaction-service.js";
import RecommendationOverlay from "./RecommendationOverlay.vue";
import RecommendationCardTitle from "./RecommendationCardTitle.vue";
import { RecommendationCategory } from "@/services/recommendations/categories.js";
import RelevantToConditionLabel from "@/components/assessment/pre-existing-conditions/RelevantToConditionLabel.vue";

export default {
  name: "recommendations-card",
  components: {
    ExerciseVideo,
    EmailRecommendationDialog,
    CalendlyWidget,
    VitrueTooltip,
    RecommendationOverlay,
    RecommendationCardTitle,
    RelevantToConditionLabel
  },
  props: {
    id: String,
    assessmentId: String,
    heading: String,
    subheading: String,
    explanation: String,
    type: String,
    json: {
      type: Object,
      default: {}
    },
    link: {
      type: String,
      default: ""
    },
    previouslyRequested: {
      type: Boolean,
      default: false
    },
    width: String,
    height: String,
    impact: Number,
    slideGroup: Boolean,
    videoDisclaimer: String,
    linkType: String,
    deskAssessment: Object,
    action: String,
    isPreviewCard: Boolean,
    loadedState: String
  },
  mounted() {
    this.checkIfActionButtonIsEnabled();
  },
  data() {
    return {
      dialog: false,
      showVideo: false,
      showEmailDialog: false,
      tipsDialog: false,
      requested: this.previouslyRequested,
      requestInProgress: false,
      bookAppointment: false,
      linkEnabled: this.linkType !== LinkTypes.APPOINTMENT,
      linkEnabledMessage: "",
      showSuccessMessage: false,
      showErrorMessage: false,
      successMessage: "",
      errorMessage: "",
      updating: false,
      currentState: this.handleLoadedState(this.loadedState),
      userInteraction: false
    };
  },
  InteractionStates: InteractionStates,
  LinkTypes: LinkTypes,
  watch: {
    previouslyRequested(newVal) {
      this.requested = newVal;
      if (newVal) {
        this.currentState = InteractionStates.COMPLETED;
      }
    },
    loadedState(newVal) {
      this.currentState = this.handleLoadedState(newVal);
    }
  },
  computed: {
    ...mapGetters(["userId"]),
    showCompletionButton() {
      if (
        this.linkType === LinkTypes.APPOINTMENT ||
        this.linkType === LinkTypes.REPORT_DOWNLOAD
      ) {
        return false;
      }
      if (this.linkType === LinkTypes.REQUEST && this.requested) {
        return false;
      }
      return true;
    },
    showTryItButton() {
      return this.$route.meta?.assessmentRoute;
    },
    onAssessmentPage() {
      return this.$route.meta?.assessmentRoute;
    },
    showActionButton() {
      if (!this.infoButtonStyle) {
        return false;
      }
      if ([LinkTypes.REGULAR, LinkTypes.PURCHASE].includes(this.linkType)) {
        return !!this.link;
      }
      return true;
    },
    canInteract() {
      return viewingOwnResults(this.$route) && !this.isPreviewCard;
    },
    infoButtonStyle() {
      return InfoButtonStyles[this.linkType];
    },
    linkMessage() {
      return this.linkBehaviour[this.linkType]?.actionMessage;
    },
    linkBehaviour() {
      return {
        [LinkTypes.VIDEO]: {
          actionMessage: this.$t("recommendationsCardComponent.playVideo"),
          onClick: async () => {
            this.showVideo = true;
          }
        },
        [LinkTypes.INTERNAL]: {
          actionMessage: this.$t("recommendationsCardComponent.goToRoute"),
          onClick: async () => {
            this.$router.push({
              name: this.json.route
            });
          }
        },
        [LinkTypes.EMAIL]: {
          actionMessage: this.$t("recommendationsCardComponent.contactUs"),
          onClick: async () => {
            this.copyEmail();
          }
        },
        [LinkTypes.REQUEST]: {
          actionMessage: this.requested
            ? this.$t("recommendationsCardComponent.requested")
            : this.$t("recommendationsCardComponent.requestNow"),
          onClick: async () => {
            await this.requestItem();
          }
        },
        [LinkTypes.EMAILREQUEST_TTQWEL]: {
          actionMessage: this.$t("recommendationsCardComponent.howToRequest"),
          onClick: async () => {
            this.showEmailDialog = true;
          }
        },
        [LinkTypes.PURCHASE]: {
          actionMessage: this.$t("recommendationsCardComponent.buy"),
          onClick: async () => {
            window.open(this.link, "_blank");
          }
        },
        [LinkTypes.REGULAR]: {
          actionMessage: this.$t("recommendationsCardComponent.learnMore"),
          onClick: async () => {
            window.open(this.link, "_blank");
          }
        },
        [LinkTypes.APPOINTMENT]: {
          actionMessage: this.$t(
            "recommendationsCardComponent.scheduleAppointment"
          ),
          onClick: () => {
            this.bookAppointment = true;
          },
          canClick: async () => {
            try {
              var response = await userCanBookAppointment(this.assessmentId);
              return response;
            } catch (err) {
              return false;
            }
          }
        },
        [LinkTypes.TIPS]: {
          actionMessage: this.$t("recommendationsCardComponent.tips"),
          onClick: async () => {
            this.tipsDialog = true;
          }
        },
        [LinkTypes.REPORT_DOWNLOAD]: {
          actionMessage: this.$t("recommendationsCardComponent.reportDownload"),
          onClick: async () => {
            this.$mixpanel.track(`Download report from recommendation card`);
            EventBus.$emit("downloadReport");
            this.cardInteraction(InteractionStates.COMPLETED);
          }
        }
      };
    },
    disableActionButton() {
      return !this.linkEnabled || this.requestInProgress;
    },
    previouslyCompleteText() {
      const category = this.json.category;
      let key;

      if (category === RecommendationCategory.EQUIPMENT) {
        key = "alreadyUsing";
      } else {
        key = "alreadyDoing";
      }

      return this.$t(`recommendationsCardComponent.completed.${key}`);
    }
  },
  methods: {
    async actionButtonClick() {
      if (this.disableActionButton) {
        return;
      }

      await this.linkBehaviour[this.linkType]?.onClick();

      this.$gtag.event(
        `Link button clicked for recommendation - ${this.id} of type ${this.linkType}`,
        {
          event_category: "Desk Assessment - Report"
        }
      );
      this.$mixpanel.track("Recommendation click", {
        page: this.$route.path,
        type: this.linkType,
        id: this.id
      });
    },
    setState(interactionType) {
      this.currentState = interactionType;
      this.$emit("update", {
        id: this.id,
        type: interactionType
      });
      this.$mixpanel.track(`Marked recommendation as ${interactionType}`, {
        type: this.linkType,
        id: this.id,
        assessmentId: this.assessmentId
      });
    },
    async cardInteraction(interactionType) {
      if (!this.canInteract) {
        return;
      }

      this.userInteraction = true;

      try {
        this.updating = true;

        await updateRecommendationStatus(
          this.assessmentId,
          this.id,
          interactionType
        );
        this.setState(interactionType);
      } catch (err) {
        this.$logger.captureException(err);
        this.errorMessage = this.$t(
          "recommendationsCardComponent.errorCompletion"
        );

        this.showErrorMessage = true;
      } finally {
        this.updating = false;
      }
    },
    async requestItem() {
      if (this.requested) {
        return;
      }

      try {
        this.requestInProgress = true;
        await makeEquipmentRequest(this.id, this.assessmentId);
        this.successMessage = this.$t(
          "recommendationsCardComponent.requestSuccess"
        );
        this.showSuccessMessage = true;
        await updateRecommendationStatus(
          this.assessmentId,
          this.id,
          InteractionStates.COMPLETED
        );
        this.setState(InteractionStates.COMPLETED);
        this.requested = true;
      } catch (ex) {
      } finally {
        this.requestInProgress = false;
      }
    },
    async checkIfActionButtonIsEnabled() {
      let canClick = this.linkBehaviour[this.linkType]?.canClick;
      if (canClick) {
        var response = await canClick();
        this.linkEnabled = response.canBook;
        this.linkEnabledMessage = response.message;
        if (
          !response.canBook &&
          this.currentState !== InteractionStates.COMPLETED
        ) {
          await updateRecommendationStatus(
            this.assessmentId,
            this.id,
            InteractionStates.COMPLETED
          );
          this.setState(InteractionStates.COMPLETED);
        }
      }
    },
    copyEmail() {
      var component = this;
      navigator.clipboard.writeText(this.link).then(
        function () {
          component.successMessage = component.$t(
            "recommendationsCardComponent.email.copied"
          );
          component.showSuccessMessage = true;
        },
        function () {
          component.errorMessage = component.$t(
            "recommendationsCardComponent.email.failed"
          );
          component.showErrorMessage = true;
        }
      );
    },
    handleLoadedState(state) {
      if (
        state === InteractionStates.WANT_TO_TRY &&
        !this.$route.meta?.assessmentRoute
      ) {
        return InteractionStates.NONE;
      }
      return state;
    },
    onCardTitleLoaded() {
      this.$emit("onCardTitleLoaded");
    }
  }
};
</script>

<style scoped lang="scss">
.circle {
  position: absolute;
  top: -5%;
  right: -3%;
  z-index: 4;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: var(--v-vitruePaleBlue-base);
}

.cardActions {
  position: relative;
  bottom: 0;
  margin-top: auto;
}

.card-footer-subactions {
  button i {
    position: relative;
    top: 1px; // optical rendering
  }
}

.actionsMargin {
  padding-bottom: 1.5rem;
}

.reducedLineHeight {
  line-height: 1rem !important;
}

.subheading {
  color: rgba(255, 255, 255, 0.8);
}

.action-underline {
  position: relative;
  display: inline-block;
}
.action-underline::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px; /* Adjust this value for the offset */
  height: 1px; /* Adjust thickness */
  background-color: #343d48; /* Adjust underline color */
}

.disable-text-button {
  opacity: 0.8;
  pointer-events: none;
}
</style>
