<template>
  <nav>
    <button @click="onPrev" :class="{ 'is-disabled': disablePrev }">
      <span class="d-sr-only">{{
        $t("dashboardAssessmentCards.navigation.prev")
      }}</span
      ><v-icon color="white" size="40">mdi-chevron-left</v-icon></button
    ><button @click="onNext" :class="{ 'is-disabled': disableNext }">
      <span class="d-sr-only">{{
        $t("dashboardAssessmentCards.navigation.next")
      }}</span
      ><v-icon color="white" size="40">mdi-chevron-right</v-icon>
    </button>
  </nav>
</template>
<script>
export default {
  name: "DashboardAssessmentCardsNav",
  props: {
    disablePrev: Boolean,
    disableNext: Boolean
  },
  methods: {
    onPrev() {
      this.$emit("onPrev");
    },
    onNext() {
      this.$emit("onNext");
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  button {
    background: var(--v-vflPrimary-base);
    border-radius: 9999px;
    position: absolute;
    top: calc(50% - 16px);
    transform: translateY(-50%) scale(1);
    transition: opacity 0.05s ease-out, transform 0.075s ease-out;

    &:first-child {
      left: -20px;

      i {
        left: -1px;
        position: relative;
      }
    }

    &:last-child {
      right: -20px;

      i {
        position: relative;
        right: -1px;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0;
      transform: translateY(-50%) scale(0.9);
    }
  }
}
</style>
