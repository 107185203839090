import {
  AssessmentStateCodes,
  Locations
} from "./assessmentHistoryConstants.js";
import store from "@/store";
import { assessmentTypes } from "@/constants/constants.js";

export function filterUsers(users, filters) {
  if (!users) {
    return [];
  }

  if (!filters) {
    return users;
  }

  // Deep clone to prevent reference items mutating
  let clonedUsers = deepClone(users);

  // Filter archived assessments before other filters
  if (!filters.showArchived) {
    clonedUsers = filterArchivedAssessments(clonedUsers, filters);
  }

  const usersByEmailOrName = getUsersByEmailOrName(clonedUsers, filters.search);
  const usersByTag = getUsersWithAllFilterTags(usersByEmailOrName, filters.tag);

  const filteredUsers = filterUserAssessments(usersByTag, filters);

  return filteredUsers;
}

export function filterUsersWithoutCompletedAssessmentInEnvironment(
  users,
  environment
) {
  if (!users) {
    return [];
  }
  users = deepClone(users);
  return users.filter(user => {
    return !user.assessments.some(
      assessment =>
        assessment.location === environment &&
        assessment.state === AssessmentStateCodes.Completed
    );
  });
}

export function filterUsersWithCompletedHomeAndOfficeAssessments(users) {
  if (!users) {
    return [];
  }
  users = deepClone(users);
  return users.filter(user => {
    const hasHome = user.assessments.some(
      assessment =>
        assessment.location === Locations.home.value &&
        assessment.state === AssessmentStateCodes.Completed
    );
    const hasOffice = user.assessments.some(
      assessment =>
        assessment.location === Locations.office.value &&
        assessment.state === AssessmentStateCodes.Completed
    );
    return hasHome && hasOffice;
  });
}

export function filterUsersWithoutCompletedDeskAssessments(users) {
  if (!users) {
    return [];
  }
  users = deepClone(users);
  return users.filter(user => {
    return (
      user.assessments.length > 0 &&
      !user.assessments.some(
        assessment =>
          assessment.assessmentType === assessmentTypes.deskAssessment &&
          assessment.state === AssessmentStateCodes.Completed
      )
    );
  });
}

export function filterUsersWithoutAnyDeskAssessments(users) {
  if (!users) {
    return [];
  }
  users = deepClone(users);
  return users.filter(user => {
    return (
      user.assessments.filter(
        assessment =>
          assessment.assessmentType === assessmentTypes.deskAssessment
      ).length === 0
    );
  });
}

export function filterUsersWithAHealthCondition(users) {
  if (!users) {
    return [];
  }
  users = deepClone(users);
  return users.filter(user => user.healthConditions?.length > 0);
}

export const filterArchivedAssessments = users => {
  // Incomplete assessments that have been superseded by another assessment (same type and location) can be archived

  const Status = {
    COMPLETE: "complete",
    INCOMPLETE: "incomplete"
  };

  users.forEach(user => {
    if (!user.assessments?.length) return [];

    const assessmentStatusMap = {};
    const filteredAssessments = [];

    user.assessments
      .sort((a, b) => {
        const aDate = a.lastUpdatedAt ?? a.createdAt;
        const bDate = b.lastUpdatedAt ?? b.createdAt;
        return new Date(bDate) - new Date(aDate);
      })
      .forEach(assessment => {
        const state =
          assessment.state === AssessmentStateCodes.Completed
            ? Status.COMPLETE
            : Status.INCOMPLETE;

        const key = generateAssessmentKey(assessment, state);

        // Always add completed assessments
        if (state === Status.COMPLETE) {
          filteredAssessments.push(assessment);
          assessmentStatusMap[key] = true;
          return;
        }

        // Push an incomplete assessment if it hasn't been superseded by any other type
        const completedAssessmentKey = generateAssessmentKey(
          assessment,
          Status.COMPLETE
        );
        const incompleteAssessmentKey = generateAssessmentKey(
          assessment,
          Status.INCOMPLETE
        );
        if (
          !assessmentStatusMap[completedAssessmentKey] &&
          !assessmentStatusMap[incompleteAssessmentKey]
        ) {
          filteredAssessments.push(assessment);
          assessmentStatusMap[key] = true;
        }
      });

    user.assessments = filteredAssessments;
  });

  return users;
};

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function getUsersByEmailOrName(users, search) {
  if (!search) return users;
  return users.filter(
    x =>
      x.email.toLowerCase().includes(search.toLowerCase()) ||
      (x.name && x.name.toLowerCase().includes(search.toLowerCase()))
  );
}

function getUsersWithAllFilterTags(users, tags) {
  if (tags.length === 0) return users;
  return users.filter(x => tags.every(a => x.tags.includes(a)));
}

function filterUserAssessments(users, filters) {
  if (!hasAssessmentFilters(filters)) {
    return users;
  }

  var dates = getFilterDates(filters.date);

  users.forEach(u => {
    u.assessments = u.assessments.filter(a => {
      return (
        hasValidLocation(a, filters.location) &&
        hasValidState(a, filters.state) &&
        inDateRange(a, dates)
      );
    });
  });

  const usersWithAssessments = users.filter(x => x.assessments.length > 0);

  return usersWithAssessments;
}

function hasAssessmentFilters(filters) {
  return (
    filters.location.length > 0 ||
    filters.state.length > 0 ||
    filters.date.length > 0
  );
}

function getFilterDates(dates) {
  return dates.map(d => {
    var date = new Date(d);
    date.setHours(0, 0, 0, 0);
    return date;
  });
}

function hasValidLocation(assessment, locations) {
  if (locations.length === 0) return true;
  return locations.includes(assessment.location);
}

function hasValidState(assessment, states) {
  if (states.length === 0) return true;
  return states.includes(assessment.state);
}

function inDateRange(assessment, dates) {
  if (dates.length === 0) return true;
  var date = new Date(assessment.lastUpdatedAt);
  date.setHours(0, 0, 0, 0);
  return date >= dates[0] && date <= dates[1];
}

export function getHomeOfficeRisks(user) {
  var completeAssessments = user.assessments.filter(
    a => a.state === AssessmentStateCodes.Completed
  );
  var homeOfficeAsesssments = completeAssessments.filter(
    x => x.location === "Home" || x.location === "Office"
  );

  var mostRecentAssessmentsByLocation = getMostRecentAssessmentByLocation(
    homeOfficeAsesssments
  );
  return getRelevantRisksByLocation(mostRecentAssessmentsByLocation);
}

function getMostRecentAssessmentByLocation(assessments) {
  return assessments.reduce((dict, a) => {
    var location = a.location;
    if (!(location in dict) || isMoreRecent(a, dict[location])) {
      dict[location] = a;
    }
    return dict;
  }, {});
}

function isMoreRecent(assessment1, assessment2) {
  return (
    new Date(assessment1.lastUpdatedAt) > new Date(assessment2.lastUpdatedAt)
  );
}

export const irrelevantRisks = [
  "noNaturalLightInOffice",
  "noPlants",
  "noWater"
];

function getRelevantRisksByLocation(assessmentDict) {
  return Object.fromEntries(
    Object.entries(assessmentDict).map(x => [
      x[0],
      getRelevantRisks(x[1].risks)
    ])
  );
}

export function getRelevantRisks(risks) {
  return risks.filter(r => !irrelevantRisks.includes(r));
}

export function getLatestAssessmentDate(user) {
  var assessmentDates = user.assessments.map(
    a => new Date(a.lastUpdatedAt ?? a.createdAt)
  );
  return assessmentDates.reduce(function (a, b) {
    return a > b ? a : b;
  });
}

export function getPreloadedUserAssessment(users) {
  var id = store.getters.adminAssessmentToView;
  var user = users.find(u => u.assessments.some(a => a.id === id));

  if (!user) return null;

  var assessment = user.assessments.find(a => a.id === id);
  return { user, assessment };
}

function generateAssessmentKey(assessment, state) {
  return `${assessment.assessmentType}-${assessment.location}-${state}`;
}
