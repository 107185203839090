import { getRecommendationTextFromId } from "@/components/user-profile/history/get-recommendation-text.js";
import i18n from "@/plugins/i18n.js";

const RECOMMENDATION_INTERACTIONS = {
  0: "Completed",
  1: "Marked as unresolved",
  2: "Already doing",
  3: "Wants to try",
  4: "Not interested in"
};

export const getRecommendationEventText = item => {
  const interaction =
    RECOMMENDATION_INTERACTIONS[item.interactionType] || "Interacted with";
  let recommendationText = getRecommendationTextFromId(item.name);

  recommendationText = recommendationText ? i18n.t(recommendationText) : "";

  if (recommendationText) {
    return `${interaction} recommendation: '${recommendationText}'`;
  } else {
    return `${interaction} a recommendation`;
  }
};
