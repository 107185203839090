<template>
  <div class="container pa-10 rounded-xl">
    <button class="close-button ma-5" @click="$emit('close')">
      <v-icon color="black">close</v-icon>
    </button>
    <h2 class="mb-2 text-h5">
      {{ $t("assessment.form.healthConditions.admin.supportDialog.title") }}
    </h2>
    <p class="mb-8">
      {{ $t("assessment.form.healthConditions.admin.supportDialog.preText") }}
    </p>
    <div
      class="conditions-container"
      :class="{ 'fixed-height': !isMobile, 'full-height': isMobile }"
    >
      <div v-for="section in visibleSections" :key="section.value" class="mb-6">
        <div v-if="section.title" class="d-flex mb-3">
          <v-icon v-if="section.icon" color="black" size="25">{{
            section.icon
          }}</v-icon>
          <img
            v-else
            :src="`images/icons/health-conditions/${section.image}`"
            width="25"
            height="25"
          />
          <h4 class="ml-3 text-h6">{{ $t(section.title) }}</h4>
        </div>
        <span>{{ $t(section.text) }}</span>
        <div
          v-if="
            section.subsection && conditions.includes(section.subsection.value)
          "
          class="d-flex mt-3"
        >
          <v-icon v-if="section.subsection.icon" color="black" size="25">{{
            section.subsection.icon
          }}</v-icon>
          <img
            v-else
            width="25"
            height="25"
            :src="`images/icons/health-conditions/${section.subsection.image}`"
          />
          <span class="ml-3">{{ $t(section.subsection.text) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-conditions",
  props: {
    conditions: Array
  },
  data() {
    return {
      sections: [
        {
          value: "mobilityImpairment",
          image: "mobility.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.mobilityImpairment.title",
          text: "assessment.form.healthConditions.admin.supportDialog.mobilityImpairment.text",
          subsection: {
            value: "mobilityAssistance",
            icon: "mdi-wheelchair",
            text: "assessment.form.healthConditions.admin.supportDialog.mobilityImpairment.mobilityAssistance"
          }
        },
        {
          value: "mskCondition",
          image: "msk.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.mskCondition.title",
          text: "assessment.form.healthConditions.admin.supportDialog.mskCondition.text"
        },
        {
          value: "injury",
          image: "msk.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.mskInjury.title",
          text: "assessment.form.healthConditions.admin.supportDialog.mskInjury.text"
        },
        {
          value: "hearingLoss",
          image: "hearing.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.hearingLoss.title",
          text: "assessment.form.healthConditions.admin.supportDialog.hearingLoss.text",
          subsection: {
            value: "hearingAid",
            image: "hearingAid.svg",
            text: "assessment.form.healthConditions.admin.supportDialog.hearingLoss.hearingAid"
          }
        },
        {
          value: "visionImpairment",
          image: "vision.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.visionImpairment.title",
          text: "assessment.form.healthConditions.admin.supportDialog.visionImpairment.text"
        },
        {
          value: "chronicFatigueSyndrome",
          image: "chronicFatigueSyndrome.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.chronicFatigueSyndrome.title",
          text: "assessment.form.healthConditions.admin.supportDialog.chronicFatigueSyndrome.text"
        },
        {
          value: "fibromyalgia",
          image: "fibromyalgia.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.fibromyalgia.title",
          text: "assessment.form.healthConditions.admin.supportDialog.fibromyalgia.text"
        },
        {
          value: "posturalTachycardiaSyndrome",
          image: "posturalTachycardiaSyndrome.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.posturalTachycardiaSyndrome.title",
          text: "assessment.form.healthConditions.admin.supportDialog.posturalTachycardiaSyndrome.text"
        },
        {
          value: "neurodiversity",
          image: "dyslexia.svg",
          title:
            "assessment.form.healthConditions.admin.supportDialog.neurodiversity.title",
          text: "assessment.form.healthConditions.admin.supportDialog.neurodiversity.text"
        }
      ]
    };
  },
  computed: {
    visibleSections() {
      return this.sections.filter(s => this.conditions.includes(s.value));
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style scoped>
.container {
  background: linear-gradient(to bottom right, #ffffff, #d9edff, #ece2ff);
  position: relative;
  overflow-y: auto;
  height: 100%;
}
.conditions-container {
  overflow-y: auto;
}
.full-height {
  max-height: 80%;
}
.fixed-height {
  max-height: 600px;
}
/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
