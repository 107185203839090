<template>
  <v-tooltip bottom :disabled="dataExists" color="transparent">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <button
          class="vfl-button-primary-ghost has-icon is-small pr-4 mr-n4"
          @click="csvExport()"
          :disabled="!dataExists"
        >
          <v-icon color="var(--v-vflTextDark-base)">mdi-export</v-icon>
          {{ $t("buttons.export") }}
        </button>
      </div>
    </template>
    <vitrue-tooltip :message="$t('tooltips.mustHaveDataToExport')" />
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "ExportToCSV",
  props: {
    data: Array
  },
  components: {
    VitrueTooltip
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    dataExists() {
      return this.data && this.data.length > 0;
    },
    filteredData() {
      if (!this.anonymousResults) return this.data;
      var filtered = [...this.data];
      filtered.forEach(e => {
        delete e.risks;
      });
      return filtered;
    }
  },
  methods: {
    csvExport() {
      let link = this.createExportElement();
      link.click();
    },
    createExportElement() {
      let content = this.getCSVContent();
      const uri = encodeURI(content);
      const link = document.createElement("a");
      link.setAttribute("href", uri);
      link.setAttribute("download", this.getCSVName() + ".csv");
      return link;
    },
    getCSVContent() {
      let header = this.getCSVHeader();
      return this.addCSVMainBodyToHeader(header);
    },
    getCSVHeader() {
      let headers = Object.keys(this.filteredData[0]);
      return headers.map(x => {
        const result = x.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
      });
    },
    addCSVMainBodyToHeader(header) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        header.join(","),
        ...this.filteredData.map(item => Object.values(item).join(","))
      ].join("\n");
      return csvContent;
    },
    getCSVName() {
      return `Vitrue Assessments_${new Date().toLocaleString()}`;
    }
  }
};
</script>
<style>
button {
  white-space: nowrap;
}
</style>
