<template>
  <div class="d-flex align-center">
    <v-skeleton-loader v-if="loading" type="text" width="230" height="34" />

    <v-tooltip
      v-else
      nudge-bottom="10"
      top
      color="transparent"
      @input="onTooltipVisibilityChange"
      :disabled="!disableAiFeatures"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-on="on"
          v-bind="attrs"
          class="ai-toggle d-flex"
          :class="[
            { 'is-active': value },
            { 'ai-toggle-is-disabled': disableAiFeatures }
          ]"
        >
          <label for="toggle-ai">
            <span class="d-flex align-center mr-4">
              <v-icon color="var(--v-vflPrimary-base)" class="mr-2">
                mdi-creation-outline
              </v-icon>
              <span class="text-ai-gradient font-weight-medium">AI search</span>
              <span class="beta">beta</span>
            </span>
          </label>
          <v-switch
            v-model="internalValue"
            @change="onChange"
            hide-details
            class="mt-0 pt-0"
            id="toggle-ai"
            color="var(--v-vflPrimary-base)"
            :ripple="false"
            :disabled="disableAiFeatures"
          />

          <ai-search-settings-panel
              v-if="internalValue && showAiSearchSettings && !disableAiFeatures"
              @ai-search-settings-changed="handleAiSearchSettingsChanged"
              class="mt-0 pt-0 ml-2"
          />
        </div>
      </template>
      <vitrue-tooltip maxWidth="400px" :message="tooltipMessage" />
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { aiFeaturesDisabledMessage } from "@/components/common/ai/utils.js";
import AiSearchSettingsPanel from "@/components/common/assessment-history/AiSearchSettingsPanel.vue";

export default {
  name: "AiSearchToggle",
  components: { VitrueTooltip, AiSearchSettingsPanel },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    loading: Boolean
  },
  data() {
    return {
      internalValue: this.value
    };
  },
  computed: {
    ...mapGetters(["disableAiFeatures", "showAiSearchSettings"]),
    tooltipMessage() {
      return aiFeaturesDisabledMessage();
    }
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    }
  },
  methods: {
    onChange(newValue) {
      if (this.disableAiFeatures) return;

      this.$emit("input", this.internalValue);
      this.$mixpanel.track(`AI Search toggled ${newValue ? "on" : "off"}`);
    },
    onTooltipVisibilityChange(isVisible) {
      if (isVisible) {
        this.$mixpanel.track("AI feature tooltip seen", {
          feature: "AI search"
        });
      }
    },
    handleAiSearchSettingsChanged(newSettings) {
      console.log("FIRED");
      this.$emit("aiSearchSettingsChanged", newSettings);
    }
  }
};
</script>
<style lang="scss" scoped>
.v-skeleton-loader {
  border-radius: 20px;

  ::v-deep > div {
    height: 100%;
  }
}

.ai-toggle {
  border: 1px solid var(--v-vflGreyVeryLight-base);
  border-radius: 20px;
  cursor: pointer;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  position: relative;

  &:before {
    content: "";
    background-image: linear-gradient(
      120deg,
      #fff,
      var(--v-vflPrimary-base),
      var(--v-vflPurple-base)
    );
    border-radius: inherit;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease-out;
  }

  &.is-active {
    &:before {
      opacity: 0.08;
    }
  }

  &.ai-toggle-is-disabled {
    cursor: default;
  }

  label {
    cursor: inherit;
  }
}

::v-deep .v-input__slot {
  flex-direction: row-reverse;
}

.gradient-text {
  background-image: linear-gradient(
    to right,
    var(--v-vflPrimary-base),
    var(--v-vflPurple-base)
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.beta {
  align-self: flex-start;
  background-color: hsla(0, 0%, 0%, 0.05);
  border-radius: 16px;
  font-size: 0.675rem;
  margin-left: 0.5rem;
  padding: 0.125rem 0.425rem;
}
</style>
