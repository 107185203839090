<template>
  <div class="ai-assistant-empty-state-container">
    <div class="content text-body-2">
      <ai-assistant-bubble-icon class="align-self-start" />
      <div>
        <p>
          Hey there! 👋<br />
          I am your VIDA MSK Copilot, and I can help you understand how your
          team can feel better in their workspaces.
        </p>
      </div>
    </div>
    <ai-assistant-message-divider text="New conversation" />

    <div v-if="randomSuggestions.length > 0" class="suggestions">
      <ul>
        <li
          v-for="suggestion in randomSuggestions"
          :key="suggestion.content"
          class="mb-3"
        >
          <button
            class="vfl-button-ai-card"
            @click="() => onSuggestionClick(suggestion)"
          >
            <span v-if="suggestion.title">{{ suggestion.title }}</span>
            {{ suggestion.content }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import AiAssistantBubbleIcon from "@/components/AiAssistant/WindowComponents/AiAssistantBubbleIcon.vue";
import AiAssistantMessageDivider from "@/components/AiAssistant/WindowComponents/AiAssistantMessageDivider.vue";
import { mapGetters } from "vuex";
import { sampleSize } from "lodash";

export default {
  name: "AiAssistantEmptyState",
  components: { AiAssistantMessageDivider, AiAssistantBubbleIcon },
  computed: {
    ...mapGetters("ai", ["conversationSuggestions", "currentAssessmentInfo"]),
    randomSuggestions() {
      return sampleSize(this.conversationSuggestions, 3);
    }
  },
  methods: {
    onSuggestionClick(suggestion) {
      this.$store.dispatch("ai/createNewConversationAndSendMessage", {
        message: suggestion.content
      });
    }
  }
};
</script>
<style scoped lang="scss">
.content {
  display: flex;
  gap: 0.5rem;
}

.suggestions {
  ul {
    list-style: none;
    padding: 0;
  }

  .vfl-button-ai-card {
    min-width: 100%;
  }
}
</style>
