<template>
  <table-filter
    v-model="filterTags"
    :items="tags"
    label="dashboardHistoryComponents.assessmentHistoryTable.labels.tag"
    @input="$emit('input', filterTags)"
    :translateText="false"
  />
</template>

<script>
import TableFilter from "./TableFilter.vue";
import { getTeamTags } from "@/customApi";

export default {
  name: "TagFilter",
  props: {
    value: Array
  },
  components: {
    TableFilter
  },
  data() {
    return {
      filterTags: this.value,
      teamTags: []
    };
  },
  created() {
    this.getTeamTags();
  },
  computed: {
    tags() {
      const tagNames = this.teamTags.map(({ name, type }) => {
        const isManager = type?.toLowerCase() === "manager";

        const text = isManager
          ? `${this.$t(
              "dashboardHistoryComponents.assessmentHistoryTable.text.managedBy"
            )}: ${name}`
          : name;
        return { text, value: name };
      });

      const noTagText = this.$t(
        "dashboardHistoryComponents.assessmentHistoryTable.labels.noTag"
      );

      return [...tagNames, { value: "No Tag", text: noTagText }];
    }
  },
  methods: {
    async getTeamTags() {
      try {
        let tags = await getTeamTags({
          everythingTag: false
        });

        this.teamTags = tags;
      } catch (err) {
        this.teamTags = [];
      }
    }
  }
};
</script>
