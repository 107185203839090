import sentry from "@/sentry.js";
import { processPainHistoryChanges } from "@/components/user-profile/history/userPainHistoryService.js";
import { processAssessmentHistory } from "@/components/user-profile/history/assessmentHistoryService.js";
import { processAccountHistory } from "@/components/user-profile/history/accountHistoryService.js";
import { processRecommendationHistory } from "@/components/user-profile/history/recommendationHistoryService.js";
import {
  getPainHistory,
  getUserAccountHistory,
  getRecommendationInteractions,
  getAssessmentHistory
} from "@/api/userApi";
import store from "@/store.js";

async function getUnifiedHistory(userId, signal) {
  try {
    const canViewPainHistory = !store.getters.anonymousResults;
    const canViewRecommendationInteractionsHistory =
      !store.getters.anonymousResults;

    let painHistory;
    let recommendationHistory;

    if (canViewPainHistory) {
      painHistory = await fetchHistoryData(getPainHistory, userId, signal);
    }

    const accountHistory = await fetchHistoryData(
      getUserAccountHistory,
      userId,
      signal
    );

    if (canViewRecommendationInteractionsHistory) {
      recommendationHistory = await fetchHistoryData(
        getRecommendationInteractions,
        userId,
        signal
      );
    }

    const assessmentHistory = await fetchHistoryData(
      getAssessmentHistory,
      userId,
      signal
    );

    const processedPainHistory = painHistory
      ? processPainHistoryChanges(painHistory)
      : [];
    const processedAssessmentHistory =
      processAssessmentHistory(assessmentHistory);
    const processedAccountHistory = processAccountHistory(accountHistory);
    const processedRecommendationHistory = recommendationHistory
      ? processRecommendationHistory(recommendationHistory)
      : [];

    const historyItems = [
      ...processedPainHistory,
      ...processedAssessmentHistory,
      ...processedAccountHistory,
      ...processedRecommendationHistory
    ];

    return historyItems.sort((a, b) => b.timestamp - a.timestamp);
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

async function fetchHistoryData(fetchFunction, userId, signal) {
  try {
    return await fetchFunction(userId, signal);
  } catch (error) {
    sentry.captureException(error);
    return [];
  }
}

export { getUnifiedHistory };
