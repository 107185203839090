<template>
  <div
    :style="`min-height: ${loading ? '500px' : ''}`"
    :class="reportPageSignUp ? 'fill-height' : ''"
  >
    <div v-if="!loading" :class="reportPageSignUp ? 'fill-height' : ''">
      <div v-if="!enterpriseSSOLoginAvailable && !reportPageSignUp">
        <p class="text-center mb-6 text-h2">
          {{ titleText }}
        </p>
      </div>

      <v-row
        justify="center"
        :no-gutters="reportPageSignUp"
        v-if="enterpriseSSOLoginAvailable"
        :class="reportPageSignUp ? 'fill-height' : ''"
      >
        <v-col :cols="columnWidth" class="d-flex justify-center">
          <v-img
            v-if="!reportPageSignUp"
            :src="'/images/' + ssoLoginCustomisation.image"
            contain
            :height="ssoLoginCustomisation.size"
            :max-width="ssoLoginCustomisation.size"
            class="mb-4"
          ></v-img>
        </v-col>
        <v-col :cols="columnWidth" align-self="end"
          ><button
            style="width: 100%"
            class="vfl-button-primary mt-2"
            @click="signInWithEnterpriseSSO()"
          >
            {{ $t(ssoLoginCustomisation.text) }}
          </button></v-col
        >
      </v-row>
      <v-row
        justify="center"
        no-gutters
        v-else
        :class="reportPageSignUp ? 'fill-height' : ''"
      >
        <v-col :cols="columnWidth" v-if="showSocialSignOn">
          <button
            id="googleButton"
            style="width: 100%"
            class="vfl-button-sso is-google"
            @click="signInWithFederation('Google')"
          >
            <span class="icon"
              ><img src="/images/icons/google-logo.svg"
            /></span>
            {{ $t("authentication.loginOptions.google") }}
          </button>

          <button
            style="width: 100%"
            class="vfl-button-sso is-apple mt-4 mb-8"
            @click="signInWithFederation('SignInWithApple')"
          >
            <span class="icon"><img src="/images/icons/apple-logo.svg" /></span>
            {{ $t("authentication.loginOptions.apple") }}
          </button>
          <v-divider class="mb-8 mx-3" />
        </v-col>
        <v-col :cols="columnWidth">
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-tooltip top :disabled="!disableEmailField">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-text-field
                    id="email"
                    v-if="showEmailField"
                    outlined
                    :label="$t('authentication.loginOptions.labels.workEmail')"
                    :rules="emailRules"
                    v-model="email"
                    type="email"
                    @keydown.enter="confirmLogin"
                    :disabled="disableEmailField || autoJoinDisabledByTeam"
                    @keydown="keyDownEvent"
                    data-cy="email"
                    color="var(--v-vflPrimary-base)"
                  />
                </div>
              </template>
              <span>{{ $t("signUpSimpleUserDialog.tooltip") }}</span>
            </v-tooltip>
            <v-text-field
              id="password"
              style="min-height: 86px"
              v-if="showPasswordField"
              :autocomplete="reportPageSignUp || signUp ? 'new-password' : ''"
              outlined
              class="mb-2"
              :label="$t('authentication.loginOptions.labels.password')"
              v-model="password"
              type="password"
              :rules="passwordRules"
              @keydown.enter="confirmLogin"
              @keydown="keyDownEvent"
              hide-details="auto"
              data-cy="password"
              color="var(--v-vflPrimary-base)"
            />
            <p
              v-if="showPasswordField && reportPageSignUp && !userExists"
              class="mb-10 mt-0"
            >
              {{ $t("authentication.loginOptions.passwordRuleInformation") }}
            </p>
            <button
              type="button"
              class="vfl-link text-body-2 mb-3"
              @click="$emit('forgotPassword')"
              v-if="showResetPasswordField"
            >
              {{ $t("authentication.loginOptions.forgotPassword") }}
            </button>
          </v-form>

          <vfl-alert
            v-if="errorMessage"
            :message="errorMessage"
            type="error"
            isLight
          />
          <vfl-alert v-else-if="customSSOSignInError" type="error" isLight
            ><i18n :path="customSSOSignInError.text">
              <template slot="link">
                <a target="_self" :href="customSSOSignInError.link">{{
                  $t(customSSOSignInError.linkText)
                }}</a>
              </template>
            </i18n></vfl-alert
          >

          <vfl-alert
            v-if="autoJoinDisabledByTeam"
            :message="$t('authentication.autoJoinTeamSettingDisabled')"
            type="error"
            isLight
          />
        </v-col>

        <v-col :cols="columnWidth" align-self="end">
          <button
            id="confirmButton"
            class="mt-6 vfl-button-primary"
            :class="[
              { 'is-loading': confirmLoading },
              { 'is-disabled': autoJoinDisabledByTeam }
            ]"
            style="width: 100%"
            @click="confirmLogin"
          >
            {{ showSignupButton ? signUpButtonText : loginButtonText }}
          </button>
        </v-col>
        <v-col
          :cols="columnWidth"
          v-if="signUp && !userExists && !reportPageSignUp"
          ><v-row no-gutters align="center"
            ><v-col cols="auto"
              ><v-checkbox
                v-model="termsAndConditions"
                readonly
                color="vflPrimary" /></v-col
            ><v-col class="vitrueGrey--text">
              <p class="mb-0 text-body-2">
                {{ $t("authentication.loginOptions.termsOfService.text") }}
                <a
                  class="vfl-link"
                  @click="openTermsOfService()"
                  href="https://vitrueremote.com/terms-of-service/"
                  target="_blank"
                  >{{
                    $t("authentication.loginOptions.termsOfService.link")
                  }}</a
                >
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col :cols="columnWidth" v-if="!reportPageSignUp && !autoJoinTeam">
          <p class="text-center mt-6">
            <span class="vitrueGrey--text mr-1">
              {{
                signUp
                  ? $t("authentication.loginOptions.existingAccount.signUp")
                  : $t("authentication.loginOptions.existingAccount.signIn")
              }}</span
            >
            <button class="vfl-link" @click="redirect">
              {{
                signUp
                  ? $t("authentication.loginOptions.signIn")
                  : $t("authentication.loginOptions.signUp")
              }}
            </button>
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters, mapMutations } from "vuex";
import Rules from "@/utils/stringValidation";
import {
  confirmEndUserSignUp,
  authUserExists,
  userTeamMemberInformation,
  joinEndUserToTeamViaSignupLink,
  isFromExternalProvider,
  domainMatchesSSOProvider
} from "@/customApi";
import {
  completeUserSignInWithoutRedirect,
  completeUserSignIn
} from "@/services/authorization/post-auth-setup.js";
import { EventBus } from "@/services/events/event-bus.js";
import DomainService from "@/services/domain-service.js";
import { startNewAssessment } from "../../customApi";
import LoginFeatureService from "@/services/authorization/login-feature-service";
import UserpoolService from "@/services/userpool/userpool-service.js";
import { getSSOCustomisation } from "@/services/authorization/sso-customisation-service.js";
import {
  setUpRedirectActions,
  saveActionToSessionStorage
} from "./loginActionService.js";

import {
  setUpRedirectPage,
  saveRedirectPageToSessionStorage
} from "@/services/authorization/url-redirect/url-redirect-service.js";
import {
  getUrlSearchParameters,
  removeUrlParameters
} from "@/services/url-service.js";
import Sentry from "@/sentry.js";
import debounce from "lodash/debounce";

export default {
  name: "LoginOptions",
  props: {
    signUp: Boolean,
    reportPageSignUp: Boolean,
    isReturningToPartialAssessment: Boolean,
    enterpriseSSOLoginAvailable: Boolean,
    ssoIdpName: String,
    ssoTeamId: String,
    simpleUserSignup: Boolean,
    errorMsg: String,
    codeConfirmSignIn: Boolean,
    assessmentId: String,
    autoJoinTeamId: String,
    autoJoinTeamInfo: Object
  },
  data() {
    return {
      email: "",
      password: "",
      regularSignUpStep: 1,
      valid: true,
      checkbox: false,
      disableEmailField: false,
      rules: Rules,
      confirmLoading: false,
      loadingComponent: false,
      checkingIfUserExists: false,
      errorMessage: this.errorMsg,
      customSSOSignInError: null,
      userExists: false,
      termsAndConditions: true,
      idpName: null,
      domainSSOLink: false,
      adminAssessmentToView: null
    };
  },
  watch: {
    async userEmail() {
      this.setEmail();
      if (this.reportPageSignUp) {
        await this.checkIfUserExists();
      }
    },
    errorMsg(val) {
      if (val) {
        this.errorMessage = val;
      }
    },
    email(val) {
      this.debouncedSSODomainSearch(val);
    }
  },
  async mounted() {
    EventBus.$on("signInWithEmail", this.signInWithEmail);
  },
  async created() {
    try {
      this.loadingComponent = true;
      this.setEmail();
      if (this.reportPageSignUp) {
        await this.checkIfUserExists();
      }

      var queryString = getUrlSearchParameters(window.location.href);
      if (!queryString) {
        return;
      }

      let teamId = queryString.get("teamid") ?? this.teamId;
      let autoJoinTeamId = queryString.get("autojointeamid");
      let assessmentType = queryString.get("assessmenttype");
      this.idpName = queryString.get("idp");
      this.adminAssessmentToView = queryString.get("admin-assessment-to-view");
      this.setAdminAssessmentToView(this.adminAssessmentToView);
      setUpRedirectPage(queryString);
      setUpRedirectActions(queryString);

      if (teamId) {
        await UserpoolService.setUserpoolSettingsFromId(teamId);
      }

      if (teamId && this.idpName) {
        await this.signInWithEnterpriseSSO();
      } else if (autoJoinTeamId && assessmentType) {
        let route = await LoginFeatureService.tryBypassLogin(
          autoJoinTeamId,
          assessmentType
        );
        if (!!route) {
          await this.$router.push(route);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      this.loadingComponent = false;
    }
  },
  beforeDestroy() {
    EventBus.$off("signInWithEmail");
  },
  computed: {
    ...mapGetters([
      "userEmail",
      "teamIdpName",
      "teamId",
      "redirectReportAssessment"
    ]),
    showSocialSignOn() {
      return (
        !this.reportPageSignUp &&
        !this.autoJoinTeamId &&
        !this.isReturningToPartialAssessment &&
        !this.domainSSOLink
      );
    },
    autoJoinDisabledByTeam() {
      return this.autoJoinTeam && !this.autoJoinTeamInfo.allowShareableLink;
    },
    autoJoinTeam() {
      return this.autoJoinTeamId && this.autoJoinTeamInfo;
    },
    titleText() {
      if (!this.signUp) {
        return this.$t("authentication.loginOptions.login");
      }

      if (this.simpleUserSignup) {
        return this.$t("authentication.loginOptions.joinTeam", {
          teamName: this.autoJoinTeam
            ? this.autoJoinTeamInfo.name
            : this.$t("authentication.loginOptions.yourTeam")
        });
      }

      return this.$t("authentication.loginOptions.createAccount");
    },
    emailRules() {
      let rulesToReturn = this.signUp
        ? [this.rules.emailRequiredSignup, this.rules.workEmail]
        : [this.rules.emailRequired, this.rules.emailValid];

      if (
        this.signUp &&
        this.autoJoinTeamInfo &&
        this.autoJoinTeamInfo.domainNames.length > 0
      ) {
        const domainRule = v =>
          DomainService.emailMatchesAnyDomain(
            v,
            this.autoJoinTeamInfo.domainNames
          ) || this.domainNamesAsString;
        rulesToReturn.push(domainRule);
      }
      return rulesToReturn;
    },
    passwordRules() {
      let rules = this.rules;
      let loginRules = [rules.passwordRequired];
      let signUpRules = [
        rules.passwordRequired,
        rules.passwordAtLeast8Characters,
        rules.passwordContainsNumber,
        rules.passwordContainsSpecialCharacter,
        rules.passwordContainsBothCases
      ];
      return this.signUp ? signUpRules : loginRules;
    },
    columnWidth() {
      if (this.$vuetify.breakpoint.xs || this.reportPageSignUp) {
        return 12;
      }

      return this.signUp ? 8 : 10;
    },
    ssoLoginCustomisation() {
      let idpName = this.idpName ?? this.ssoIdpName;
      return getSSOCustomisation(idpName);
    },
    showSignupButton() {
      // we want to show sign up button on final step (password) of regular sign up or on report page sign up
      return this.regularSignUpStep == 2 || this.reportPageSignUp;
    },
    regularSignUpFirstStep() {
      // return true if this is regular sign up and we are on the first step (email)
      return this.regularSignUpStep == 1 && !this.reportPageOrLoginPage;
    },
    showEmailField() {
      if (this.reportPageSignUp && this.userEmail) {
        return false;
      }
      return this.regularSignUpStep == 1 || this.reportPageOrLoginPage;
    },
    showPasswordField() {
      return (
        (this.regularSignUpStep == 2 || this.reportPageOrLoginPage) &&
        !this.domainSSOLink
      );
    },
    showResetPasswordField() {
      return !this.signUp && !this.domainSSOLink;
    },
    reportPageOrLoginPage() {
      return this.reportPageSignUp || !this.signUp;
    },
    signUpButtonText() {
      if (this.autoJoinTeamId) {
        return this.$t("authentication.loginOptions.enter");
      }

      return this.reportPageSignUp
        ? this.$t("authentication.loginOptions.viewReport")
        : this.$t("authentication.loginOptions.signUp");
    },
    loginButtonText() {
      if (this.autoJoinTeamId) {
        return this.$t("authentication.loginOptions.continue");
      }

      var text = !!this.domainSSOLink ? "continueWithSSO" : "continueWithEmail";
      return this.$t(`authentication.loginOptions.${text}`);
    },
    domainNamesAsString() {
      if (
        !this.autoJoinTeamInfo ||
        this.autoJoinTeamInfo.domainNames.length == 0
      ) {
        return "";
      }

      let text = this.autoJoinTeamInfo.domainNames.join(", ");
      return this.$tc(
        "textInputRules.matchingDomainName",
        this.autoJoinTeamInfo.domainNames.length,
        { domains: text }
      );
    },
    loading() {
      return this.checkingIfUserExists || this.loadingComponent;
    }
  },
  methods: {
    ...mapMutations([
      "setUserData",
      "setRedirectAssessment",
      "setAdminAssessmentToView"
    ]),

    debouncedSSODomainSearch: debounce(function (val) {
      this.SSODomainSearch(val);
    }, 200),
    SSODomainSearch(val) {
      domainMatchesSSOProvider({ emailAddress: { value: val } })
        .then(result => {
          if (result) {
            this.domainSSOLink = result.teamIdpLink;
          } else {
            this.domainSSOLink = null;
          }
        })
        .catch(e => {
          this.domainSSOLink = null;
        });
    },
    keyDownEvent(event) {
      // required as landing on page fires off key down event due to autocomplete
      if (event.key) {
        this.resetError();
      }
    },
    async confirmLogin() {
      if (this.confirmLoading) return;

      try {
        this.confirmLoading = true;
        await this.confirm();
      } catch (err) {
        this.confirmLoading = false;
      } finally {
        this.confirmLoading = false;
      }
    },
    async confirm() {
      if (this.domainSSOLink) {
        await this.signInWithEnterpriseSSO(this.domainSSOLink);
        return;
      }

      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      // extra check here in case email didn't exist when component created - allows for manually entered emails
      if (!this.userExists && this.reportPageSignUp) {
        await this.checkIfUserExists();
      }

      if (!this.signUp) {
        var userEmailDto = { emailAddress: { value: this.email } };
        var externalProviderInfo = await isFromExternalProvider(userEmailDto);

        if (!externalProviderInfo.canSignInWithEmail) {
          if (externalProviderInfo.teamIdpLink) {
            this.customSSOSignInError = {
              text: "authentication.useSSOLogin",
              linkText: "authentication.pageLink",
              link: externalProviderInfo.teamIdpLink
            };
            return;
          }

          this.errorMessage = this.$t("authentication.useSocialLogin");
          return;
        }
      }

      if (!this.signUp || this.userExists) {
        await this.signInWithEmail();
        return;
      }

      // if first step of regular sign up, move onto step 2.
      if (this.regularSignUpFirstStep) {
        this.regularSignUpStep = 2;
        this.$emit("setInitialSignupStep", false);
        return;
      }
      await this.completeSignUp();
    },
    async signInWithEmail() {
      this.resetError();
      try {
        let user = await Auth.signIn(this.email, this.password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.$router.push({
            name: "InvitationSignUp",
            params: { signInEmail: this.email, signedInUser: user }
          });
        } else {
          // Ensure user has been set to confirm join this team if on report page
          if (this.reportPageSignUp || this.isReturningToPartialAssessment) {
            try {
              var endUserDto = {
                email: { value: this.email }
              };
              if (!this.userExists) {
                await confirmEndUserSignUp(endUserDto);
              }
              this.$emit("regularSignInComplete");
            } catch (e) {
              if (
                e.message &&
                e.message.includes(
                  "Could not find EndUser with associated team"
                )
              ) {
                this.errorMessage = this.$t("authentication.invalidEmailMatch");
                this.$emit("error");
                return;
              }
            }
          } else if (this.autoJoinTeam) {
            // user has been set to confirm join a team when coming through sign up link
            try {
              await joinEndUserToTeamViaSignupLink(this.autoJoinTeamId);
            } catch (e) {
              if (
                this.autoJoinTeamInfo &&
                this.autoJoinTeamInfo.domainNames.length > 0
              ) {
                this.errorMessage = this.$t(
                  "authentication.failedToAutoJoinTeamWithDomains"
                );
              } else {
                this.errorMessage = this.$t(
                  "authentication.failedToAutoJoinTeam"
                );
              }
              return;
            }
          }
          let teamMemberInfo = await userTeamMemberInformation();
          this.setUserData(teamMemberInfo);
          if (this.reportPageSignUp) {
            await completeUserSignInWithoutRedirect(this.$store);
          } else {
            var queryString = getUrlSearchParameters(window.location.href);
            if (queryString) {
              let teamId = queryString.get("autojointeamid");
              let assessmentType = queryString.get("assessmenttype");
              if (assessmentType && teamId) {
                let invitation = {
                  email: { value: this.email },
                  assessmentType: assessmentType
                };
                let assessmentId = await startNewAssessment(invitation, teamId);
                this.setRedirectAssessment({
                  id: assessmentId,
                  assessmentType: assessmentType,
                  results: {}
                });
              }
            }
            await completeUserSignIn(this.$store, this.$router);
          }
        }
      } catch (err) {
        //  eslint-disable-next-line no-debugger
        if (err.code === "UserNotConfirmedException") {
          this.$emit("confirmSignUp", {
            email: this.email,
            password: this.password
          });
          return;
        }
        if (err.code === "PasswordResetRequiredException") {
          this.$router.push("ResetPassword");
          return;
        }
        if (
          err.message &&
          err.message.includes("Could not find EndUser with associated team")
        ) {
          this.errorMessage = this.$t("authentication.invalidEmailMatch");
          return;
        }
        if (err.code === "NotAuthorizedException") {
          this.errorMessage = this.$t(
            "authentication.incorrectUserNameOrPassword"
          );
          return;
        }

        this.errorMessage = this.$t("authentication.genericErrorMessage");
        this.$logger.captureException(err);
        this.signOut();
      }
    },
    async completeSignUp() {
      this.$gtag.event("Sign up dialog - submit email and password", {
        event_category: this.reportPageSignUp
          ? "Desk Assessment - Report"
          : "Main SignUp"
      });
      let attributes = {
        email: this.email
      };

      try {
        var result = await Auth.signUp({
          username: this.email,
          password: this.password,
          attributes: attributes,
          validationData: {
            reportSignUp: this.reportPageSignUp ? "true" : "false", // used in pre sign up lambda
            teamId: this.teamId
          }
        });
        if (!result.userConfirmed) {
          if (
            this.reportPageSignUp &&
            import.meta.env.VITE_NODE_ENV !== "local"
          ) {
            this.$logger.captureMessage("Auto sign up failed", "error");
          }
          this.$emit("confirmSignUp", {
            email: this.email,
            password: this.password
          });
        } else {
          await this.signInWithEmail();
        }
      } catch (err) {
        if (err.code === "UsernameExistsException") {
          await this.signInWithEmail();
          return;
        }
        this.errorMessage = this.$t("authentication.genericErrorMessage");
        this.$logger.captureException(err);
      }
    },
    async signInWithFederation(provider) {
      try {
        // Set origin and sign up flag for login redirect
        this.setOrigin();
        this.storeAdminAssessmentToView();
        saveRedirectPageToSessionStorage();
        saveActionToSessionStorage();
        sessionStorage.setItem("federatedProvider", provider);
        if (this.signUp) {
          sessionStorage.setItem("signUp", true);
        }
        if (this.reportPageSignUp && !this.userExists) {
          sessionStorage.setItem("ssoEndUserSignedUp", true);
        }
        const cred = await Auth.federatedSignIn({ provider: provider });
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async signInWithEnterpriseSSO(specificUrl) {
      // if user is already logged in return - if not carry on
      try {
        var user = await Auth.currentAuthenticatedUser();

        // remove all search parameters from url in address bar
        removeUrlParameters(window.location.href);

        return;
      } catch (ex) {
        // capture exception for not authenticated user as Auth.currentAuthenticatedUser() throws instead of return null.
        if (ex != "The user is not authenticated") {
          throw ex;
        }
      }

      let teamId = sessionStorage.getItem("teamId");
      let idpName = sessionStorage.getItem("idpName");
      let assessmentType = null;
      let newAssessment = null;

      var url = specificUrl ?? window.location.href;
      var queryString = getUrlSearchParameters(url);
      if (queryString) {
        teamId = queryString.get("teamid");
        idpName = queryString.get("idp");
        assessmentType = queryString.get("assessmenttype");
        newAssessment = queryString.get("new-assessment");
        if (assessmentType) {
          newAssessment = true;
        }
      }
      teamId = teamId ?? this.teamId ?? this.ssoTeamId;
      idpName = idpName ?? this.idpName ?? this.ssoIdpName;

      if (
        !this.userExists &&
        (this.reportPageSignUp || this.isReturningToPartialAssessment)
      ) {
        sessionStorage.setItem("ssoEndUserSignedUp", true);
      }

      try {
        if (this.redirectReportAssessment) {
          sessionStorage.setItem(
            "reportAssessment",
            JSON.stringify(this.redirectReportAssessment)
          );
        }
        sessionStorage.setItem("teamId", teamId);
        sessionStorage.setItem("idpName", idpName);
        if (newAssessment) {
          sessionStorage.setItem("newAssessment", newAssessment);
        }
        if (assessmentType) {
          sessionStorage.setItem("assessmentType", assessmentType);
        }
        this.storeAdminAssessmentToView();
        saveRedirectPageToSessionStorage();
        saveActionToSessionStorage();

        var customUserpoolSettings = JSON.parse(
          localStorage.getItem("customUserpoolSettings")
        );

        var userpoolDomain =
          customUserpoolSettings?.userpoolDomain ??
          import.meta.env.VITE_APP_USER_POOL_DOMAIN;
        var appClientId =
          customUserpoolSettings?.appClientId ??
          import.meta.env.VITE_APP_AWS_USER_POOLS_WEB_CLIENT_ID;

        window.location.href =
          "https://" +
          userpoolDomain +
          "/oauth2/authorize?identity_provider=" +
          idpName +
          "&redirect_uri=" +
          import.meta.env.VITE_APP_REDIRECT_URL +
          "&response_type=CODE&client_id=" +
          appClientId +
          "&scope=aws.cognito.signin.user.admin email openid phone profile";
      } catch (err) {
        this.errorMessage = err.message;
        this.$logger.captureException(err);
      }
    },
    setOrigin() {
      let url = window.location.href;
      let origin = url.includes("?") ? url.split("?")[0] : url;
      sessionStorage.setItem("originURL", origin);
    },
    storeAdminAssessmentToView() {
      if (this.adminAssessmentToView) {
        sessionStorage.setItem(
          "adminAssessmentToView",
          this.adminAssessmentToView
        );
      }
    },
    resetError() {
      this.errorMessage = "";
      this.customSSOSignInError = null;
    },
    signOut() {
      this.setUserData(null);
      Auth.signOut();
    },
    redirect() {
      let destination = this.signUp ? "/" : "SignUp";
      this.$router.push(destination);
    },
    openedTermsAndConditions() {
      this.$gtag.event("Sign up dialog - Opened terms and conditions", {
        event_category: "VIDA - Login/Signup page"
      });
    },
    setEmail() {
      if (this.userEmail) {
        this.email = this.userEmail;
        if (this.isReturningToPartialAssessment || this.reportPageSignUp) {
          this.disableEmailField = true;
        }
      }
    },
    async checkIfUserExists() {
      if (!this.email) {
        return;
      }

      try {
        this.checkingIfUserExists = true;
        var userEmailDto = { emailAddress: { value: this.email } };
        this.userExists = await authUserExists(userEmailDto);
        this.$emit("user-exists", this.userExists);
      } catch (err) {
        this.$emit("user-exists", false);
      } finally {
        this.checkingIfUserExists = false;
      }
    },
    openTermsOfService() {
      window.open("https://vitrueremote.com/terms-of-service/", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-messages__message {
  hyphens: none !important;
}

::v-deep .v-input {
  .v-input__control {
    .v-input__slot {
      &:has(.v-text-field__slot) {
        margin-bottom: 4px;
        padding: 0 16px !important;
      }

      fieldset {
        border-radius: 8px;
      }
    }
    .v-text-field__details {
      padding-left: 0;
    }

    .v-messages {
      text-align: right;
    }
  }
}
</style>
