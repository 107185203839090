import i18n from "@/plugins/i18n";
import mixpanel from "mixpanel-browser";
import store from "@/store";
import router from "@/router";

import { markNotificationsAsSelected } from "@/customApi";

export {
  NotificationBehaviour,
  GroupNotifications,
  ExtractNotificationEntities
};

const NotificationBehaviour = {
  CompletedAssessment: {
    icon: "laptop_chromebook",
    text: (name, length) => {
      return i18n.t("notificationsBadge.completedAssessment", {
        name: name
      });
    },
    onClick: notificationGroup => {
      SelectAssessmentNotification(notificationGroup);
    }
  },
  CompletedRecommendation: {
    icon: "mdi-check",
    text: (name, length) => {
      return i18n.tc(`notificationsBadge.completedRecommendation`, length, {
        name: name,
        number: length
      });
    },
    onClick: notificationGroup => {
      if (!store.getters.isRestrictedAdminUser) {
        store.commit(
          "setRecommendationsToViewInReport",
          notificationGroup.notifications.map(x => x.recommendationName)
        );
      }
      SelectAssessmentNotification(notificationGroup);
    }
  }
};

// Group the item by type, assessmentId and if selected
function GroupNotifications(notifications) {
  var groupedItems = [];
  notifications.forEach(apiModel => {
    var currentGroup = groupedItems.find(
      x =>
        x.type == apiModel.notification.type &&
        x.assessmentId == apiModel.notification.assessmentId &&
        x.selected == !!apiModel.notification.selectedAt
    );
    if (!currentGroup) {
      currentGroup = {
        type: apiModel.notification.type,
        assessmentId: apiModel.notification.assessmentId,
        selected: !!apiModel.notification.selectedAt,
        name: apiModel.nameOfUserWhoTriggered,
        notifications: []
      };
      groupedItems.push(currentGroup);
    }

    currentGroup.notifications.push(apiModel.notification);
  });
  return groupedItems;
}

function ExtractNotificationEntities(groupedNotifications) {
  const notificationArrays = groupedNotifications.map(x => x.notifications);
  return notificationArrays.flat();
}

async function SelectAssessmentNotification(notificationGroup) {
  store.commit("setAdminAssessmentToView", notificationGroup.assessmentId);
  await markNotificationsAsSelected(notificationGroup.notifications);
  notificationGroup.selected = true;
  mixpanel.track(`Notification marked as selected`);
  if (router.currentRoute.name !== "Dashboard") {
    router.push("/dashboard");
  }
}
