<template>
  <div>
    <v-form
      class="d-flex align-start"
      style="gap: 0.5rem"
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="handleSearch"
    >
      <v-text-field
        v-model="query"
        placeholder="Ask anything about your team"
        dense
        outlined
        clearable
        hide-details="auto"
        :counter="130"
        :rules="queryRules"
        color="var(--v-vflPrimary-base)"
        class="ai-search-input"
      >
        <template v-slot:prepend-inner>
          <img src="/images/icons/search-ai.svg" />
        </template>
      </v-text-field>
      <button
        class="vfl-button-ai is-medium mb-4"
        :class="{ 'is-busy': loading }"
        @click="handleSearch"
        type="button"
      >
        {{ loading ? "Stop" : "Search" }}
      </button>
      <button
        class="vfl-button-link is-medium ml-1 mb-0 mt-2"
        :class="{ 'is-disabled': !canReset }"
        @click="onReset"
        type="button"
      >
        Reset
      </button>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "AiSearch",
  props: {
    loading: Boolean,
    canReset: Boolean
  },
  data() {
    return {
      valid: true,
      query: "",
      queryRules: [v => (v || "").length <= 130]
    };
  },
  methods: {
    handleSearch() {
      if (this.loading) {
        this.$emit("abort");
      } else {
        this.search();
      }
    },
    search() {
      this.$refs.form.validate();

      if (!this.query || !this.valid) return;

      if (this.query) {
        this.$emit("onSearch", this.query);
      }
    },
    forceSearch(query) {
      this.query = query;
      this.search();
    },
    onReset() {
      this.clearQuery();
      this.$emit("onReset");
    },
    clearQuery() {
      this.query = "";
    }
  }
};
</script>
<style lang="scss" scoped>
.ai-search-input {
  border-radius: 20px;
}

.vfl-button-ai {
  min-height: 40px;
  min-width: 110px;

  &.is-busy {
    background-image: linear-gradient(120deg, #d2dbfa, #e8cff6 30%, #d2dbfa);
    color: var(--v-vflPrimary-base);
  }
}
</style>
