export const BurnoutRisk = {
  LOW: 'lowRisk',
  AVERAGE: 'averageRisk',
  HIGH: 'highRisk',
  VERY_HIGH: 'veryHighRisk',
}

export const BurnoutLevel = {
  LOW: 'low',
  AVERAGE: 'average',
  HIGH: 'high',
  VERY_HIGH: 'veryHigh',
}

export const ResilienceLevel = {
  NEEDS_ATTENTION: 'needsAttention',
  ROOM_FOR_IMPROVEMENT: 'roomForImprovement',
  AVERAGE: 'average',
  HIGH: 'high'
}

export const ResilienceCategoryLevel = {
  VERY_LOW: 'veryLow',
  LOW: 'low',
  AVERAGE: 'average',
  HIGH: 'high',
}

// For resilience, we reverse the stops for burnout: [0, 15, 35, 58, 100];
export const RESILIENCE_SPEEDOMETER_STOPS = [0, 42, 65, 85, 100];

export const ResilienceColor = {
  RED: 'vitrueRed',
  YELLOW: 'vitrueYellow',
  GREEN: 'vitrueBrightGreen',
  GREEN_DARK: 'vitrueDarkGreen'
}

export const BURNOUT_CATEGORIES = [
  "exhaustion",
  "cognitiveimpairment",
  "emotionalimpairment",
  "mentaldistance",
  "secondarysymptoms"
]

export const BurnoutToResilienceCategoriesMap = {
  exhaustion: "energyLevel",
  cognitiveimpairment: "mentalClarity",
  emotionalimpairment: "emotionalWellbeing",
  mentaldistance: "mentalEngagement",
  secondarysymptoms: "physicalWellbeing"
}

export const BurnoutToResilienceMap = {
  [BurnoutLevel.LOW]: {
    level: ResilienceLevel.HIGH,
    color: ResilienceColor.GREEN_DARK,
    categoryLevel: ResilienceCategoryLevel.HIGH
  },
  [BurnoutLevel.AVERAGE]: {
    level: ResilienceLevel.AVERAGE,
    color: ResilienceColor.GREEN,
    categoryLevel: ResilienceCategoryLevel.AVERAGE
  },
  [BurnoutLevel.HIGH]: {
    level: ResilienceLevel.ROOM_FOR_IMPROVEMENT,
    color: ResilienceColor.YELLOW,
    categoryLevel: ResilienceCategoryLevel.LOW
  },
  [BurnoutLevel.VERY_HIGH]: {
    level: ResilienceLevel.NEEDS_ATTENTION,
    color: ResilienceColor.RED,
    categoryLevel: ResilienceCategoryLevel.VERY_LOW
  }
};