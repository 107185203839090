<template>
  <vfl-panel>
    <template v-slot:header
      ><v-row class="px-3" justify="space-between">
        <p
          class="text-left vitrueDarkGrey--text py-0 my-0 pr-5"
          v-text="title"
        ></p>
        <p
          class="text-right text-h6 py-0 my-0 pr-5 font-weight-bold header-text"
          v-bind:class="[goodScore === true ? 'green--text' : 'red--text']"
          v-text="score"
        ></p> </v-row
    ></template>
    <template v-slot:content> <slot name="content"></slot></template>
  </vfl-panel>
</template>

<script>
import VflPanel from "@/components/vitrue-foundation-library/expansion-panel/VflPanel.vue";
export default {
  name: "assessment-report-panel",
  components: {
    VflPanel
  },
  props: {
    title: String,
    score: Number,
    goodScore: Boolean
  }
};
</script>
