import RestApiClient from "@/infrastructure/restApiClient";
import WebSocketClient from "@/infrastructure/webSocketClient";
import { AbortError } from "@/infrastructure/restErrors";
const assistantRestApiPath = "/api/assistant";
const assistantWebSocketRoute = "assistant";
const assistantWebSocketPath = "/assistant/assessment";
const assessmentHistoryAssistantWebSocketRoute = "assistant";
const assessmentHistoryAssistantWebSocketPath = "/assistant/history";
const strategyAssistantWebSocketRoute = "assistant";
const strategyAssistantWebSocketPath = "/assistant/strategy";

export async function giveAssistantInteractionFeedbackMessage(
  interactionId,
  feedback
) {
  const path = `${assistantRestApiPath}/feedback/${interactionId}`;
  const body = feedback;
  return await RestApiClient.post(path, body);
}

export async function getAssessmentConversations(assessmentId) {
  const path = `${assistantRestApiPath}/${assessmentId}`;
  return await RestApiClient.get(path);
}

export async function sendAssessmentAssistantMessage(
  query,
  settings,
  deltaUpdate,
  signal
) {
  const body = { query: query, searchSettings: settings };
  return await createWebsocket(
    body,
    assessmentHistoryAssistantWebSocketRoute,
    assessmentHistoryAssistantWebSocketPath,
    deltaUpdate,
    signal
  );
}

export async function sendStrategyAssistantMessage(
  conversationId,
  message,
  deltaUpdate,
  signal
) {
  const body = new RequestApiModel(null, conversationId, message, null);
  return await createWebsocket(
    body,
    strategyAssistantWebSocketRoute,
    strategyAssistantWebSocketPath,
    deltaUpdate,
    signal
  );
}

export async function sendAssistantMessage(
  assessmentId,
  conversationId,
  message,
  context,
  signal,
  deltaUpdate
) {
  const body = new RequestApiModel(
    assessmentId,
    conversationId,
    message,
    context
  );
  return await createWebsocket(
    body,
    assistantWebSocketRoute,
    assistantWebSocketPath,
    deltaUpdate,
    signal
  );
}

async function createWebsocket(query, route, path, updateFunction, signal) {
  const webSocket = await WebSocketClient.websocket(route, path, query);
  let buffer = '';
  return new Promise(async (resolve, reject) => {
    webSocket.onopen = () => {
      webSocket.send(JSON.stringify(query));
    };
    webSocket.onmessage = async event => {
      buffer += event.data;
      let messageBody;
      try {
        messageBody = JSON.parse(buffer);
      } catch (e) {
        //Received partial json buffer. :fingers_crossed:
        return;
      }
      buffer = '';
      await updateFunction(messageBody);
      if (messageBody.isFinished) {
        resolve();
      }
    };
    webSocket.onerror = error => {
      reject(error);
    };

    if (signal) {
      signal.addEventListener(
        "abort",
        () => {
          webSocket.close();
          reject(new AbortError());
        },
        { once: true }
      );
    }
  });
}

class RequestApiModel {
  constructor(
    assessmentId,
    conversationId,
    message,
    context,
    requestType = "UserRequest"
  ) {
    this.assessmentId = assessmentId;
    this.sessionId = conversationId;
    this.message = message;
    this.context = context;
    this.requestType = requestType;
  }
}
