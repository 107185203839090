<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-combobox
        v-model="dates"
        style="max-width: 300px"
        class="pt-0"
        multiple
        :label="
          $t('dashboardHistoryComponents.assessmentHistoryTable.labels.dates')
        "
        readonly
        v-bind="attrs"
        v-on="on"
        hide-details
        disable-lookup
        @click:append="menu = !menu"
        color="var(--v-vflPrimary-base)"
      >
        <template v-slot:selection="{ item }">
          <v-chip x-small color="grey" dark @click="menu = !menu">{{
            item
          }}</v-chip>
        </template>
      </v-combobox>
    </template>
    <v-date-picker v-model="dates" range no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="clear">
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn text color="primary" @click="save">
        {{ $t("buttons.add") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: Array
  },
  data() {
    return {
      dates: this.value,
      menu: false
    };
  },
  methods: {
    save() {
      this.orderDates();
      this.$refs.menu.save(this.dates);
      this.$emit("input", this.dates);
    },
    clear() {
      this.dates = [];
      this.menu = false;
      this.$refs.menu.save(this.dates);
      this.$emit("input", this.dates);
    },
    orderDates() {
      if (this.dates.length === 0) {
        return;
      }

      if (new Date(this.dates[0]) > new Date(this.dates[1])) {
        this.dates.reverse();
      }
    }
  }
};
</script>
