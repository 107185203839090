<template>
  <div class="pills-group" role="group">
    <label
      v-for="option in options"
      :key="option.value"
      class="pill"
      :class="{ 'pill-selected': isSelected(option.value) }"
    >
      <input
        type="checkbox"
        :value="option.value"
        :checked="isSelected(option.value)"
        @change="toggleOption(option.value)"
      />
      <span>{{ option.label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckBoxPillsQuestion",
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedInternal: [...this.value]
    };
  },
  methods: {
    isSelected(value) {
      return this.selectedInternal.includes(value);
    },
    toggleOption(value) {
      const index = this.selectedInternal.indexOf(value);
      if (index === -1) {
        this.selectedInternal.push(value);
      } else {
        this.selectedInternal.splice(index, 1);
      }
      this.$emit("input", [...this.selectedInternal]);
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedInternal = [...newVal];
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.pills-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.pill {
  background-color: transparent;
  border: 1px solid #d0d0d0;
  border-radius: 999px;
  padding: 0.35rem 1.5rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--v-vflBlueVeryLight-base);
  }

  &:focus-within {
    outline: 1px solid var(--v-vflPrimary-base);
    outline-offset: -1px;
  }
}

/* Removes the checkbox tick */
.pill input[type="checkbox"] {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.pill-selected {
  background-color: var(--v-vflBlueVeryLight-base);
  color: var(--v-vflBlueVeryLight-base);
  border-color: var(--v-vflPrimary-base);
}
</style>
