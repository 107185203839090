<template>
  <v-app-bar
    :app="$vuetify.breakpoint.smAndUp"
    :clipped-left="$vuetify.breakpoint.smAndUp"
    color="white"
    :style="{
      flexGrow: $vuetify.breakpoint.smAndUp ? '1' : '0'
    }"
    ref="topbar"
  >
    <v-app-bar-nav-icon
      v-show="showSidebarBurgerMenu"
      @click.stop="$emit('input', !value)"
    />

    <team-logo-and-name v-if="showTeamDetails" :logoSize="logoSize" />
    <img
      v-else
      class="m2-4"
      src="/images/vida-logo-full.svg"
      style="width: 160px"
    />

    <v-spacer></v-spacer>

    <v-row align="center" justify="end" no-gutters style="height: 100%">
      <v-col cols="auto"><FeatureFlagSelector /></v-col>

      <v-col cols="auto" v-if="showIconTray">
        <notifications-badge />
      </v-col>
      <v-col v-if="showIconTray" cols="auto" class="mx-4" style="height: 75%"
        ><div class="vertical-bar bar-style" />
      </v-col>
      <v-col v-if="teamName" cols="auto">
        <img
          v-if="$vuetify.breakpoint.mdAndUp"
          src="/images/powered-by-vida.svg"
          alt="Powered by Vitrue VIDA"
          class="logo-big-screen"
        />
        <img v-else src="/images/vida-logo.svg" class="logo-small-screen" />
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import FeatureFlagSelector from "@/components/internal/FeatureFlagSelector.vue";
import TeamLogoAndName from "./TeamLogoAndName.vue";
import NotificationsBadge from "@/components/common/NotificationBadge.vue";
import { routes } from "@/router/route-constants.js";
import debounce from "lodash/debounce";

const TOPBAR_CSS_VAR = "--topbar-height";

export default {
  name: "top-bar",
  components: {
    FeatureFlagSelector,
    TeamLogoAndName,
    NotificationsBadge
  },
  props: {
    value: Boolean,
    showSideBar: Boolean
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "appLoading",
      "demoFeatures",
      "teamName",
      "isAdminUser"
    ]),
    showSidebarBurgerMenu() {
      return this.showSideBar && this.$vuetify.breakpoint.smAndDown;
    },
    showIconTray() {
      return this.signedIn && this.isAdminUser;
    },
    logoSize() {
      return this.$vuetify.breakpoint.mdAndUp ? "48" : "40";
    },
    showTeamDetails() {
      return (
        this.signedIn &&
        this.$route.name &&
        this.$route.name !== routes.CREATE_TEAM &&
        this.$route.name !== routes.JOIN_TEAM &&
        this.$route.name !== routes.CHOOSE_TEAM
      );
    }
  },
  mounted() {
    this.setCssTopbarHeightVariable();
    this.addEventListeners();
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  methods: {
    setCssTopbarHeightVariable() {
      if (this.$refs.topbar?.$el) {
        const { offsetHeight } = this.$refs.topbar.$el;

        document.documentElement.style.setProperty(
          TOPBAR_CSS_VAR,
          `${offsetHeight}px`
        );
      }
    },
    debouncedResizeListener: debounce(function () {
      this.setCssTopbarHeightVariable();
    }, 200),
    addEventListeners() {
      window.addEventListener("resize", this.debouncedResizeListener);
    },
    removeEventListeners() {
      window.removeEventListener("resize", this.debouncedResizeListener);
    }
  }
};
</script>

<style scoped lang="scss">
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 0 0 1px hsl(0, 0%, 90%);
}

.bar-style {
  width: 1px;
  background-color: hsl(0, 0%, 90%);
}
[class^="logo"] {
  display: block;
  height: auto;
}

.logo-big-screen {
  width: 120px;
}

.logo-small-screen {
  width: 28px;
}
</style>
