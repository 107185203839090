import { ASSESSMENT_HISTORY_STATES } from "@/components/user-profile/history/constants.js";
import store from "@/store.js";

function getFormattedLocation(location) {
  if (location === "None") return "";
  if (location === "NotAtUsualWorkspace") return " [Other]";
  return ` [${location}]`;
}

export const getAssessmentEventText = item => {
  switch (item.historyState) {
    case ASSESSMENT_HISTORY_STATES.COMPLETED:
      const canViewScore = !store.getters.anonymousResults;

      if (item.score !== null && item.location && canViewScore) {
        const location = getFormattedLocation(item.location);
        return `Scored ${item.score}% on ${item.assessmentType}${location}`;
      } else {
        return `${item.assessmentType} completed`;
      }
    case ASSESSMENT_HISTORY_STATES.IN_PROGRESS:
      return `${item.assessmentType} started`;
    case ASSESSMENT_HISTORY_STATES.OPENED:
      return `${item.assessmentType} opened`;
    case ASSESSMENT_HISTORY_STATES.CREATED:
      return `${item.assessmentType} invite sent`;
    default:
      return `${item.assessmentType} status updated`;
  }
};
