const locationLocaleBase =
  "dashboardHistoryComponents.assessmentHistoryTable.assessmentLocations";
const locationCompletedAtBase =
  "dashboardHistoryComponents.userHistoryTable.completedAt";

export const AssessmentStateCodes = {
  Pending: 0,
  Opened: 1,
  InProgress: 10,
  Completed: 20,
  FailedToSendInvitation: 120,
  Failed: 150
};

export const Locations = {
  home: {
    intValue: 1,
    value: "Home",
    color: "primary",
    text: `${locationLocaleBase}.home`,
    completedAtText: `${locationCompletedAtBase}.home`,
    icon: "mdi-home"
  },
  office: {
    intValue: 2,
    value: "Office",
    color: "accent",
    text: `${locationLocaleBase}.office`,
    completedAtText: `${locationCompletedAtBase}.office`,
    icon: "mdi-office-building"
  },
  notatusualworkspace: {
    intValue: 3,
    value: "NotAtUsualWorkspace",
    color: "secondary",
    text: `${locationLocaleBase}.other`,
    completedAtText: `${locationCompletedAtBase}.other`,
    icon: "mdi-bag-personal"
  }
};

export const States = {
  [AssessmentStateCodes.Pending]: {
    value: AssessmentStateCodes.Pending,
    color: "red lighten-2",
    text: "statusCodes.0"
  },
  [AssessmentStateCodes.Opened]: {
    value: AssessmentStateCodes.Opened,
    color: "blue lighten-2",
    text: "statusCodes.1"
  },
  [AssessmentStateCodes.InProgress]: {
    value: AssessmentStateCodes.InProgress,
    color: "orange lighten-2",
    text: "statusCodes.10"
  },
  [AssessmentStateCodes.Completed]: {
    value: AssessmentStateCodes.Completed,
    color: "green lighten-2",
    text: "statusCodes.20"
  },
  [AssessmentStateCodes.FailedToSendInvitation]: {
    value: AssessmentStateCodes.FailedToSendInvitation,
    color: "vitrueDarkGrey",
    text: "statusCodes.120"
  },
  [AssessmentStateCodes.Failed]: {
    value: AssessmentStateCodes.Failed,
    color: "vitrueDarkGrey",
    text: "statusCodes.150"
  }
};
