<template>
  <div>
    <feature-introduction-card
      v-if="showAdminFeedbackPopup"
      featureKey="AdminDashboardFeedback"
      imageLink="/images/illustrations/feedback-popup.svg"
      :title="$t('featureIntroductions.adminDashboardFeedback.title')"
      :description="
        $t('featureIntroductions.adminDashboardFeedback.description')
      "
      :actionButtonText="
        $t('featureIntroductions.adminDashboardFeedback.button')
      "
      :customCompleteFunction="openFeedbackSurvey"
    />
    <total-seats-warning-bar
      id="dashboardSeatWarning"
      class="pa-6"
      showCloseButton
    />

    <div class="mx-10 mb-4" v-if="showAccountBanner">
      <account-banner v-model="showAccountBanner" />
    </div>
    <v-dialog v-if="webcamQuickAssess" v-model="webcamQuickAssess">
      <quick-assess type="webcamAnalysis" v-model="webcamQuickAssess" />
    </v-dialog>
    <v-dialog
      v-if="simsQuickAssess"
      v-model="simsQuickAssess"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <quick-assess type="deskSetup" v-model="simsQuickAssess" />
    </v-dialog>
    <v-dialog v-if="showWelcomeDialog" v-model="welcomeDialog">
      <AdminTour v-if="welcomeDialog" v-model="welcomeDialog" />
    </v-dialog>
    <v-dialog
      v-if="inviteDialog"
      v-model="inviteDialog"
      :width="inviteDialogWidth"
      persistent
      max-width="1020"
    >
      <invite-user-to-assessment-dialog
        v-if="inviteDialog"
        v-model="inviteDialog"
        :assessmentType="inviteAssessmentType"
        :preloadedUser="preloadedInviteUser"
        @preloadedUser="preloadedInviteUser = null"
      />
    </v-dialog>

    <dashboard-assessment-cards
      v-if="!deactivatedUser"
      :loading="loading"
      @onAssessmentCardClicked="onAssessmentCardClicked"
    />
    <dashboard-deactivated-user-banner v-else />

    <v-row
      no-gutters
      :class="deactivatedUser ? 'mt-4' : 'mt-4'"
      justify="center"
      ><analytics-table ref="analyticsTable" class="ma-0" />
    </v-row>

    <div :class="$vuetify.breakpoint.xs ? 'px-4' : 'px-8'" class="mt-10">
      <user-history-table
        ref="userHistory"
        @sendInvite="showInviteWithPreloadedUser($event)"
      />
    </div>

    <AiAssistantContainer
      v-if="showDashboardAssistant"
      :type="$options.assistantTypes.STRATEGY"
    />
  </div>
</template>

<script>
import AccountBanner from "@/components/common/AccountBanner.vue";
import DashboardAssessmentCards from "@/components/dashboard/assessment-cards/DashboardAssessmentCards.vue";
import DashboardDeactivatedUserBanner from "@/components/dashboard/DashboardDeactivatedUserBanner.vue";
const InviteUserToAssessmentDialog = () =>
  import("./InviteDialog/InviteUserToAssessmentDialog.vue");
import UserHistoryTable from "@/components/common/assessment-history/UserHistoryTable.vue";
import AnalyticsTable from "./analytics/AnalyticsTable.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getTeamMember } from "@/customApi";
import { Auth } from "aws-amplify";
import AdminTour from "@/components/account/onboarding/AdminTour.vue";
import { updateCheckListFromFeatures } from "@/services/onboarding/adminOnboarding";
import TeamService from "@/services/teamService.js";
import FeatureService from "@/services/new-feature-service.js";
import QuickAssess from "@/components/dashboard/QuickAssess.vue";
import TotalSeatsWarningBar from "@/components/dashboard/TotalSeatsWarningBar.vue";
import * as Constants from "@/constants/constants.js";
import NewFeatureService from "@/services/new-feature-service.js";
import FeatureIntroductionCard from "@/components/common/FeatureIntroductionCard.vue";
import AiAssistantContainer from "@/components/AiAssistant/AiAssistantContainer.vue";
import { AssistantTypes } from "@/components/AiAssistant/constants.js";

export default {
  name: "Dashboard",
  components: {
    QuickAssess,
    AccountBanner,
    DashboardAssessmentCards,
    AnalyticsTable,
    InviteUserToAssessmentDialog,
    AdminTour,
    FeatureIntroductionCard,
    TotalSeatsWarningBar,
    UserHistoryTable,
    DashboardDeactivatedUserBanner,
    AiAssistantContainer
  },
  data() {
    return {
      welcomeDialog: true,
      incompleteAssessment: {},
      loading: true,
      simsQuickAssess: false,
      webcamQuickAssess: false,
      showAccountBanner: false,
      preloadedInviteUser: null,
      assessmentCardClicked: ""
    };
  },
  assistantTypes: AssistantTypes,
  computed: {
    ...mapGetters([
      "currentUserTeamAndSubscriptionBasicInformation",
      "userEmail",
      "teamMemberId",
      "burnout",
      "deactivatedUser",
      "featureIntros",
      "isTrialUser",
      "subscriptionHasExpired",
      "modalOpen",
      "driverAssessmentEnabled",
      "enableV3Assistant"
    ]),
    ...mapGetters("ai", ["assistantType"]),
    inviteDialog: {
      get() {
        return this.assessmentCardClicked;
      },
      set(inviteDialogNeeded) {
        if (
          !inviteDialogNeeded &&
          this.$refs.userHistory &&
          !this.assessmentCardClicked !==
            Constants.assessmentTypes.burnoutAssessment
        ) {
          this.$refs.userHistory.getUserHistory();
        }
        this.assessmentCardClicked = "";
      }
    },
    showDashboardAssistant() {
      return (
        this.enableV3Assistant && this.assistantType !== AssistantTypes.REPORT
      );
    },
    showWelcomeDialog() {
      return (
        !!this.currentUserTeamAndSubscriptionBasicInformation &&
        this.currentUserTeamAndSubscriptionBasicInformation.isNew
      );
    },
    inviteDialogWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? "90%" : "70%";
    },
    inviteAssessmentType() {
      const validTypes = Object.values(Constants.assessmentTypes);

      return validTypes.includes(this.assessmentCardClicked)
        ? this.assessmentCardClicked
        : "";
    },
    showAdminFeedbackPopup() {
      const englishLocale = this._i18n.locale.includes("en");
      if (!englishLocale) {
        return false;
      }

      const shouldShowCustomRecs = NewFeatureService.showFeature(
        "AdminDashboardFeedback"
      );
      return shouldShowCustomRecs;
    }
  },
  async mounted() {
    try {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      await this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });

      // TODO: add back in this.IsTrialUser to show account banner (hidden for week of 17/06/24)
      this.showAccountBanner = this.subscriptionHasExpired;

      let teamMember = await getTeamMember(this.teamMemberId);

      if (teamMember.disabledAt !== null) {
        Auth.signOut().then(() => {
          this.resetLoginState();
          this.$router.push("/", () => {});
        });
      }

      let featureIntrosNeedsLoading =
        !this.featureIntros ||
        (this.featureIntros &&
          (this.featureIntros.length == 0 ||
            this.featureIntros[0].userId !== teamMberInfo.userId));

      if (featureIntrosNeedsLoading) {
        await FeatureService.getActiveFeatureIntros();
      }

      updateCheckListFromFeatures(this.featureIntros);
    } catch (err) {
      this.$logger.captureException(err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([
      "getUserTeamAndSubscriptionBaseInformation",
      "resetLoginState"
    ]),
    ...mapMutations(["setTeamMemberId"]),
    showInviteWithPreloadedUser(user) {
      this.assessmentType = Constants.assessmentTypes.deskAssessment;
      this.preloadedInviteUser = user;
      this.assessmentCardClicked = this.assessmentType;
    },
    onAssessmentCardClicked(newValue) {
      this.assessmentCardClicked = newValue;
    },
    openFeedbackSurvey() {
      window.open("https://tally.so/r/w24EOL", "_blank");
    }
  },
  watch: {
    inviteDialog: function (newVal) {
      if (newVal === true) {
        return;
      }
      if (this.$refs.historyTable != undefined) {
        this.$refs.historyTable.updateHistory();
      }
      if (this.$refs.analyticsTable != undefined) {
        this.$refs.analyticsTable.updateAnalytics();
      }
      this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });
    }
  }
};
</script>

<style scoped>
.noClick {
  cursor: default;
}
.checkList {
  position: fixed;
  z-index: 5;
  right: 0;
  bottom: 0;
}
</style>
