<template>
  <div class="ai-seed-question-container" :class="{ 'is-visible': isVisible }">
    <h3
      :class="headingClassList"
      class="d-flex align-center mb-4"
      style="line-height: 1"
    >
      <img src="/images/icons/blue-sparkles.svg" class="mr-1" />
      <span :class="{ 'text-ai-gradient ml-1': variant === 'cards' }">{{
        heading
      }}</span>
    </h3>
    <div class="ai-search-suggestions d-flex align-center">
      <button
        v-if="showLeftArrow"
        @click="scrollLeft"
        class="arrow left"
        :style="{ left: `-${navButtonOffset}` }"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </button>
      <div
        ref="scrollContainer"
        class="scroll-container flex-grow-1"
        :class="{
          'has-gradient-left': showGradientLeft,
          'has-gradient-right': showGradientRight
        }"
        @scroll="onScroll"
      >
        <button
          v-for="(item, index) in items"
          :key="item.id"
          :class="[
            buttonClassList,
            { 'is-visible': isVisible },
            { 'is-loading': showLoader }
          ]"
          :style="{
            '--d': `${index / animationDelay}s`
          }"
          @click="() => onClick(item)"
        >
          <span v-if="item.title">{{ item.title }}</span>
          {{ item.content }}
        </button>
      </div>
      <button
        v-if="showRightArrow"
        @click="scrollRight"
        class="arrow right"
        :style="{ right: `-${navButtonOffset}` }"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

const SCROLL_BUFFER = 20;
const FIXED_SCROLL_AMOUNT = 300;
const ANIMATION_DELAY_PILLS = 3;
const ANIMATION_DELAY_CARDS = 6;

export default {
  name: "AiSeedQuestionContainer",
  props: {
    items: Array,
    variant: {
      type: String,
      default: "pills",
      validator: value => ["pills", "cards"].includes(value)
    },
    heading: {
      type: String,
      default: "MSK Copilot"
    },
    navButtonOffset: {
      type: String,
      default: "0"
    },
    scrollToEnd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showLeftArrow: false,
      showRightArrow: false,
      isVisible: false,
      observer: null,
      animationTimer: null
    };
  },
  computed: {
    ...mapState("ai", ["isAiWindowOpen", "isContextLoading"]),
    showGradientRight() {
      return this.showRightArrow;
    },
    showGradientLeft() {
      return this.showLeftArrow;
    },
    buttonClassList() {
      if (this.variant === "cards") {
        return "vfl-button-ai-card";
      }

      return "vfl-button-ai-outline is-small";
    },
    headingClassList() {
      if (this.variant === "cards") {
        return "text-ai-gradient font-weight-medium text-h6";
      }

      return "text-overline ";
    },
    animationDelay() {
      return this.variant === "cards"
        ? ANIMATION_DELAY_CARDS
        : ANIMATION_DELAY_PILLS;
    },
    animationDuration() {
      return (this.items.length - 1) / this.animationDelay;
    },
    showLoader() {
      // Loader should show on the following conditions:
      // The context is generating in the background
      // isVisible is false
      // The animation timer is not null (this is to keep loader until animation finished)
      return (
        this.isContextLoading || !this.isVisible || this.animationTimer !== null
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkArrows();
      this.setupIntersectionObserver();
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    if (this.observer) {
      this.cleanupObserver();
    }
    if (this.animationTimer !== null) {
      clearTimeout(this.animationTimer);
    }
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.startAnimationTimer();
      }
    }
  },
  methods: {
    setupIntersectionObserver() {
      if (!("IntersectionObserver" in window)) {
        // Fallback for browsers that don't support IntersectionObserver
        this.isVisible = true;
        return;
      }

      const options = {
        root: null,
        rootMargin: "-20% 0% -20% 0%",
        threshold: 0
      };

      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.isVisible = true;
            this.observer.unobserve(entry.target);
            this.cleanupObserver();
          }
        });
      }, options);

      this.observer.observe(this.$el);

      // Check if the component is already in view on mount
      if (this.isElementInViewport(this.$el)) {
        this.isVisible = true;
        this.cleanupObserver();
      }
    },
    scrollLeft() {
      this.scroll(true);
    },
    scrollRight() {
      this.scroll(false);
    },
    scroll(isScrollingLeft) {
      const container = this.$refs.scrollContainer;

      if (this.scrollToEnd) {
        this.scrollToEndOfContainer(container, isScrollingLeft);
      } else {
        this.scrollByFixedAmount(container, isScrollingLeft);
      }
    },
    scrollToEndOfContainer(container, isScrollingLeft) {
      const buttons = container.querySelectorAll(".vfl-button-ai-outline");
      const targetButton = this.findTargetButton(
        container,
        buttons,
        isScrollingLeft
      );

      if (targetButton) {
        container.scrollTo({
          left:
            targetButton.offsetLeft - (isScrollingLeft ? SCROLL_BUFFER : 20),
          behavior: "smooth"
        });
      } else {
        container.scrollTo({
          left: isScrollingLeft ? 0 : container.scrollWidth,
          behavior: "smooth"
        });
      }
    },
    scrollByFixedAmount(container, isScrollingLeft) {
      const scrollAmount = isScrollingLeft
        ? -FIXED_SCROLL_AMOUNT
        : FIXED_SCROLL_AMOUNT;

      container.scrollTo({
        left: Math.max(
          0,
          Math.min(
            container.scrollWidth - container.clientWidth,
            container.scrollLeft + scrollAmount
          )
        ),
        behavior: "smooth"
      });
    },
    findTargetButton(container, buttons, isScrollingLeft) {
      if (isScrollingLeft) {
        for (let i = buttons.length - 1; i >= 0; i--) {
          if (buttons[i].offsetLeft < container.scrollLeft) {
            return buttons[i];
          }
        }
      } else {
        for (let button of buttons) {
          if (
            button.offsetLeft + button.offsetWidth >
            container.scrollLeft + container.clientWidth
          ) {
            return button;
          }
        }
      }
      return null;
    },
    onScroll() {
      this.checkArrows();
    },
    checkArrows() {
      const container = this.$refs.scrollContainer;

      this.showLeftArrow = container.scrollLeft > 0;
      this.showRightArrow =
        container.scrollWidth > container.clientWidth + container.scrollLeft;
    },
    onClick(item) {
      this.$store.dispatch("ai/openAiWindow");
      this.$store.dispatch("ai/createNewConversationAndSendMessage", {
        message: item.content
      });
    },
    handleResize() {
      this.$nextTick(() => {
        this.checkArrows();
      });
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    startAnimationTimer() {
      if (this.animationTimer !== null) {
        return;
      }

      this.animationTimer = setTimeout(() => {
        this.animationTimer = null;
      }, this.animationDuration * 1000);
    },
    cleanupObserver() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ai-seed-question-container {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.23, 0.81, 0.85, 0.78);

  &.is-visible {
    opacity: 1;
  }
}

h3 {
  color: var(--v-vflGreyLight-base);
}

.ai-search-suggestions {
  position: relative;
}

.scroll-container {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  scroll-behavior: smooth;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before,
  &:after {
    bottom: 0;
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50px;
    z-index: 1;
  }

  &.has-gradient-left:before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), white);
    opacity: 1;
    left: 0;
  }

  &.has-gradient-right:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    opacity: 1;
    right: 0;
  }
}

.vfl-button-ai-outline {
  flex: 0 0 auto;
  line-height: 1.3;
  max-width: calc(100% - 3rem);
  min-height: 42px;
  opacity: 0;
  overflow-wrap: break-word;
  text-align: left;
  transition: opacity 1s cubic-bezier(0.23, 0.81, 0.85, 0.78) var(--d),
    transform 0.125s ease-out;
  white-space: normal;
  word-wrap: break-word;

  &.is-visible {
    opacity: 1;
  }

  &:active {
    // The tags can be quite long, so the default scale effect looks too dramatic. Overriding here
    transform: scale(0.995, 0.98);
  }
}

.vfl-button-ai-card {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.23, 0.81, 0.85, 0.78) var(--d),
    transform 0.125s ease-out;

  &.is-visible {
    opacity: 1;
  }

  &:active {
    transform: scale(0.99);
  }
}

.arrow {
  cursor: pointer;
  background-color: #eaf1f9;
  border-radius: 8px;
  bottom: 0.5rem; // account for padding bottom on container
  height: 24px;
  margin: auto 0;
  position: absolute;
  top: 0;
  width: 24px;
  user-select: none;
  z-index: 2;

  // Increase hit size
  &:before {
    content: "";
    bottom: -6px;
    left: -6px;
    right: -6px;
    position: absolute;
    top: -6px;
  }

  &:hover {
    filter: brightness(95%);
  }

  &.left {
    left: -1.5rem;
  }

  &.right {
    right: -1.5rem;
  }
}
</style>
